import React, { useState, useEffect } from "react";
import { Group, Pagination, Stack, Text } from "@mantine/core";
import LeadsFilter from "./LeadsFilter";
import LeadsTable from "./LeadsTable";
import LeadsAction from "./LeadsAction/LeadsAction";
import { fetchUrl } from "utils/urlHelpers";
import ContentBox from "components/ContentBox";
import { useDictionary } from "i18n/dictionary";

export default function Leads() {
	const dict = useDictionary("leads");
	const [leads, setLeads] = useState(null);
	const [leadCount, setLeadCount] = useState(null);
	const [filters, setFilters] = useState(null);
	const [activePage, setActivePage] = useState(1);

	useEffect(() => {
		if (filters === null) return;
		if (filters.rowStart === undefined && activePage > 1) {	// filter has changed => back to page 1
			setActivePage(1);
			return;
		}
		filters.rowStart = (activePage - 1) * filters.rowCount;
		const onSuccess = json => {
			setLeads(json.leads);
			setLeadCount(json.rowCount);
		};
		setLeads(null);
		fetchUrl("lead/search", "POST", JSON.stringify(filters), null, null, onSuccess);
	}, [filters, activePage]);

	return (
		<Stack>
			<ContentBox>
				<Group position="apart" mb="xs">
					<Group position="left" my="xs">
						<LeadsAction rowCount={leadCount} filters={filters} />
						<LeadsFilter setFilters={setFilters} />
					</Group>
					<Pagination total={filters == null ? 0 : Math.ceil(leadCount / filters?.rowCount)} page={activePage} onChange={setActivePage} />
				</Group>
				<LeadsTable leads={leads} dict={dict} />
			</ContentBox>
			{leads !== null && <Text align="center" size="sm">{dict.companyFound?.replace("{count}", leadCount)}</Text>}
		</Stack>
	);
}