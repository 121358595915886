import React, { useState, useEffect } from "react";
import { createStyles, Table, Skeleton, Modal, Group, Button } from "@mantine/core";
import { Plus } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import Competitor from "./Competitor";
import { getUrl } from "utils/urlHelpers";
import ContentBox from "components/ContentBox";

const useStyles = createStyles(() => ({
	row: { cursor: "pointer" }
}));

export default function Competitors() {
	const { classes } = useStyles();
	const dict = useDictionary("competitors");
	const [competitors, setCompetitors] = useState(null);
	const [savedCompetitor, setSavedCompetitor] = useState(null);
	const [competitorOpened, setCompetitorOpened] = useState(null);
	const [deletedCompetitor, setDeletedCompetitor] = useState(null);

	useEffect(() => {
		if (competitors && savedCompetitor != null) {
			let newCompetitors = competitors.slice();
			if (competitorOpened.id == savedCompetitor.id)
				newCompetitors.forEach((user, i) => {
					if (user.id == savedCompetitor.id)
						newCompetitors[i] = { ...savedCompetitor };
				});
			else {
				newCompetitors.push(savedCompetitor);
				newCompetitors = newCompetitors.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
			}
			setCompetitors(newCompetitors);
			setCompetitorOpened(null);
		}
	}, [savedCompetitor]);

	useEffect(() => {
		if (competitors && deletedCompetitor != null) {
			let newCompetitors = competitors.filter(function (competitor) {
				return competitor.id != deletedCompetitor.id;
			});
			setCompetitors(newCompetitors);
			setCompetitorOpened(null);
		}
	}, [deletedCompetitor]);

	useEffect(() => {
		getUrl("competitor", data => { setCompetitors(data); });
	}, []);

	const openCompetitor = (e, competitor) => {
		setCompetitorOpened(competitor);
	};

	const placeHolderRow = Array.from({ length: 1 }, (v, i) => <td key={i}><Skeleton height={42} /></td>);
	const placeHolder = Array.from({ length: 10 }, (v, i) => <tr key={i}>{placeHolderRow}</tr>);

	const rows = competitors?.map(competitor => (
		<tr key={competitor.id} className={classes.row} onClick={e => openCompetitor(e, competitor)}>
			<td>{competitor.name}</td>
		</tr>
	));
	return (
		<ContentBox>
			<Group position="right">
				<Button leftIcon={<Plus size={20} />} onClick={() => setCompetitorOpened({})}>{dict.newCompetitor}</Button>
			</Group>
			<Table highlightOnHover sx={{ minWidth: 450 }}>
				<thead>
					<tr>
						<th>{dict.name}</th>
					</tr>
				</thead>
				<tbody>{competitors === null ? placeHolder : rows}</tbody>
			</Table>
			<Modal size="75%" opened={competitorOpened != null} onClose={() => setCompetitorOpened(null)} centered overflow="outside" title={dict.editCompetitor}>
				<Competitor competitor={competitorOpened} opened={competitorOpened != null} setSavedCompetitor={setSavedCompetitor} setDeletedCompetitor={setDeletedCompetitor} />
			</Modal>
		</ContentBox>
	);
}