export default function usePathFinder(hierarchy) {

	function buildMappings() {
		const regToParents = {};
		const regToChildren = {};
		const regToIsInGroup = {};

		hierarchy.forEach(x => {
			if (x.regNumber && x.parentReg) {
				if (!regToParents[x.regNumber]) regToParents[x.regNumber] = [];
				if (!regToChildren[x.parentReg]) regToChildren[x.parentReg] = [];

				regToParents[x.regNumber].push(x.parentReg);
				regToChildren[x.parentReg].push(x.regNumber);
			}
			regToIsInGroup[x.regNumber] = x.isInGroup === 1;
		});

		return { regToParents, regToChildren, regToIsInGroup };
	}

	// Performs a breadth-first search on a graph represented by the given adjacency lists
	// Returns the shortest path from the given starting node to a node that satisfies the given condition.
	function bfs(regNumber, regToParents, regToChildren, regToIsInGroup) {
		const queue = [{ reg: regNumber, path: [] }];
		const visited = new Set();

		while (queue.length > 0) {
			const { reg, path } = queue.shift();
			if (visited.has(reg)) continue;

			visited.add(reg);

			const newPath = [...path, reg];

			if (regToIsInGroup[reg])
				return newPath.reverse().map(x => hierarchy.find(y => y.regNumber == x));

			const parents = regToParents[reg] || [];
			const children = regToChildren[reg] || [];

			for (const parent of parents)
				queue.push({ reg: parent, path: newPath });

			for (const child of children)
				queue.push({ reg: child, path: newPath });
		}
		return null;
	}

	function getCompanyLink(regNumber) {
		const { regToParents, regToChildren, regToIsInGroup } = buildMappings();
		return bfs(regNumber, regToParents, regToChildren, regToIsInGroup);
	}

	return getCompanyLink;
}