import React from "react";
import { createStyles, Table, Skeleton } from "@mantine/core";
import { useNavigate, Outlet } from "react-router-dom";

const useStyles = createStyles(theme => ({
	row: { cursor: "pointer" },
	rowInactive: {
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2],
		cursor: "pointer", fontStyle: "italic"
	}
}));

export default function ContactsTable({ contacts, dict }) {
	const { classes } = useStyles();
	const navigate = useNavigate();

	const openCompany = (e, contact) => {
		if (e.target.tagName != "svg")
			navigate(`/leads/${contact.companyId}`);
	};

	const placeHolderRow = Array.from({ length: 7 }, (v, i) => <td key={i}><Skeleton height={30} /></td>);
	const placeHolder = Array.from({ length: 10 }, (v, i) => <tr key={i}>{placeHolderRow}</tr>);

	const rows = contacts?.map(contact => (
		<tr key={contact.contactId} className={contact.status == 1 ? classes.row : classes.rowInactive} onClick={e => openCompany(e, contact)}>
			<td>{contact.title}</td>
			<td>{contact.firstName}</td>
			<td>{contact.lastName}</td>
			<td>{contact.companyName}</td>
			<td>{contact.position}</td>
			<td>{contact.email}</td>
			<td>{contact.phone}</td>
		</tr>
	));

	return (
		<>
			<Table highlightOnHover>
				<thead>
					<tr>
						<th>{dict.title}</th>
						<th>{dict.firstName}</th>
						<th>{dict.lastName}</th>
						<th>{dict.company}</th>
						<th>{dict.position}</th>
						<th>{dict.email}</th>
						<th>{dict.phone}</th>
					</tr>
				</thead>
				<tbody>{contacts === null ? placeHolder : rows}</tbody>
			</Table>
			<Outlet />
		</>
	);
}