import { ActionIcon, Group, Stack } from "@mantine/core";
import React, { useEffect, useState } from "react";
import LeadFilterAiDataItem from "./LeadFilterAiDataItem";
import { Plus } from "tabler-icons-react";

export default function LeadFilterAiData({ dict, updateFilter, aiData, setAiData }) {
	const [items, setItems] = useState(aiData || [{ property: "", comparison: "", value: "" }]);

	useEffect(() => {
		const newAiData = items.filter(item => item.property && item.comparison && item.value);
		if (JSON.stringify(newAiData) !== JSON.stringify(aiData || [])) {
			const description = newAiData.map(item => `${item.property} ${item.comparison} ${item.value}`).join(` ${dict.and} `);
			updateFilter(dict.aiData, setAiData, newAiData, true, description);
		}

	}, [items]);

	const updateItem = (item, index) => {
		const newItems = [...items];
		newItems[index] = item;
		setItems(newItems);
	};
	const addItem = () => {
		setItems([...items, { property: "", comparison: "", value: "" }]);
	};
	const deleteItem = index => {
		setItems(items.filter((item, i) => i !== index));
	};

	return (
		<Stack>
			{items.map((item, index) => (
				<LeadFilterAiDataItem key={index} dict={dict} item={item} setItem={updatedItem => updateItem(updatedItem, index)} deleteItem={() => deleteItem(index)} />
			))}
			<Group position="right">
				<ActionIcon variant="outline" color="blue" size="sm" onClick={addItem}>
					<Plus size={16} />
				</ActionIcon>
			</Group>
		</Stack>
	);
}