import React, { useState, useEffect } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import { Modal, Text, Breadcrumbs, Grid, Space, Group, Loader } from "@mantine/core";
import { fetchUrl, getUrl } from "utils/urlHelpers";
import { useDictionary } from "i18n/dictionary";
import Company from "./LeadCompany/LeadCompany";
import Matches from "./LeadMatches";
import Contacts from "./LeadContacts/LeadContacts";
import Documents from "./LeadDocuments";
import WebPages from "./LeadWebPages/LeadWebPages";
import LeadDetail from "./LeadDetail";
import Scores from "./LeadScores";
import Duplicates from "./LeadDuplicate";
import Extras from "./LeadExtras";
import Nearby from "./LeadNearby";
import Competitors from "./LeadCompetitors";
import Categories from "./LeadCategories";
import LeadAI from "./LeadAI";
import Tags from "../Tags";

export default function Lead() {
	const navigate = useNavigate();
	const dict = useDictionary("lead");
	const params = useParams();
	const close = () => { navigate(".."); };
	const [company, setCompany] = useState(null);
	const [lead, setLead] = useState(null);

	const isBattle = window.location.pathname.indexOf("/battle/") != -1;
	const isModal = true;

	useEffect(() => {
		if (!dict.noLead) return;
		getUrl(`company/${params.companyId}/full`, setCompany);
		fetchUrl(`company/${params.companyId}/lead/full`, "GET", null, null, null, setLead, dict.noLead, () => setLead({}));
	}, [dict.noLead]);

	const elementFromType = (type, props) => {
		const icons = {
			"company": Company, "contacts": Contacts, "matches": Matches, "leadDetail": LeadDetail, "scores": Scores,
			"webPages": WebPages, "duplicates": Duplicates, "extras": Extras, "nearby": Nearby, "documents": Documents,
			"competitors": Competitors, "categories": Categories, "ai": LeadAI
		};
		return React.createElement(icons[type], props);
	};
	const pageBattle = {
		"tr": [{
			"height": 300,
			"td": [{
				"width": 37.5,
				"modules": [{
					"type": "company",
					"label": dict.details,
					"properties": {
						"editable": true
					}
				}]
			}, {
				"width": 37.5,
				"modules": [{
					"type": "contacts",
					"label": dict.contacts,
					"properties": {
						"private": true,
						"public": true
					}
				}]
			}, {
				"width": 25,
				"modules": [{
					"type": "matches",
					"label": dict.links
				}, {
					"type": "duplicates",
					"label": dict.duplicate,
					"height": 170,
					"properties": {
						"bigchange": true,
						"hubspot": false
					}
				}]
			}]
		}, {
			"height": 315,
			"td": [{
				"width": 20,
				"modules": [{
					"type": "scores",
					"label": dict.scores,
					"height": 180
				}, {
					"type": "categories",
					"label": dict.categories,
					"height": 100
				}]
			}, {
				"width": 25,
				"modules": [{
					"type": "webPages",
					"label": dict.website,
					"properties": {
						"editable": true
					}
				}]
			}, {
				"width": 40,
				"modules": [{
					"type": "nearby",
					"label": dict.nearby
				}]
			}, {
				"width": 15,
				"modules": [{
					"type": "competitors",
					"label": dict.competitors
				}]
			}]
		}, {
			"height": 350,
			"td": [{
				"width": 100,
				"modules": [{
					"type": "ai",
					"label": "A.I.",
				}]
			}]
		}]
	};
	const pageAll = {
		"tr": [{
			"height": 320,
			"td": [{
				"width": 37.5,
				"modules": [{
					"type": "company",
					"label": dict.details,
					"properties": {
						"editable": true
					}
				}]
			}, {
				"width": 37.5,
				"modules": [{
					"type": "contacts",
					"label": dict.contacts,
					"properties": {
						"private": true,
						"public": true
					}
				}]
			}, {
				"width": 25,
				"modules": [{
					"type": "matches",
					"label": dict.links,
					"Height": "33%"
				}, {
					"type": "leadDetail",
					"label": dict.leadInfo,
					"Height": "66%"
				}]
			}]
		}, {
			"height": 230,
			"td": [{
				"width": 37.5,
				"modules": [{
					"type": "scores",
					"label": dict.scores
				}]
			}, {
				"width": 37.5,
				"modules": [{
					"type": "webPages",
					"label": dict.website,
					"properties": {
						"editable": true
					}
				}]
			}, {
				"width": 25,
				"modules": [{
					"type": "duplicates",
					"label": dict.duplicate,
					"properties": {
						"bigchange": true,
						"hubspot": true
					}
				}]
			}]
		}, {
			"height": 150,
			"td": [{
				"width": 37.5,
				"modules": [{
					"type": "extras",
					"label": dict.extraInfo
				}]
			}, {
				"width": 37.5,
				"modules": [{
					"type": "nearby",
					"label": dict.nearby
				}]
			}, {
				"width": 25,
				"modules": [{
					"type": "documents",
					"label": dict.accountDoc
				}]
			}]
		}]
	};
	const cells = (isBattle ? pageBattle : pageAll).tr.map((tr, i) =>
		tr.td.map((td, j) =>
			<Grid.Col key={`${i}-${j}`} md={6} lg={td.width * 12 / 100}>
				{td.modules.map((module, k) =>
					<div key={k}>
						{k > 0 && <Space h="sm" />}
						{elementFromType(module.type, { label: module.label, height: module.height || tr.height, company: company, lead: lead, setCompany: setCompany, ...module.properties })}
					</div>
				)}
			</Grid.Col>
		)
	);

	const popupTitle = isModal && company?.name &&
		(<Group>
			{!company.processed && <Loader size={24} />}
			<Text weight={500}>{company.name}</Text>
			{lead?.id && <Tags entityType={1} entityName="lead" entityId={lead.id} entityTags={lead.tags} />}
		</Group>
		);

	return (
		isBattle &&
		<div style={{ padding: 10 }}>
			<Text weight={500}>{company?.name || dict.loading}</Text>
			<Grid mt={4}>{cells}</Grid>
		</div>
		|| isModal &&
		<Modal opened={true} onClose={close} centered size="95%" overflow="outside"
			title={company?.name && popupTitle || dict.loading}>
			<Grid>{cells}</Grid>
		</Modal>
		|| <>
			<Breadcrumbs>
				<Text><NavLink to="../leads" sx={theme => ({ color: theme.colors.blue[7], textDecoration: "none" })}>Leads</NavLink></Text>
				<Text>{company?.name || dict.loading}</Text>
			</Breadcrumbs>
			<Grid mt={4}>{cells}</Grid>
		</>
	);
}