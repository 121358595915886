import React from "react";
import { Modal, Text } from "@mantine/core";

export default function ImportTableError({ dict, setErrorOpened, errors }) {
	const parseError = () => {
		const formattedErrors = errors.split("\n").map((error, i) => {
			let rowNumberMatch = error.match(/Row (\d+)/);
			let errorDescriptionMatch = error.match(/: (.*)/);

			let rowNumber = rowNumberMatch ? rowNumberMatch[1] : null;
			let errorDescription = errorDescriptionMatch ? errorDescriptionMatch[1] : null;
			try {
				const errorJson = JSON.parse(errorDescription.substring(errorDescription.indexOf("{")));
				if (errorJson.errors?.id)
					errorDescription = errorJson.errors.id.join(", ");
			} catch { /**/ }

			if (rowNumber)
				return (
					<div key={i}>
						<Text weight={700}>{dict.row} {rowNumber}</Text><Text>{errorDescription}</Text>
					</div>
				);
		}).filter(x => x !== undefined);

		return !formattedErrors.length
			? <Text>{errors}</Text>
			: formattedErrors;
	};

	return (
		<Modal opened={true} onClose={() => setErrorOpened(null)} title={dict.error} size="xl">
			{parseError() || errors}
		</Modal>
	);}