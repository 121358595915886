import React, { useState, useEffect } from "react";
import { createStyles, Table, Modal } from "@mantine/core";
import { PlayCard } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import { getUrl } from "utils/urlHelpers";
import LeadSection from "./LeadSection";
import BattleCards from "pages/Admin/BattleCards";

const useStyles = createStyles(theme => ({
	row: {
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
	},
	rowClickable: {
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		cursor: "pointer",
	},
	playCard: { stroke: theme.colors.blue[7], verticalAlign: "middle", marginLeft: 4 },
}));

export default function Competitors({ label, height }) {
	const { classes } = useStyles();
	const dict = useDictionary();
	const [competitors, setCompetitors] = useState(null);
	const [cardEntityId, setCardEntityId] = useState(null);

	useEffect(() => {
		getUrl("competitor", data => { setCompetitors(data); });
	}, []);

	const rows = competitors?.sort((a, b) => (a.cardCount > 0 ? 0 : 1) + a.name < (b.cardCount > 0 ? 0 : 1) + a.name ? -1 : 1)
		.map(competitor => (
			<tr key={competitor.id} className={competitor.cardCount > 0 ? classes.rowClickable : classes.row}
				onClick={() => { if (competitor.cardCount > 0) setCardEntityId(competitor.id); }}>
				<td>{competitor.name}</td>
				<td>{competitor.cardCount > 0 && <PlayCard className={classes.playCard} size="20" />}</td>
			</tr>
		));

	const content =
		<Table highlightOnHover>
			<tbody>{rows}</tbody>
		</Table>;

	return (
		<>
			<LeadSection label={label} maxHeight={height - 55} minHeight={height}>{content}</LeadSection>
			<Modal opened={cardEntityId != null} centered size="85%" onClose={() => setCardEntityId(null)} title={dict.battleCard}>
				<BattleCards entityType={2} entityId={cardEntityId} readOnly />
			</Modal>
		</>
	);
}