import React, { useState } from "react";
import { AppShell, useMantineTheme } from "@mantine/core";
import { Outlet } from "react-router-dom";
import AppMenu from "./AppMenu";
import AppHeader from "./AppHeader";

export default function AppShellResponsive({ logout }) {
	const theme = useMantineTheme();
	const [navBarOpened, setNavBarOpened] = useState(false);
	const isBattle = window.location.pathname.indexOf("/battle/") != -1;

	const background = theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[1];
	const color = theme.colorScheme === "dark" ? theme.colors.gray[4] : theme.colors.dark[8];

	document.body.style.backgroundColor = background;

	return (
		isBattle && <div style={{ color: color}}><Outlet /></div>
		|| <AppShell navbarOffsetBreakpoint="sm"
			styles={{ main: { background: background, color: color } }}
			navbar={<AppMenu navBarOpened={navBarOpened} setNavBarOpened={setNavBarOpened} />}
			header={<AppHeader navBarOpened={navBarOpened} setNavBarOpened={setNavBarOpened} logout={logout} />}
		>
			<Outlet />
		</AppShell>
	);
}