import { Paper, useMantineTheme } from "@mantine/core";
import React from "react";

export default function ContentBox({ children }) {
	const theme = useMantineTheme();

	return (
		<Paper shadow={theme.colorScheme === "dark" ? undefined : "0px 0px 8px #BBB"}
			radius="md" p="md" withBorder>
			{children}
		</Paper>
	);
}