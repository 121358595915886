import React, { useState } from "react";
import { ActionIcon, Anchor, createStyles, Modal, Text, Tooltip } from "@mantine/core";
import { Home, InfoCircle, Users, Circles, Tool, QuestionMark, Location, Message, News, PhoneCall, Tie, ReportSearch, ZoomQuestion, Photo, Certificate, FaceMask, ListCheck, MapSearch, Edit } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import LeadSection from "../LeadSection";
import LeadWebPageUrl from "./LeadWebPageUrl";

const useStyles = createStyles(theme => ({
	link: { color: theme.colorScheme === "dark" ? theme.colors.gray[5] : theme.colors.dark[7], fontSize: theme.fontSizes.sm },
	icon: { verticalAlign: "middle", marginRight: theme.spacing.xs },
}));

export default function LeadWebPages({ company, label, height }) {
	const { classes } = useStyles();
	const dict = useDictionary("leadWebPage");
	const [editWebsiteOpened, setEditWebsiteOpened] = useState(false);

	const iconFromType = (type) => {
		const icons = {
			1: Home, 2: InfoCircle, 3: Circles, 4: Tool, 5: PhoneCall, 6: Message, 7: Location, 8: News, 9: Tie, 10: ZoomQuestion,
			11: Photo, 12: Certificate, 14: ReportSearch, 15: Users, 16: ListCheck, 17: MapSearch, 18: FaceMask, 50: QuestionMark
		};
		return React.createElement(icons[type] ?? QuestionMark, { className: classes.icon });
	};

	const cantScrape = <>
		<Text>{dict.cantScraped}</Text>
		<Anchor href={company?.website} target="_blank">{company?.website}</Anchor>
	</>;
	const links = company?.webPages?.map(page =>
		<div key={page.id}>
			<Anchor href={page.url} target="_blank" className={classes.link}>
				{iconFromType(page.type)}{page.title}
			</Anchor>
		</div>
	);
	const content = company?.webPages?.length == 0
		? <>
			{company?.website && cantScrape || <Text>{dict.websiteUnknown}</Text>}
			<LeadWebPageUrl dict={dict} company={company} />
		</>
		: <>
			<table>
				<tbody>
					<tr>
						<td>
							<Anchor href={company?.website} target="_blank">{company?.website}</Anchor>
						</td>
						<td>
							<Tooltip label={dict.editWebsite} openDelay={500}>
								<ActionIcon variant="outline" color="blue" onClick={() => setEditWebsiteOpened(true)}>
									<Edit size={18} />
								</ActionIcon>
							</Tooltip>
						</td>
					</tr>
				</tbody>
			</table>
			{links}
		</>;

	return (
		<>
			<LeadSection label={label} maxHeight={height - 55} minHeight={height}>{content}</LeadSection>
			<Modal opened={editWebsiteOpened} centered onClose={() => setEditWebsiteOpened(false)} title={dict.editWebsite}>
				<LeadWebPageUrl dict={dict} company={company} onClose={() => setEditWebsiteOpened(false)} />
			</Modal>
		</>
	);
}