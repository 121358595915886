import React, { useEffect, useState } from "react";
import { ActionIcon, Button, Group, Tabs, TextInput, Tooltip } from "@mantine/core";
import { Check, Plus, QuestionMark, TextSpellcheck, Trash, X } from "tabler-icons-react";
import { createUid } from "utils/helpers";

export default function LeadContactMatches({ dict, contactMatches, setContactMatches, getMatchedSource }) {
	const [matches, setMatches] = useState(contactMatches.map(x => ({ ...x, uid: createUid() })));
	const [activeTab, setActiveTab] = useState(matches.length ? matches[0].uid : undefined);

	useEffect(() => {
		if (activeTab == "-1")
			setActiveTab(matches.at(-1).uid);
	}, [activeTab]);

	useEffect(() => {
		setContactMatches(matches);
	}, [matches]);

	const addMatch = () => {
		let newMatches = [...matches];
		newMatches.push({ id: 0, uid: createUid(), sourceType: 20, matchReliability: 1 });
		setMatches(newMatches);
	};
	const updateMatch = (uid, property, value) => {
		let newMatches = [...matches];
		newMatches.forEach((x, i) => {
			if (x.uid == uid)
				newMatches[i][property] = value;
		});
		setMatches(newMatches);
	};
	const deleteMatch = uid => {
		let newMatches = [...matches];
		let match = newMatches.find(x => x.uid == uid);
		if (match.id == 0)
			newMatches = newMatches.filter(x => x.uid != uid);
		else
			match.deleted = true;
		setMatches(newMatches);
	};

	const verifiedEmailIcon = status => {
		const icons = [<QuestionMark key={0}/>, <Check key={1} color="green"/>, <X key={2} color="red"/>, <Trash key={3} color="red"/>, <TextSpellcheck key={4} color="red"/>, <QuestionMark key={5}/>];
		return <Tooltip label={dict[`emailStatus${status}`]} withArrow><span>{icons[status]}</span></Tooltip>;
	};
	const getSource = type => {
		return getMatchedSource(type).replace("(", "").replace(")", "");
	};
	const tabs = matches.filter(x => !x.deleted).map(x =>
		<Tabs.Tab key={x.uid} value={x.uid} rightSection={x.uid == activeTab &&
			<ActionIcon variant="outline" color="blue" onClick={() => deleteMatch(x.uid)}>
				<Trash size={14} />
			</ActionIcon>
		}>
			{getSource(x.sourceType)}
		</Tabs.Tab>
	);
	const tabPanels = matches.filter(x => !x.deleted).map(x =>
		<Tabs.Panel key={x.uid} value={x.uid} mt="xs">
			<Group grow>
				<TextInput label={dict.email} defaultValue={x.email || ""} type="email" rightSection={verifiedEmailIcon(x.emailStatus)}
					onChange={e => updateMatch(x.uid, "email", e.currentTarget.value)}/>
				<TextInput label={dict.phone} defaultValue={x.phone || ""} type="tel"
					onChange={e => updateMatch(x.uid, "phone", e.currentTarget.value)}/>
			</Group>
		</Tabs.Panel>
	);

	return (
		<Tabs mt="md" value={activeTab} onTabChange={setActiveTab}>
			<Tabs.List>
				{tabs}
				<Tabs.Tab value={"-1"}>
					<Button variant="outline" compact leftIcon={<Plus size={14} />} onClick={addMatch}>{dict.add}</Button>
				</Tabs.Tab>
			</Tabs.List>
			{tabPanels}
		</Tabs>
	);
}