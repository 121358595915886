import React, { useState } from "react";
import { useDictionary } from "i18n/dictionary";
import { Skeleton, Paper, Spoiler, Text, useMantineTheme, Tooltip, Modal } from "@mantine/core";
import { AlertCircle, ZoomIn } from "tabler-icons-react";
import LeadIssue from "./LeadIssue";

export default function LeadSection({ label, minHeight, maxHeight, leadId, children }) {
	const theme = useMantineTheme();
	const dict = useDictionary("leadSection");
	const [mouseOver, setMouseOver] = useState(false);
	const [problemOpened, setProblemOpened] = useState(false);
	const [sectionOpened, setSectionOpened] = useState(false);

	return (
		children == null
			? <>
				<Text>{label}</Text>
				<Skeleton height={maxHeight} radius="sm" />
			</>
			: <div onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)} >
				<Text>
					<span>{label}</span>
					{mouseOver && <span style={{ float: "right" }}>
						<Tooltip label={dict.zoomIn} withArrow openDelay={300}>
							<span><ZoomIn style={{ stroke: theme.colors.gray[6], cursor: "pointer" }} size={20} onClick={() => setSectionOpened(true)} /></span>
						</Tooltip>
						<Tooltip label={dict.reportIncorrect} withArrow openDelay={300}>
							<span><AlertCircle style={{ stroke: theme.colors.gray[6], cursor: "pointer" }} size={20} onClick={() => setProblemOpened(true)} /></span>
						</Tooltip>
					</span>}
				</Text>
				<Paper radius="md" p="xs" style={{ backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2], minHeight: minHeight }}>
					<Spoiler maxHeight={maxHeight} showLabel={dict.showMore} hideLabel="Hide">
						{children}
					</Spoiler>
				</Paper>
				{problemOpened &&
					<Modal opened={problemOpened} onClose={() => { setProblemOpened(false); setMouseOver(false); }} title={dict.reportIncorrect}>
						<LeadIssue section={label} leadId={leadId} closePopup={() => { setProblemOpened(false); setMouseOver(false); }} />
					</Modal>
				}
				{sectionOpened &&
					<Modal opened={sectionOpened} title={label} centered size={700}
						onClose={() => { setSectionOpened(false); setMouseOver(false); }} >
						<Paper radius="md" p="xs" style={{ backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2], minHeight: minHeight }}>
							{children}
						</Paper>
					</Modal>
				}
			</div>
	);
}