import React, { useState } from "react";
import { Menu, ActionIcon } from "@mantine/core";
import { DotsVertical, Tag, FileExport, AddressBook, ArrowsShuffle, ZoomQuestion, At, MapSearch, Plus } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import LeadsActionTag from "./LeadsActionTag";
import LeadsActionAssign from "./LeadsActionAssign";
import LeadsActionHubspot from "./LeadsActionHubspot";
import LeadsActionValidEmail from "./LeadsActionValidEmail";
import LeadsActionGuessEmail from "./LeadsActionGuessEmail";
import LeadsActionExport from "./LeadsActionExport";
import LeadsActionFetchContact from "./LeadsActionFetchContact";
import LeadsActionWebAddress from "./LeadsActionWebAddress";
import CompanyCreate from "pages/Company/companyCreate";

export default function LeadsAction({ filters, rowCount }) {
	const dict = useDictionary("leadsMenu");
	const [actionOpened, setActionOpened] = useState(false);

	const user = JSON.parse(localStorage.getItem("user"));

	return (
		<>
			<Menu>
				<Menu.Target><ActionIcon variant="filled" color="blue" radius={16}><DotsVertical size={18} /></ActionIcon></Menu.Target>
				<Menu.Dropdown>
					<Menu.Label>{rowCount} {dict.leads}</Menu.Label>
					<Menu.Item icon={<Plus size={18} />} onClick={() => setActionOpened("create")}>{dict.createLead}</Menu.Item>
					<Menu.Item icon={<Tag size={18} />} onClick={() => setActionOpened("tag")}>{dict.addRemoveTags}</Menu.Item>
					<Menu.Item icon={<ZoomQuestion size={18} />} onClick={() => setActionOpened("guess")}>{dict.guessEmail}</Menu.Item>
					<Menu.Item icon={<MapSearch size={18} />} onClick={() => setActionOpened("address")}>{dict.webAddress}</Menu.Item>
					<Menu.Item icon={<FileExport size={18} />} onClick={() => setActionOpened("export")}>{dict.exportResult}</Menu.Item>
					<Menu.Item icon={<AddressBook size={18} />} onClick={() => setActionOpened("contact")}>{dict.buyContact}</Menu.Item>
					<Menu.Item icon={<At size={18} />} onClick={() => setActionOpened("valid")}>{dict.validEmail}</Menu.Item>
					<Menu.Item icon={<ArrowsShuffle size={18} />} onClick={() => setActionOpened("assign")}>{dict.assign}</Menu.Item>
					{user.hasHubspot &&
						<Menu.Item icon={<img src="/hubspot.png" width="18" alt="Hubspot" />} onClick={() => setActionOpened("hubspot")}>{dict.exportHubspot}</Menu.Item>}
				</Menu.Dropdown>
			</Menu>
			<CompanyCreate opened={actionOpened == "create"} setOpened={setActionOpened} />
			<LeadsActionTag dict={dict} setOpened={setActionOpened} opened={actionOpened == "tag"} filters={filters} />
			<LeadsActionGuessEmail dict={dict} setOpened={setActionOpened} opened={actionOpened == "guess"} filters={filters} rowCount={rowCount} />
			<LeadsActionWebAddress dict={dict} setOpened={setActionOpened} opened={actionOpened == "address"} filters={filters} rowCount={rowCount} />
			<LeadsActionExport dict={dict} setOpened={setActionOpened} opened={actionOpened == "export"} filters={filters} rowCount={rowCount} user={user} />
			<LeadsActionFetchContact dict={dict} setOpened={setActionOpened} opened={actionOpened == "contact"} filters={filters} rowCount={rowCount} />
			<LeadsActionValidEmail dict={dict} setOpened={setActionOpened} opened={actionOpened == "valid"} filters={filters} rowCount={rowCount} />
			<LeadsActionAssign dict={dict} setOpened={setActionOpened} opened={actionOpened == "assign"} filters={filters} rowCount={rowCount} />
			<LeadsActionHubspot dict={dict} setOpened={setActionOpened} opened={actionOpened == "hubspot"} filters={filters} rowCount={rowCount} />
		</>
	);
}