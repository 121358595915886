import React, { useState, useEffect } from "react";
import { Textarea, TextInput, Group, Modal, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DeviceFloppy } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import { objectToFormData, nullIfEmpty, isUrl } from "utils/helpers";
import { fetchUrl, getUrl } from "utils/urlHelpers";
import LazyDropDown from "components/LazyDropDown";
import Confirm from "components/Confirm";

export default function IndustryNew({ opened, setOpened, setSavedIndustry }) {
	const dict = useDictionary("industries");
	const [saving, setSaving] = useState(null);
	const [showStopWordWarning, setShowStopWordWarning] = useState(false);

	const form = useForm({
		initialValues: {
			name: "", status: 2, baseSites: "", industryStopWords: null, stopWords: null,
			countryId: JSON.parse(localStorage.getItem("user")).countryId
		},
		validate: {
			name: value => value.length == 0 ? dict.invalidName : null,
			baseSites: value => (value?.split("\n").filter(x => isUrl(x)).length || 0) < 7 ? dict.notEnoughSites : null,
		}
	});
	
	useEffect(() => {
		if (form.values.industryStopWords)
			getUrl(`industry/${form.values.industryStopWords}`, industry => {
				form.setValues({ stopWords: industry.stopWords });
			});
	}, [form.values.industryStopWords]);
	
	const saveIndustry = checkStopWords => {
		const data = { ...form.values, ...{ baseSites: nullIfEmpty(form.values.baseSites)?.split("\n").filter(x => isUrl(x)) } };
		if (checkStopWords && !data.stopWords)
			setShowStopWordWarning(true);
		else
			fetchUrl("industry", "POST", objectToFormData(data), setSaving, dict.industrySaved, setSavedIndustry, dict.industrySaveError);
	};

	return (
		<Modal size="50%" opened={opened} onClose={() => setOpened(false)} centered overflow="outside" title={dict.editIndustry}>
			<form onSubmit={form.onSubmit(() => saveIndustry(true))}>
				<TextInput label={dict.industryName} required {...form.getInputProps("name")} maxLength={50} />
				<LazyDropDown label={dict.importStopWords} path="industry" value={form.values.industryStopWords + ""} onChange={val => form.setValues({ industryStopWords: val })} />
				<Textarea label={dict.refSites} {...form.getInputProps("baseSites")} minRows={10} maxRows={10} />
				<Group position="center" mt="sm">
					<Button type="submit" leftIcon={<DeviceFloppy size={20} />} loading={saving}>{dict.create}</Button>
				</Group>
			</form>
			<Confirm opened={showStopWordWarning} setOpened={setShowStopWordWarning} yesLabel={dict.confirm} yesAction={saveIndustry}>{dict.noStopWordWarning}</Confirm>
		</Modal>
	);
}