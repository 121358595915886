import React, { useState, useEffect } from "react";
import { Group, Modal, Pagination, Stack, Text } from "@mantine/core";
import { useDictionary } from "i18n/dictionary";
import RoadLeadEdit from "./RoadLeadEdit";
import { fetchUrl } from "utils/urlHelpers";
import RoadLeadsTable from "./RoadLeadsTable";
import RoadLeadsFilter from "./RoadLeadsFilter";
import ContentBox from "components/ContentBox";


export default function RoadLead() {
	const dict = useDictionary("roadLeads");
	const [roadLeads, setRoadLeads] = useState(null);
	const [leadCount, setLeadCount] = useState(null);
	const [filters, setFilters] = useState(null);
	const [activePage, setActivePage] = useState(1);
	const [savedRoadLead, setSavedRoadLead] = useState(null);
	const [roadLeadOpened, setRoadLeadOpened] = useState(null);

	useEffect(() => {
		if (roadLeads && savedRoadLead != null) {
			let newRoadLeads = roadLeads.slice();
			if (roadLeadOpened.id == savedRoadLead.id)
				newRoadLeads.forEach((user, i) => {
					if (user.id == savedRoadLead.id)
						newRoadLeads[i] = { ...savedRoadLead };
				});
			else {
				newRoadLeads.push(savedRoadLead);
				newRoadLeads = newRoadLeads.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
			}
			setRoadLeads(newRoadLeads);
			setRoadLeadOpened(null);
		}
	}, [savedRoadLead]);

	useEffect(() => {
		if (filters === null) return;
		if (filters.rowStart === undefined && activePage > 1) {	// filter has changed => back to page 1
			setActivePage(1);
			return;
		}
		filters.rowStart = (activePage - 1) * filters.rowCount;
		fetchUrl("roadLead/search", "POST", JSON.stringify(filters), null, null, data => {
			setRoadLeads(data.roadLeads);
			setLeadCount(data.rowCount);
		});
	}, [filters, activePage]);

	const openRoadLead = (e, lead) => {
		if (e.target.tagName != "svg")
			setRoadLeadOpened(lead);
	};

	return (
		<Stack>
			<ContentBox>
				<Stack>
					<Group position="apart">
						<RoadLeadsFilter dict={dict} setFilters={setFilters} />
						<Pagination total={filters == null ? 0 : Math.ceil(leadCount / filters?.rowCount)} page={activePage} onChange={setActivePage} />
					</Group>
					<RoadLeadsTable dict={dict} roadLeads={roadLeads} openRoadLead={openRoadLead} />
				</Stack>
			</ContentBox>
			{roadLeads && <Text align="center" size="sm">{dict.roadLeadFound?.replace("{count}", leadCount)}</Text>}
			<Modal size="75%" opened={roadLeadOpened != null} onClose={() => setRoadLeadOpened(null)} centered overflow="outside" title={dict.roadLead}>
				<RoadLeadEdit dict={dict} roadLead={roadLeadOpened} opened={roadLeadOpened != null} setSavedRoadLead={setSavedRoadLead} />
			</Modal>
		</Stack>
	);
}