import React, { useEffect, useState } from "react";
import { TextInput, Group, Button, MultiSelect } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Trash, DeviceFloppy } from "tabler-icons-react";
import Confirm from "components/Confirm";
import { objectToFormData } from "utils/helpers";
import { fetchUrl, getUrl } from "utils/urlHelpers";

export default function UserDetails({ dict, user, setSavedUser, setDeletedUser }) {
	const [saving, setSaving] = useState(null);
	const [deleting, setDeleting] = useState(null);
	const [deleteConfOpened, setDeleteConfOpened] = useState(false);
	const [roles, setRoles] = useState(null);

	useEffect(() => {
		if (user?.id && user?.roles)
			form.setValues({ ...user });
		if (roles == null)
			getUrl("user/role", data => setRoles(data.map(x => ({ value: x.id, label: dict[x.name] }))));
	}, [user?.roles]);

	const form = useForm({
		initialValues: { id: null, firstName: "", lastName: "", email: "", roles: [] },
		validate: {
			roles: value => value.some(x => x == roles.find(y => y.label == dict["Diamond Website Reader"]).value) ? null : dict.selectRole, // Check "read only" role is selected
		},
	});
	
	const saveUser = values => {
		let data = { ...values };
		let formData = objectToFormData({ ...values });
		fetchUrl(`user/${data.id || ""}`, data.id ? "PUT" : "POST", formData, setSaving, dict.userSaved, user => setSavedUser(user), dict.userNotSaved);
	};

	const deleteUser = () => {
		fetchUrl(`user/${user.id}`, "DELETE", null, setDeleting, dict.userDeleted, () => setDeletedUser({ id: user.id }), dict.userNotDeleted);
	};
	const isCurrentUser = user?.id && user.id == JSON.parse(localStorage.getItem("user")).id;

	return (
		<>
			<form onSubmit={form.onSubmit(values => saveUser(values))}>
				<TextInput label={dict.firstName} required {...form.getInputProps("firstName")} maxLength={50}></TextInput>
				<TextInput label={dict.lastName} required {...form.getInputProps("lastName")} maxLength={50}></TextInput>
				<TextInput label={dict.email} required {...form.getInputProps("email")} maxLength={320} type="email"></TextInput>
				<MultiSelect label={dict.roles} data={roles || []} multiple {...form.getInputProps("roles")} disabled={isCurrentUser} />
				<Group position="center" mt="sm">
					{user?.id &&
						<Button leftIcon={<Trash size={20} />} color="red" variant="outline" loading={deleting} onClick={() => setDeleteConfOpened(true)} disabled={isCurrentUser}>
							{dict.delete}
						</Button>
					}
					<Button type="submit" leftIcon={<DeviceFloppy size={20} />} loading={saving}>{dict.save}</Button>
				</Group>
			</form>
			<Confirm opened={deleteConfOpened} setOpened={setDeleteConfOpened} yesLabel={dict.delete} yesAction={deleteUser}>{dict.delConf}</Confirm>
		</>
	);
}