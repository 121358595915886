import React, { useEffect, useState } from "react";
import { Navbar, Text, Accordion, Divider } from "@mantine/core";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Dashboard, Users, AddressBook, BuildingFactory, Bulb, Truck, Settings, Swords, Tool, Subtask, Speakerphone } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import { AppMenuStyles } from "./AppMenuCss";

export default function AppMenu({ navBarOpened, setNavBarOpened }) {
	const dict = useDictionary("menu");
	const { classes, cx } = AppMenuStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const [parentMenuOpened, setParentMenuOpened] = useState(null);

	useEffect(() => {
		let parent = [...menuItems, ...footerItems].find(x => x.children?.some(y => y.link === location.pathname));
		if (parent) setParentMenuOpened({ label: parent.label });
	}, []);

	useEffect(() => {
		let item = menuItems.find(x => x.label === parentMenuOpened?.label);
		if (item && parentMenuOpened?.origin == "parent") navigate(item.link);
	}, [parentMenuOpened]);

	const menuItems = [
		{ link: "/dashboard", label: dict.dashboard, icon: Dashboard },
		{
			link: "/leads", label: dict.leads, icon: Bulb, children: [
				{ link: "/leads", label: dict.leads, icon: Bulb },
				{ link: "/roadLeads", label: dict.roadLeads, icon: Truck },
			]
		},
		{ link: "/contacts", label: dict.contacts, icon: AddressBook },
		{ link: "/process/select", label: dict.processes, icon: Subtask },
	];

	const footerItems = [
		{
			label: dict.admin, icon: Settings, children: [
				{ link: "/admin/industries", label: dict.industries, icon: BuildingFactory },
				{ link: "/admin/competitors", label: dict.competitors, icon: Swords },
				{ link: "/admin/contacts", label: dict.contactScoring, icon: AddressBook },
				{ link: "/admin/issues", label: dict.feedback, icon: Speakerphone },
				{ link: "/admin/users", label: dict.users, icon: Users },
				{ link: "/admin/settings", label: dict.accountParam, icon: Tool },
			]
		},
	];

	const getMenuItems = (items, key, hasParent) => {
		return items.map(item =>
			item.children
				? getParentMenu(item)
				: getLink(item, key, hasParent)
		);
	};

	const getLink = (item, key, hasParent) => {
		return (
			<NavLink to={item.link} key={item.icon}
				className={({ isActive }) => { return cx(classes.link, { [classes.linkActive]: isActive }); }}
				style={{ fontWeight: hasParent ? 300 : 500 }}
				onClick={() => {
					setNavBarOpened(false);
					setParentMenuOpened({ label: key || null });
				}}>
				<item.icon className={classes.linkIcon} />
				<span>{item.label}</span>
			</NavLink>
		);
	};
	const getParentMenu = item => {
		return dict.leads && (
			<Accordion key={item.label} value={parentMenuOpened?.label}
				onChange={label => setParentMenuOpened({ label: label, origin: "parent" })}
				styles={{
					item: { borderBottom: "none" },
					chevron: {
						color: "white", 
						transform: "rotate(270deg)",
						"&[data-rotate]": { transform: "rotate(0deg)" }
					},
				}}>
				<Accordion.Item value={item.label}>
					<Accordion.Control icon={<item.icon className={classes.parentIcon} />} className={classes.parentLinkButton}>
						<Text className={classes.parentLink}>{item.label}</Text>
					</Accordion.Control>
					<Accordion.Panel>
						{getMenuItems(item.children, item.label, true)}
					</Accordion.Panel>
				</Accordion.Item>
			</Accordion>
		);
	};

	return (
		<Navbar p="xs" hiddenBreakpoint="sm" hidden={!navBarOpened} width={{ sm: 200, lg: 250 }} className={classes.navbar}>
			<Navbar.Section grow mt={0}>
				<nav>
					{getMenuItems(menuItems)}
				</nav>
			</Navbar.Section>
			<Navbar.Section>
				<nav>
					<Divider className={classes.divider}/>
					{getMenuItems(footerItems)}
				</nav>
			</Navbar.Section>
		</Navbar>
	);
}