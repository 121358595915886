import React, { useState } from "react";
import { Anchor, Divider, ScrollArea, Tabs, Text } from "@mantine/core";
import { MapPin, Registered, WorldWww } from "tabler-icons-react";
import { getUrl } from "utils/urlHelpers";
import LeadCompanyAddressEdit from "./LeadCompanyAddressEdit";

export default function LeadCompanyAddress({ dict, company, setCompany }) {
	const [webAddresses, setWebAddresses] = useState(null);
	const [addressOpened, setAddressOpened] = useState(false);

	const tabChanged = tab => {
		if (tab == "web" && webAddresses == null)
			getUrl(`company/${company.id}/webAddress`, setWebAddresses);
	};
	const formatAddress = add => {
		return add && <>
			<Text size="sm">{add.address1}</Text>
			<Text size="sm">{add.address2}</Text>
			<Text size="sm">
				{add.countryId == 1
					? (add.town ? add.town + " " : "") + (add.postcode || "")
					: (add.postcode ? add.postcode + " " : "") + (add.town || "")}
			</Text>
		</> || <></>;
	};

	const registerMatch = company?.matches.find(x => x.sourceType == 1 || x.sourceType == 2);
	const googleMatch = company?.matches.find(x => x.sourceType == 5);

	return (
		<>
			<Tabs defaultValue="main" onTabChange={tabChanged}>
				<Tabs.List>
					<Tabs.Tab value="main">{dict.address}</Tabs.Tab>
					<Tabs.Tab value="register" icon={<Registered size={16} />}></Tabs.Tab>
					<Tabs.Tab value="google" icon={<MapPin size={16} />}></Tabs.Tab>
					<Tabs.Tab value="web" icon={<WorldWww size={16} />}></Tabs.Tab>
				</Tabs.List>
				<Tabs.Panel value="main" pt="xs">
					<Anchor onClick={() => setAddressOpened(true)}>{formatAddress(company)}</Anchor>
				</Tabs.Panel>
				<Tabs.Panel value="register" pt="xs">
					{formatAddress(registerMatch)}
				</Tabs.Panel>
				<Tabs.Panel value="google" pt="xs">
					{formatAddress(googleMatch)}
				</Tabs.Panel>
				<Tabs.Panel value="web" pt="xs">
					<ScrollArea style={{ height: 100 }} type="auto">
						{webAddresses?.map(x =>
							<span key={x.id}>
								<Text size="sm">{x.formattedAddress}</Text>
								{webAddresses?.length > 1 && <Divider my={4} />}
							</span>
						)}
					</ScrollArea>
				</Tabs.Panel>
			</Tabs>
			{addressOpened &&
				<LeadCompanyAddressEdit company={company} opened={addressOpened} setOpened={setAddressOpened} setCompany={setCompany} />
			}
		</>
	);
}