import { Button, Center, Group, Modal, Space, Text } from "@mantine/core";
import React from "react";
import { Login } from "tabler-icons-react";

export default function AppLogin({ loginWithRedirect }) {
	return (
		<Modal opened={true} withCloseButton={false} closeOnClickOutside={false} centered>
			<Text size="xl">
				<Center>
					<img src="/logo192.png" width="32" alt="Welcome" />&nbsp;&nbsp; Welcome to Diamond Leads
				</Center>
			</Text>
			<Space h="xl" />
			<Group position="center">
				<Button leftIcon={<Login size={24} />} onClick={() => loginWithRedirect()}>Log In</Button>
			</Group>
			<Space h="xl" /><Space h="xl" />
			<Group position="right">
				<img src="https://cdn.bigchange.com/img/Login/powered-by-bigchange.svg" width="155" alt="BigChange"></img>
			</Group>
		</Modal>
	);
}