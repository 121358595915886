import React, { useState } from "react";
import { ActionIcon, createStyles, Group, Table, Tooltip } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { Phone, At, Candle, CalendarEvent, Check, Plus } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import { formatDate } from "utils/helpers";
import LeadSection from "../LeadSection";
import LeadContactEdit from "./LeadContactEdit";

const useStyles = createStyles(theme => ({
	clickableRow: {
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		cursor: "pointer",
	},
	row: {
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
	},
	score: {
		borderColor: theme.colorScheme === "dark" ? theme.colors.gray[4] : theme.colors.dark[8],
		borderWidth: 1, borderStyle: "solid", borderRadius: "12px", padding: 2, fontSize: theme.fontSizes.xs,
		whiteSpace: "nowrap"
	}
}));

export default function Contacts({ company, setCompany, label, height }) {
	const { classes } = useStyles();
	const dict = useDictionary("leadContacts");
	const [contactOpened, setContactOpened] = useState(null);

	const setUpdatedContact = savedContact => {
		if (company.contacts.some(x => x.id == savedContact.id))
			company.contacts.forEach((contact, i) => {
				if (contact.id == savedContact.id)
					company.contacts[i] = { ...savedContact };
			});
		else
			company.contacts.push(savedContact);
		setCompany(company);
		setContactOpened(null);
	};
	const setDeletedContact = id => {
		company.contacts = company.contacts.filter(x => { return x.id != id; });
		setCompany(company);
		setContactOpened(null);
	};

	const copyToClipboard = text => {
		if (!text) return;
		navigator.clipboard.writeText(text);
		showNotification({ icon: <Check size={18} />, color: "teal", message: `${text} ${dict.copiedToClipboard}` });
	};
	const getMatchedSource = sourceType => {
		switch (sourceType) {
			case 0: return ` (${dict.imported})`; case 1: return " (Companies House)";
			case 2: return " (Gouv.fr)"; case 5: return " (Google Map)";
			case 11: return ` (${dict.website})`; case 15: return " (BigChange)";
			case 16: return " (Hubspot)";
			case 17: return " (MarketLocation)";
			case 18: return " (NeverBounce)";
			case 19: return " (Cognism)";
			case 20: return ` (${dict.manualEntry})`;
			default: return ` (${sourceType})`;
		}
	};
	const getMatchedIcon = match => {
		return <span key={match.id}>
			{match.phone ? <Tooltip label={match.phone + getMatchedSource(match.sourceType)} withArrow><span><Phone size={18} onClick={() => { copyToClipboard(match.phone); }} /></span></Tooltip> : <></>}
			{match.email ? <Tooltip label={match.email + getMatchedSource(match.sourceType)} withArrow><span><At size={18} onClick={() => { copyToClipboard(match.email); }} /></span></Tooltip> : <></>}
			{match.dateJoined ? <Tooltip label={`${dict.joined} ${formatDate(match.dateJoined)}${getMatchedSource(match.sourceType)}`} withArrow><span><CalendarEvent size={18} /></span></Tooltip> : <></>}
			{match.yearOfBirth ? <Tooltip label={dict.yearsOld?.replace("{age}", new Date().getFullYear() - match.yearOfBirth) + getMatchedSource(match.sourceType)} withArrow><span><Candle size={18} /></span></Tooltip> : <></>}
			&nbsp;</span>;

	};
	const showContacts = forCustomer => {
		return company?.contacts.filter(x => { return forCustomer && x.customerId !== null || !forCustomer && x.customerId === null; })
			.map(contact =>
				<tr key={contact.id} className={forCustomer && (contact.firstName || contact.lastName) ? classes.clickableRow : classes.row}
					onClick={forCustomer && (contact.firstName || contact.lastName) ? () => setContactOpened(contact) : undefined}>
					<td>
						{contact.score &&
							<Tooltip label={dict.contactScore} withArrow>
								<span className={classes.score}>{contact.score}</span>
							</Tooltip>
						}
					</td>
					{contact.firstName === null && contact.lastName === null
						? <td colSpan="3"><i>{dict.companyContact}</i></td>
						: <>
							<td style={{ minWidth: 75 }}>{contact.firstName}</td>
							<td style={{ minWidth: 75 }}>{contact.lastName}</td>
							<td>{contact.position}</td>
						</>
					}
					<td width={110}>
						{contact.matches.map(match => getMatchedIcon(match))}
					</td>
				</tr>
			);
	};
	const addContact = () => {
		setContactOpened({ companyId: company.id, matches: [{ id: 0, sourceType: 20, matchReliability: 1 }] });
	};

	const content = company?.contacts == null ? null :
		<Table highlightOnHover verticalSpacing={2} horizontalSpacing={2}>
			<thead>
				<tr><th colSpan="5">
					<Group position="apart">
						<b>{dict.yourContacts}</b>
						<Tooltip label={dict.addContact} openDelay={500}>
							<ActionIcon variant="outline" color="blue"
								onClick={addContact}>
								<Plus size={18} />
							</ActionIcon>
						</Tooltip>
					</Group>
				</th></tr>
			</thead>
			<tbody>{showContacts(true)}</tbody>
			<thead>
				<tr><th colSpan="5"><b><br />{dict.publicInfo}</b></th></tr>
			</thead>
			<tbody>{showContacts(false)}</tbody>
		</Table>;

	return (
		<>
			<LeadSection label={label} maxHeight={height - 55} minHeight={height}>{content}</LeadSection>
			{contactOpened &&
				<LeadContactEdit dict={dict} contactOpened={contactOpened} setContactOpened={setContactOpened}
					setUpdatedContact={setUpdatedContact} setDeletedContact={setDeletedContact}
					getMatchedSource={getMatchedSource} />
			}
		</>
	);
}