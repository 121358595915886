import React, { useState } from "react";
import { Modal, Stack, Text, NumberInput, Group, Button } from "@mantine/core";
import { fetchUrl } from "utils/urlHelpers";
import { X, Check } from "tabler-icons-react";

export default function LeadsActionValidEmail({ dict, opened, setOpened, rowCount, filters }) {
	const [contactCount, setContactCount] = useState(5);

	const validate = () => {
		const url = `leadProcessQueue/validateEmail?contactCount=${contactCount}`;
		fetchUrl(url, "POST", JSON.stringify(filters), null, dict.validEmailSent, () => setOpened(false));
	};

	return (
		<Modal centered title={dict.validEmail} opened={opened} onClose={() => setOpened(false)}>
			<Stack>
				<Text>{dict.validEmailConfirm?.replace("{count}", rowCount)}</Text>
				<NumberInput value={contactCount} label={dict.validEmailContact} onChange={setContactCount} />
				<Group position="center" mt="sm">
					<Button leftIcon={<X size={20} />} variant="outline" onClick={() => setOpened(false)}>{dict.cancel}</Button>
					<Button leftIcon={<Check size={20} />} onClick={validate}>{dict.validEmail}</Button>
				</Group>
			</Stack>
		</Modal>
	);
}