import React, { useEffect, useState } from "react";
import { useDictionary } from "i18n/dictionary";
import { Box, Button, Group, Image, Modal, Overlay, Stack, Switch, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { fetchUrl } from "utils/urlHelpers";
import { objectToFormData } from "utils/helpers";
import { DeviceFloppy, Refresh } from "tabler-icons-react";

export default function LeadCompanyAddressEdit({ company, opened, setOpened, setCompany }) {
	const dict = useDictionary("leadAddress");
	const [mapLocation, setMapLocation] = useState(null);
	const [mapLocationNeedsRefresh, setMapLocationNeedsRefresh] = useState(false);
	const [saving, setSaving] = useState(null);

	useEffect(() => {
		setMapLocation(`${company.latitude},${company.longitude}`);
	}, []);

	const form = useForm({
		initialValues: {
			id: company.id, address1: company.address1, address2: company.address2 || "",
			postcode: company.postcode, town: company.town, country: company.country,
			countryId: company.countryId
		},
		validate: {},
	});

	useEffect(() => {
		if (form.isDirty())
			setMapLocationNeedsRefresh(true);
	}, [form.values.address1, form.values.address2, form.values.town, form.values.postcode]);

	const updateMapLocation = () => {
		setMapLocationNeedsRefresh(false);
		setMapLocation(encodeURIComponent(`${form.values.address1} ${form.values.address2} ${form.values.town} ${form.values.postcode} ${form.values.country}`));
	};
	const saveCompany = values => {
		let formData = objectToFormData({ ...values });
		fetchUrl(`company/${values.id}/address`, "PUT", formData, setSaving, dict.addressSaved, data => {
			setCompany({ ...company, ...data });
			setOpened(false);
		});
	};

	return (
		<Modal opened={opened} onClose={() => setOpened(false)} size={900}>
			<form onSubmit={form.onSubmit(values => saveCompany(values))}>
				<Group align="flex-start" position="apart">
					<Stack spacing={4} style={{ width: 320 }}>
						<Switch label={dict.addressVerified} checked={company.addressVerified} disabled></Switch>
						<Switch label={dict.addressManuallyVerified}  checked={company.addressManuallyVerified}  disabled mb="xl"></Switch>
						<TextInput label={`${dict.address} 1`} {...form.getInputProps("address1")} maxLength={255} required></TextInput>
						<TextInput label={`${dict.address} 2`} {...form.getInputProps("address2")} maxLength={255}></TextInput>
						<TextInput label={dict.town} {...form.getInputProps("town")} maxLength={255} required></TextInput>
						<TextInput label={dict.postcode} {...form.getInputProps("postcode")} maxLength={20} required></TextInput>
					</Stack>
					<Box sx={{ position: "relative" }}>
						{mapLocationNeedsRefresh &&
							<>
								<Overlay opacity={0.6} color="#BBB" zIndex={5} />
								<Button onClick={updateMapLocation} variant="light" leftIcon={<Refresh />}
									sx={{ position: "absolute", zIndex: 6, margin: "auto", left: 0, right: 0, top: 0, bottom: 0, width: 200 }}>
									{dict.refreshMap}
								</Button>
							</>
						}
						<Image radius="md" alt="Map" width={500} height={400}
							src={`https://maps.googleapis.com/maps/api/staticmap?markers=color:blue|${mapLocation}&size=500x400&zoom=13&key=AIzaSyAjtPzGlFmPd3g6eePOq1XP-6_RWo6SsiI`} />
					</Box>
				</Group>
				<Group position="center" mt="md">
					<Button type="submit" leftIcon={<DeviceFloppy size={20} />} loading={saving}>{dict.save}</Button>
				</Group>
			</form>
		</Modal>
	);
}