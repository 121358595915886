import React, { useState } from "react";
import { Group, Text } from "@mantine/core";
import { IconUpload, IconFileText, IconX } from "@tabler/icons";
import { Dropzone } from "@mantine/dropzone";
import * as Papa from "papaparse";
import { showNotification } from "@mantine/notifications";

export function ImportFile({ dict, setFileContent, setUploadedData, setFileName }) {
	const [uploading, setUploading] = useState(null);

	const showError = (message) => {
		setUploading(false);
		showNotification({
			icon: <IconX size={18} />, color: "red", message: message
		});
	};
	const onFileDropped = (files) => {
		setUploading(true);
		const reader = new FileReader();
		reader.onabort = () => showError(dict.importError);
		reader.onerror = () => showError(dict.importError);
		reader.onload = () => {
			const data = Papa.parse(reader.result);
			setFileContent(reader.result);
			setUploadedData(data.data);
			setUploading(false);
		};
		setFileName(files[0].name);
		reader.readAsText(files[0]);
	};

	return (
		<Dropzone maxSize={30 * 1000000} maxFiles={1} accept={{ "text/csv": [".csv"] }} loading={uploading} onDrop={onFileDropped}>
			<Group position="center" style={{ minHeight: 150, pointerEvents: "none" }}>
				<Dropzone.Accept><IconUpload size={50} stroke={1.5} /></Dropzone.Accept>
				<Dropzone.Reject><IconX size={50} stroke={1.5} /></Dropzone.Reject>
				<Dropzone.Idle><IconFileText size={50} stroke={1.5} /></Dropzone.Idle>
				<div>
					<Text size="xl" inline>{dict.dragFile}</Text>
					<Text size="sm" color="dimmed" inline mt={7}>{dict.maxSize}</Text>
				</div>
			</Group>
		</Dropzone>
	);
}