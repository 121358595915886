import React, { useState } from "react";
import { Table } from "@mantine/core";
import PopupCognism from "../PopupCognism/PopupCognism";
import LeadsTableSize from "pages/Leads/LeadsTableSize";

export default function TableCognism({ companies }) {
	const [openedCompany, setOpenedCompany] = useState(null);

	const rows = companies?.map(company => (
		<tr style={{ cursor: "pointer" }} key={company.id} onClick={() => setOpenedCompany(company)}>
			<td>{company.name}</td>
			<td>{company.address ? company.address.town : (company.addresses.length && company.addresses[0].town)}</td>
			<td><LeadsTableSize lead={company} /></td>
		</tr>
	));
	return (
		<>
			<Table highlightOnHover>
				<thead><tr>
					<th>Name</th>
					<th>Town</th>
					<th>Size</th>
				</tr></thead>
				<tbody>{rows}</tbody>
			</Table>
			{openedCompany && <PopupCognism company={openedCompany} setCompany={setOpenedCompany} />}
		</>
	);
}