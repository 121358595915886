import { ActionIcon, Group, Modal, Tabs, Text } from "@mantine/core";
import React, { useState } from "react";
import { AddressBook, BrandLinkedin, FileDescription, WorldWww } from "tabler-icons-react";
import DiamondDetails from "./DiamondDetails";
import DiamondContact from "./DiamondContact";

export default function PopupDiamond({ company, setCompany }) {
	const [activeTab, setActiveTab] = useState("details");

	const title = <Group position="left" spacing={0}>
		<Text mr="lg">{company.name}</Text>
		{company.url && <ActionIcon onClick={() => window.open(`https://${company.url}`)} color="blue"><WorldWww size={24} /></ActionIcon>}
		{company.linkedIn && <ActionIcon onClick={() => window.open(company.linkedIn)} color="blue" ><BrandLinkedin size={24} /></ActionIcon>}
	</Group>;

	return (
		<Modal opened={company != null} onClose={() => setCompany(null)} title={title} size={800}>
			<Tabs value={activeTab} onTabChange={setActiveTab}>
				<Tabs.List mb="xl">
					<Tabs.Tab value="details" icon={<FileDescription size={18} />}>Details</Tabs.Tab>
					<Tabs.Tab value="contacts" icon={<AddressBook size={18} />}>Contacts</Tabs.Tab>
				</Tabs.List>
				<Tabs.Panel value="details">
					<DiamondDetails company={company} visible={activeTab == "details"} />
				</Tabs.Panel>
				<Tabs.Panel value="contacts">
					<DiamondContact company={company} visible={activeTab == "contacts"} />
				</Tabs.Panel>
			</Tabs>
		</Modal>
	);
}