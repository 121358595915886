import React, { useEffect, useState } from "react";
import { Group, Stack, Text } from "@mantine/core";
import RoadLeadLogin from "./RoadLeadLogin";
import RoadLeadForm from "./RoadLeadForm";
import { NotificationsProvider } from "@mantine/notifications";

export default function RoadLead() {
	const [token, setToken] = useState(null);

	useEffect(() => {
		const sessionDetails = JSON.parse(sessionStorage.getItem("roadLeadLogged") || "{}");
		if (sessionDetails.expire && new Date(sessionDetails.expire) > new Date())
			setToken(sessionDetails.token);
		else
			setToken(undefined);

		replaceManifest();	// removes Diamond Lead site manifest and add the RoadLead one (so adding shortcut on smartphones redirects to correct page)
	}, []);

	const replaceManifest = () => {
		document.head.querySelectorAll("link[rel='manifest']").forEach(function (e) {
			e.parentElement.removeChild(e);
		});
		let link = document.createElement("link");
		link.rel = "manifest";
		document.head.appendChild(link);
		link.href = `${process.env.PUBLIC_URL}/manifestRoad.json`;
	};

	return (
		<NotificationsProvider>
			<Group position="center">
				<Stack sx={{ width: 450, padding: 10 }} >
					<Group position="center">
						<img src="/logo192.png" width="32" alt="Logo" />
						<Text size="lg">BigChange - Diamond Road Leads</Text>
					</Group>
					{token === undefined && <RoadLeadLogin setToken={setToken} />}
					{token?.length > 0 && <RoadLeadForm />}
				</Stack>
			</Group>
		</NotificationsProvider>
	);
}