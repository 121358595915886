import React, { useState, useEffect } from "react";
import { createStyles, Table, Skeleton, Group, Button, Modal } from "@mantine/core";
import { UserPlus } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import User from "./User.js";
import { formatDate } from "utils/helpers";
import { getUrl } from "utils/urlHelpers";
import ContentBox from "components/ContentBox.js";

const useStyles = createStyles((theme) => ({
	row: { cursor: "pointer" },
	rowInactive: {
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2],
		cursor: "pointer", fontStyle: "italic"
	},
}));

export default function Users() {
	const { classes } = useStyles();
	const dict = useDictionary("user");
	const [users, setUsers] = useState(null);
	const [savedUser, setSavedUser] = useState(null);
	const [deletedUser, setDeletedUser] = useState(null);
	const [userOpened, setUserOpened] = useState(null);

	useEffect(() => {
		if (users && savedUser != null) {
			let newUsers = users.slice();
			if (userOpened.id == savedUser.id)
				newUsers.forEach((user, i) => {
					if (user.id == savedUser.id)
						newUsers[i] = { ...savedUser };
				});
			else {
				newUsers.push(savedUser);
				newUsers = newUsers.sort((a, b) => a.firstName.toLowerCase() < b.firstName.toLowerCase() ? -1 : 1);
			}
			setUsers(newUsers);
			setUserOpened(null);
		}
	}, [savedUser]);

	useEffect(() => {
		if (users && deletedUser != null) {
			let newUsers = users.filter(user => {
				return user.id != deletedUser.id;
			});
			setUsers(newUsers);
			setUserOpened(null);
		}
	}, [deletedUser]);

	useEffect(() => {
		getUrl("user", setUsers);
	}, []);

	const openUser = (e, user) => {
		setUserOpened(user);
		setSavedUser(null);
		setDeletedUser(null);
	};

	const placeHolderRow = Array.from({ length: 6 }, (v, i) => <td key={i}><Skeleton height={42} /></td>);
	const placeHolder = Array.from({ length: 20 }, (v, i) => <tr key={i}>{placeHolderRow}</tr>);

	const rows = users?.map(user => (
		<tr key={user.id} className={user.lastLogin ? classes.row : classes.rowInactive} onClick={e => openUser(e, user)}>
			<td>{user.firstName}</td>
			<td>{user.lastName}</td>
			<td>{user.email}</td>
			<td>{formatDate(user.lastLogin)}</td>
		</tr>
	));
	return (
		<ContentBox>
			<Group position="right">
				<Button leftIcon={<UserPlus size={20} />} onClick={() => setUserOpened({})}>{dict.newUser}</Button>
			</Group>
			<Table mt={4} highlightOnHover sx={{ minWidth: 450 }}>
				<thead>
					<tr>
						<th>{dict.firstName}</th>
						<th>{dict.lastName}</th>
						<th>{dict.email}</th>
						<th>{dict.lastLogin}</th>
					</tr>
				</thead>
				<tbody>{users === null ? placeHolder : rows}</tbody>
			</Table>
			<Modal size={800} opened={userOpened != null} onClose={() => setUserOpened(null)} centered title={userOpened?.id ? dict.editUser : dict.newUser}>
				<User user={userOpened} opened={userOpened != null} setSavedUser={setSavedUser} setDeletedUser={setDeletedUser} setUserOpened={setUserOpened} />
			</Modal>
		</ContentBox>
	);
}