import React, { useEffect, useState } from "react";
import { Accordion, Group, Button, Text } from "@mantine/core";
import { Check } from "tabler-icons-react";
import { useForm } from "@mantine/form";
import { objectToFormData, toTitleCase } from "utils/helpers";
import { fetchUrl } from "utils/urlHelpers";
import dayjs from "dayjs";
import RoadLeadFormPhoto from "./RoadLeadFormPhoto";
import RoadLeadFormDetails from "./RoadLeadFormDetails";
import RoadLeadFormCompanyFound from "./RoadLeadFormCompanyFound";
import RoadLeadFormCompanyNotFound from "./RoadLeadFormCompanyNotFound";
import RoadLeadFormExtra from "./RoadLeadFormExtra";
import RoadLeadRanking from "./RoadLeadRanking";

export default function RoadLeadForm() {
	const [step, setStep] = useState("1");
	const [saving, setSaving] = useState(null);
	const [photo, setPhoto] = useState(null);
	const [locWarningShown, setLocWarningShown] = useState(false);
	const [location, setLocation] = useState(null);
	const [step2Result, setStep2Result] = useState(null);
	const [ocr, setOcr] = useState(null);
	const [rankingVisible, setRankingVisible] = useState(false);

	const form = useForm({
		initialValues: {
			name: "", website: "", phone: "", note: "", spotted: "now",
			date: new Date(), time: new Date(), address: "", postcode: "", town: "",
			photoDate: null, photoLatitude: null, photoLongitude: null, regNumber: null,
		},
		validate: {
			town: val => val.length < 3 && form.values.spotted == "earlier" ? "Please enter a town" : null,
		}
	});

	useEffect(() => {
		if (localStorage.getItem("locationPermission") == "ok")
			setLocWarningShown(true);
	}, []);

	useEffect(() => {
		if (!ocr) return;
		const name = ocr.find(x => x.type == null)?.text || "";
		form.setValues({
			name: toTitleCase(name),
			website: ocr.find(x => x.type == "url")?.text || "",
			phone: ocr.find(x => x.type == "phone")?.text || "",
		});
	}, [ocr]);

	useEffect(() => {
		if (!locWarningShown) return;
		navigator.geolocation.getCurrentPosition(position => {
			setLocation(position.coords || null);
			localStorage.setItem("locationPermission", "ok");
		}, () => {
			setLocation({});
		}, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true });
	}, [locWarningShown]);

	const saveRoadLead = (values) => {
		const extraData = {
			photo: photo,
			latitude: values.photoLatitude || location.latitude,
			longitude: values.photoLongitude || location.longitude,
			companyId: step2Result?.id || undefined, leadId: step2Result?.leadId || undefined,
			countryId: step2Result.countryId,
			date: values.photoDate || dayjs(values.date).format("YYYY-MM-DD") + " " + dayjs(values.time).format("HH:mm"),
			address: !values.photoLatitude && values.spotted == "earlier" && values.address || undefined,
			postcode: !values.photoLatitude && values.spotted == "earlier" && values.postcode || undefined,
			town: !values.photoLatitude && values.spotted == "earlier" && values.town || undefined,
		};
		let data = objectToFormData({ ...values, ...extraData });
		const leadSent = () => {
			form.reset();
			setPhoto(null);
			setStep2Result(null);
			setStep(1);
			setRankingVisible(true);
		};
		fetchUrl("roadLead", "POST", data, setSaving, null, leadSent, "Error while sending the road lead. Please try again");
	};

	const locationWarningContent = <>
		<Text my="md">Your about to be asked to share your location. This is necessary for posting a road lead. Please click on Allow.</Text>
		<Group position="center">
			<Button leftIcon={<Check size={20} />} onClick={() => setLocWarningShown(true)}>Understood</Button>
		</Group>
	</>;
	const locationErrorContent = <>
		<Text my="md" weight={500}>Your location is necessary for posting a road lead.</Text>
		<Text>Open your browser settings, allow location for this site then refresh this page.</Text>
		<Text my="md">Chrome mobile: Settings → Site settings → Location → diamond.bigchange.com → Allow.</Text>
	</>;

	return (
		!locWarningShown && locationWarningContent
		||
		(location == null || location.latitude === undefined) && locationErrorContent
		||
		rankingVisible && <RoadLeadRanking/>
		||
		<form onSubmit={form.onSubmit((values) => saveRoadLead(values))} >
			<Accordion value={step} onChange={setStep} styles={{
				item: { backgroundColor: "#F5F5F5", borderRadius: "15px", marginBottom: 10 },
				control: { "&:hover": { backgroundColor: "inherit", borderRadius: "15px" } }
			}}
			>
				<RoadLeadFormPhoto form={form} photo={photo} setPhoto={setPhoto} setStep={setStep} ocr={ocr} setOcr={setOcr} />
				<RoadLeadFormDetails form={form} setStep={setStep} setStep2Result={setStep2Result} />
				{step2Result?.regNumber
					? <RoadLeadFormCompanyFound step2Result={step2Result} setStep={setStep} />
					: <RoadLeadFormCompanyNotFound step2Result={step2Result} setStep={setStep} form={form} />
				}
				<RoadLeadFormExtra form={form} saving={saving} />
			</Accordion>
		</form>
	);
}