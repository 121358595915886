import React, { useRef, useEffect } from "react";
import mermaid from "mermaid";

export default function MermaidChart({ mermaidCode }) {
	const mermaidRef = useRef(null);

	useEffect(() => {
		mermaid.initialize({ securityLevel: "loose", maxTextSize: 500000 });

		if (mermaidRef.current && mermaidCode) {
			(async () => {
				const { svg } = await mermaid.render("generatedGraph", mermaidCode);
				mermaidRef.current.innerHTML = svg;
			})();
		}
	}, [mermaidCode]);

	return <div ref={mermaidRef} style={{ backgroundColor: "white", padding: 10, borderRadius: 6 }}></div>;
}
