import React, { useState, useEffect } from "react";
import { Tabs, Grid, TextInput, Group, Button, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DeviceFloppy, PlayCard, Settings, Trash } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import { objectToFormData } from "utils/helpers";
import { fetchUrl } from "utils/urlHelpers";
import Confirm from "components/Confirm";
import BattleCards from "../BattleCards";

export default function Competitor(p) {
	const dict = useDictionary("competitors");
	const [saving, setSaving] = useState(null);
	const [deleting, setDeleting] = useState(null);
	const [deleteConfOpened, setDeleteConfOpened] = useState(false);

	useEffect(() => {
		if (!p.competitor) return;
		form.setValues({ id: p.competitor.id || null, name: p.competitor.name || "" });
	}, [p.competitor?.id]);

	const form = useForm({
		initialValues: { name: "" },
	});

	const saveCompetitor = values => {
		let data = { ...values };
		fetchUrl(`competitor/${data.id || ""}`, data.id ? "PUT" : "POST", objectToFormData(data), setSaving, dict.competitorSaved, data => p.setSavedCompetitor(data), dict.competitorSaveError);
	};
	const deleteCompetitor = () => {
		fetchUrl(`competitor/${p.competitor.id}`, "DELETE", null, setDeleting, dict.competitorDeleted, () => p.setDeletedCompetitor({ id: p.competitor.id }), dict.competitorNotDeleted);
	};

	return (
		<>
			<Tabs defaultValue="details">
				<Tabs.List>
					<Tabs.Tab value="details" icon={<Settings size={16} />}>{dict.competitor}</Tabs.Tab>
					<Tabs.Tab value="battle" icon={<PlayCard size={16} />}>{dict.battleCard}</Tabs.Tab>
				</Tabs.List>
				<Tabs.Panel value="details">
					<form onSubmit={form.onSubmit(values => saveCompetitor(values))}>
						<Grid mt={10}>
							<Grid.Col md={3}>
								<TextInput label={dict.name} required {...form.getInputProps("name")} maxLength={50}></TextInput>
							</Grid.Col>
						</Grid>
						<Group position="center" mt="sm">
							{p.competitor?.id &&
								<Button leftIcon={<Trash size={20} />} color="red" variant="outline" loading={deleting} onClick={() => setDeleteConfOpened(true)}>
									{dict.delete}
								</Button>
							}
							<Button type="submit" leftIcon={<DeviceFloppy size={20} />} loading={saving} name={"Save"}>{dict.save}</Button>
						</Group>
					</form>
				</Tabs.Panel>
				<Tabs.Panel value="battle">
					{p.competitor?.id &&
						<BattleCards entityType={2} entityId={p.competitor?.id} />
						|| <Text mt={50} mb={50} ml={50}>{dict.safeFirst}</Text>}
				</Tabs.Panel>
			</Tabs>
			<Confirm opened={deleteConfOpened} setOpened={setDeleteConfOpened} yesLabel={dict.delete} yesAction={deleteCompetitor}>{dict.delConf}</Confirm>
		</>
	);
}