import React, { useEffect } from "react";

export default function Dashboard() {

	useEffect(() => {

	}, []);

	return (
		<>Nothing there yet!</>
	);
}