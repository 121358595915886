import React, { useState, useEffect } from "react";
import { TextInput, NumberInput, MultiSelect, Grid, Popover, Button, Select, Checkbox, Space, ScrollArea, SegmentedControl, Text, Accordion, Group, Stack, Tooltip } from "@mantine/core";
import {
	Typography, ArrowsMaximize, ArrowsMinimize, Calendar, Star, PhoneCall, BuildingFactory, FileImport, Files, Checks, Flag,
	Filter, ChartLine, Category2, MapPin, MapPins, Tag, Copy, AddressBook, WorldWww, Registered, ReportSearch, InfoCircle, Map, Diamond, ClipboardList, ListNumbers, AlertCircle, Bulb
} from "tabler-icons-react";
import LazyDropDown from "components/LazyDropDown";
import { useDictionary } from "i18n/dictionary";
import LeadsActiveFilters from "./LeadsActiveFilters";
import LeadFilterAiData from "./LeadFilterAiData";

function AccordionControl({ icon, label, sub }) {
	return (
		<Accordion.Control py={4} pl={0} pr={8}>
			<Group>
				{icon}
				<Stack spacing={0}>
					<Text weight={500}>{label}</Text>
					<Text size="xs">{sub}</Text>
				</Stack>
			</Group>
		</Accordion.Control>
	);
}

export default function LeadsFilter({ setFilters }) {
	const dict = useDictionary("leadFilter");
	const [lastChangedFilter, setLastChangedFilter] = useState(null);
	const [filterTimeout, setFilterTimeout] = useState(1);
	const [name, setName] = useState(null);
	const [sizeMin, setSizeMin] = useState(null);
	const [sizeMax, setSizeMax] = useState(null);
	const [scoreMin, setScoreMin] = useState(null);
	const [scoreMax, setScoreMax] = useState(null);
	const [ageMin, setAgeMin] = useState(null);
	const [lastTouched, setLastTouched] = useState(null);
	const [industries, setIndustries] = useState(null);
	const [importId, setImportId] = useState(null);
	const [statusIds, setStatusIds] = useState(null);
	const [countryId, setCountryId] = useState(null);
	const [postcode, setPostcode] = useState(null);
	const [town, setTown] = useState(null);
	const [regNumber, setRegNumber] = useState(null);
	const [domain, setDomain] = useState(null);
	const [websiteContent, setWebsiteContent] = useState(null);
	const [groupIds, setGroupIds] = useState(null);
	const [companyStatus, setCompanyStatus] = useState(null);
	const [personalEmail, setPersonalEmail] = useState(null);
	const [addressVerified, setAddressVerified] = useState(null);
	const [growth, setGrowth] = useState(null);
	const [categories, setCategories] = useState(null);
	const [withTagIds, setWithTagIds] = useState(null);
	const [withoutTagIds, setWithoutTagIds] = useState(null);
	const [withContactTagIds, setWithContactTagIds] = useState(null);
	const [withoutContactTagIds, setWithoutContactTagIds] = useState(null);
	const [conScoreMin, setConScoreMin] = useState(null);
	const [withoutDup, setWithoutDup] = useState(null);
	const [conCountMax, setConCountMax] = useState(null);
	const [aiData, setAiData] = useState(null);

	useEffect(() => {
		const callSetFilters = () => {
			setFilters({
				name: name, sizeMin: sizeMin, sizeMax: sizeMax, bestScoreMin: scoreMin, bestScoreMax: scoreMax,
				lastTouched: lastTouched, ageMin: ageMin ? new Date(new Date().getFullYear() - ageMin, new Date().getMonth(), 1) : null,
				bestIndustryIds: industries, importId: importId, leadStatusIds: statusIds, countryId: countryId, postcode: postcode, town: town,
				regNumber: regNumber, websiteContent: websiteContent, excludeGroups: groupIds, companyStatus: companyStatus,
				withPersonalEmail: personalEmail == "-1" || personalEmail == null ? null : personalEmail == "1",
				addressVerified: addressVerified == "-1" || addressVerified == null ? null : addressVerified == "1",
				growth: growth, categoryIds: categories, withTagIds: withTagIds, withoutTagIds: withoutTagIds, contactScoreMin: conScoreMin,
				withoutDuplicateSource: withoutDup, domain: domain, contactCountMax: conCountMax,
				withContactTagIds: withContactTagIds, withoutContactTagIds: withoutContactTagIds, aiData: aiData,
				rowCount: 10, sortColumn: "Name", sortDirection: "ASC"
			});
		};
		const timer = setTimeout(callSetFilters, filterTimeout);
		setFilterTimeout(1);
		return () => clearTimeout(timer);
	}, [name, sizeMin, sizeMax, scoreMin, scoreMax, ageMin, lastTouched, industries, importId, statusIds, countryId, postcode, town,
		regNumber, websiteContent, groupIds, companyStatus, personalEmail, addressVerified, growth, categories, withTagIds,
		withoutTagIds, withContactTagIds, withoutContactTagIds, conScoreMin, withoutDup, domain, conCountMax, aiData]);

	const updateFilter = (caption, setFunction, value, withDelay, data) => {
		setFilterTimeout(withDelay ? 800 : 1);
		setLastChangedFilter({ caption: caption, setFunction: setFunction, value: value, data: data == null || data === undefined ? value : data });
		setFunction(value);
	};

	const defaultCountryId = JSON.parse(localStorage.getItem("user")).countryId;
	const lastTouchedStatuses = [{ value: 1, label: dict.never }, { value: 2, label: `0-3 ${dict.months}` }, { value: 3, label: `3-6 ${dict.months}` }, { value: 4, label: `6-9 ${dict.months}` }, { value: 5, label: `9-12 ${dict.months}` }, { value: 6, label: `12+ ${dict.months}` }];
	const personalEmailOptions = [{ value: "1", label: dict.with }, { value: "0", label: dict.without }, { value: "-1", label: dict.either }];
	const addressVerifiedOptions = [{ value: "1", label: dict.with }, { value: "0", label: dict.without }, { value: "-1", label: dict.either }];
	const duplicateSources = [{ value: 1, label: "BigChange" }, { value: 3, label: "HubSpot" }];

	return (
		<>
			<Popover position="bottom" width={450}>
				<Popover.Target>
					<Button leftIcon={<Filter size={24} />} variant="outline">{dict.filters}</Button>
				</Popover.Target>
				<Popover.Dropdown>
					<ScrollArea style={{ height: 500 }}>
						<Accordion>
							<Accordion.Item value="basic">
								<AccordionControl icon={<InfoCircle />} label={dict.basicInfo} sub={`${dict.name}, ${dict.reg}, ${dict.category}, ${dict.size}, ${dict.domain}...`} />
								<Accordion.Panel>
									<Grid>
										<Grid.Col sm={6}>
											<TextInput onChange={e => updateFilter(dict.name, setName, e.currentTarget.value || null, true)} label={dict.name} value={name || ""} icon={<Typography size={18} />} />
										</Grid.Col>
										<Grid.Col sm={6}>
											{countryId == null
												? <>
													<Space h="md" /><Space h="md" />
													<Checkbox label={dict.activeOnly} checked={companyStatus == 1} onChange={e => updateFilter(dict.activeOnly, setCompanyStatus, e.currentTarget.checked ? 1 : null, false, e.currentTarget.checked ? dict.yes : null)} />
												</>
												: <Select data={[{ value: 1, label: dict.uk }, { value: 2, label: dict.france }]} onChange={setCountryId} label={dict.country} clearable icon={<Flag size={18} />} />
											}
										</Grid.Col>
										<Grid.Col sm={6}>
											<TextInput onChange={e => updateFilter(dict.reg, setRegNumber, e.currentTarget.value || null, true)} label={dict.reg} value={regNumber || ""} icon={<Registered size={18} />} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<TextInput onChange={e => updateFilter(dict.domain, setDomain, e.currentTarget.value || null, true)} label={dict.domain} value={domain || ""} icon={<WorldWww size={18} />} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<NumberInput onChange={val => updateFilter(dict.minSize, setSizeMin, val, true)} label={dict.minSize} value={sizeMin || ""} icon={<ArrowsMinimize size={18} />} min={0} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<NumberInput onChange={val => updateFilter(dict.maxSize, setSizeMax, val, true)} label={dict.maxSize} value={sizeMax || ""} icon={<ArrowsMaximize size={18} />} min={0} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<NumberInput onChange={val => updateFilter(dict.growth, setGrowth, val, true)} label={`${dict.growth} (%)`} value={growth || ""} icon={<ChartLine size={18} />} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<NumberInput onChange={val => updateFilter(dict.minAge, setAgeMin, val, true)} label={dict.minAge} value={ageMin || ""} placeholder={dict.year} icon={<Calendar size={18} />} min={0} />
										</Grid.Col>
										<Grid.Col sm={12}>
											<LazyDropDown onChange={(val, data) => updateFilter(dict.category, setCategories, val, false, data)} path={`category?countryId=${defaultCountryId}`} label={dict.category} value={categories?.map(x => x + "")} multiple icon={<Category2 size={18} />} />
										</Grid.Col>
									</Grid>
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value="location">
								<AccordionControl icon={<Map />} label={dict.location} sub={`${dict.postcode}, ${dict.town}...`} />
								<Accordion.Panel>
									<Grid mr={10}>
										<Grid.Col sm={6}>
											<TextInput onChange={e => updateFilter(dict.postcode, setPostcode, e.currentTarget.value || null, true)} label={dict.postcode} value={postcode || ""} icon={<MapPins size={18} />} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<TextInput onChange={e => updateFilter(dict.town, setTown, e.currentTarget.value || null, true)} label={dict.town} value={town || ""} icon={<MapPin size={18} />} />
										</Grid.Col>
										<Grid.Col sm={12}>
											<Text size="sm" weight={500}>{dict.addressVerified}</Text>
											<Space h={4} />
											<SegmentedControl data={addressVerifiedOptions} onChange={val => updateFilter(dict.addressVerified, setAddressVerified, val, false, val == "-1" ? "" : (val == "0" ? dict.without : dict.with))} value={addressVerified == null ? "-1" : addressVerified} />
										</Grid.Col>
									</Grid>
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value="diamond">
								<AccordionControl icon={<Diamond />} label={dict.diamondData} sub={`${dict.industry}, ${dict.leadScore}, ${dict.websiteContent}...`} />
								<Accordion.Panel>
									<Grid mr={10}>
										<Grid.Col sm={12}>
											<LazyDropDown onChange={(val, data) => updateFilter(dict.industry, setIndustries, val, false, data)} path="industry" label={dict.industry} value={industries?.map(x => x + "")} multiple icon={<BuildingFactory size={18} />} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<NumberInput onChange={val => updateFilter(dict.minScore, setScoreMin, val, true)} label={dict.minScore} value={scoreMin || ""} icon={<Star size={18} />} min={0} max={80} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<NumberInput onChange={val => updateFilter(dict.maxScore, setScoreMax, val, true)} label={dict.maxScore} value={scoreMax || ""} icon={<Star size={18} />} min={0} max={80} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<TextInput onChange={e => updateFilter(dict.websiteContent, setWebsiteContent, e.currentTarget.value || null, true)} label={dict.websiteContent} value={websiteContent || ""} icon={<ReportSearch size={18} />} />
										</Grid.Col>
									</Grid>
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value="aiData">
								<AccordionControl icon={<Bulb />} label={dict.aiData} sub={dict.aiDataSub} />
								<Accordion.Panel>
									<LeadFilterAiData dict={dict} updateFilter={updateFilter} aiData={aiData} setAiData={setAiData} />
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value="crm">
								<AccordionControl icon={<ClipboardList />} label={dict.crmData} sub={`${dict.status}, ${dict.tags}, ${dict.matches}, ${dict.group}, ${dict.import}...`} />
								<Accordion.Panel>
									<Grid mr={10}>
										<Grid.Col sm={12}>
											<LazyDropDown onChange={(val, data) => updateFilter(dict.status, setStatusIds, val, false, data)} path="status" label={dict.status} value={statusIds?.map(x => x + "")} multiple clearable={true} icon={<Checks size={18} />} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<LazyDropDown onChange={(val, data) => updateFilter(dict.withTag, setWithTagIds, val, false, data)} path="tag?entityType=1" label={dict.withTag} value={withTagIds?.map(x => x + "")} multiple clearable={true} icon={<Tag size={18} />} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<LazyDropDown onChange={(val, data) => updateFilter(dict.withoutTag, setWithoutTagIds, val, false, data)} path="tag?entityType=1" label={dict.withoutTag} value={withoutTagIds?.map(x => x + "")} multiple clearable={true} icon={<Tag size={18} />} />
										</Grid.Col>
										<Grid.Col sm={12}>
											<MultiSelect data={lastTouchedStatuses} icon={<PhoneCall size={18} />} label={dict.lastTouched} value={lastTouched || []}
												onChange={(val) => updateFilter(dict.lastTouched, setLastTouched, val, false, lastTouchedStatuses.filter(x => val.includes(x.value)))} />
										</Grid.Col>
										<Grid.Col sm={12}>
											<MultiSelect data={duplicateSources} icon={<Copy size={18} />} label={dict.withoutDup} value={withoutDup || []}
												onChange={(val) => updateFilter(dict.withoutDup, setWithoutDup, val, false, duplicateSources.filter(x => val.includes(x.value)))} />
										</Grid.Col>
										<Grid.Col sm={12}>
											<LazyDropDown onChange={(val, data) => updateFilter(dict.excludeGroup, setGroupIds, val, false, data)} path="leadDuplicate/groups" label={dict.excludeGroup} value={groupIds?.map(x => x + "")} multiple clearable={true} icon={<Files size={18} />} />
										</Grid.Col>
										<Grid.Col sm={12}>
											<LazyDropDown onChange={(val, data) => updateFilter(dict.import, setImportId, val, false, data)} path="import?importType=1" label={dict.import} value={importId ? importId + "" : null} clearable={true} icon={<FileImport size={18} />} />
										</Grid.Col>
									</Grid>
								</Accordion.Panel>
							</Accordion.Item>
							<Accordion.Item value="contact">
								<AccordionControl icon={<AddressBook />} label={dict.contacts} sub={`${dict.contactScore}, ${dict.email}, ${dict.tags}...`} />
								<Accordion.Panel>
									<Grid mr={10}>
										<Grid.Col sm={12}>
											<NumberInput onChange={val => updateFilter(dict.conCountMax, setConCountMax, val, true)} value={conCountMax || ""} icon={<ListNumbers size={18} />} min={1} max={10}
												label={<Group position="apart">
													<Text>{dict.conCountMax}</Text>
													<Tooltip label={dict.conCountHelp} multiline withArrow>
														<span><AlertCircle size={20} /></span>
													</Tooltip>
												</Group>}
											/>
										</Grid.Col>
										<Grid.Col sm={6}>
											<NumberInput onChange={val => updateFilter(dict.conScoreMin, setConScoreMin, val, true)} label={dict.conScoreMin} value={conScoreMin || ""} placeholder={dict.minScore} icon={<AddressBook size={18} />} min={0} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<Text size="sm" weight={500}>{dict.personalEmail}</Text>
											<Space h={4} />
											<SegmentedControl data={personalEmailOptions} onChange={val => updateFilter(dict.personalEmail, setPersonalEmail, val, false, val == "-1" ? "" : (val == "0" ? dict.without : dict.with))} value={personalEmail == null ? "-1" : personalEmail} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<LazyDropDown onChange={(val, data) => updateFilter(dict.withTag, setWithContactTagIds, val, false, data)} path="tag?entityType=2" label={dict.withTag} value={withContactTagIds?.map(x => x + "")} multiple clearable={true} dropdownPosition="top" icon={<Tag size={18} />} />
										</Grid.Col>
										<Grid.Col sm={6}>
											<LazyDropDown onChange={(val, data) => updateFilter(dict.withoutTag, setWithoutContactTagIds, val, false, data)} path="tag?entityType=2" label={dict.withoutTag} value={withoutContactTagIds?.map(x => x + "")} multiple clearable={true} dropdownPosition="top" icon={<Tag size={18} />} />
										</Grid.Col>
									</Grid>
								</Accordion.Panel>
							</Accordion.Item>
						</Accordion>
					</ScrollArea>
				</Popover.Dropdown>
			</Popover>
			<LeadsActiveFilters lastChangedFilter={lastChangedFilter} />
		</>
	);
}