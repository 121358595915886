export function formatDate(dt, nullText) {
	if (!dt) return nullText;
	const d = dt.split("T")[0].split("-");
	return `${d[2]}/${d[1]}/${d[0]}`;
}

export function nullIfEmpty(s) {
	return s === "" ? null : s;
}
export function isEmail(email) {
	return email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
}
export function isPhone(phone) {
	return phone.match(/^\+?\d+$/);
}
export function isUrl(url) {
	const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
	return url.match(new RegExp(expression));
}
export function toTitleCase(str) {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}
function buildFormData(formData, obj, parentKey) {
	if (obj && typeof obj === "object" && !(obj instanceof Date) && !(obj instanceof File)) {
		Object.keys(obj).forEach(key => {
			buildFormData(formData, obj[key], parentKey ? `${parentKey}[${key}]` : key);
		});
	} else if (obj !== undefined && obj !== null) 
		formData.append(parentKey, obj);
}
export function objectToFormData(obj) {
	let formData = new FormData();
	buildFormData(formData, obj);
	return formData;
}
export function createUid() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	);
}

export function addSpaceBeforeUpperCase(s) {
	if (s == null) return null;
	let result = "";
	for (let i = 0; i < s.length; i++) {
		if (i > 0 && s[i] === s[i].toUpperCase()) result += " ";
		result += s[i];
	}
	return result;			
}