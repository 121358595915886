import React, { useEffect, useState } from "react";
import { Modal, Stack, TextInput, NumberInput, Group, Button, SegmentedControl } from "@mantine/core";
import { Trash, Check, X } from "tabler-icons-react";


export default function ContactPositionNewScore({ dict, position, setPosition, saveScoring }) {
	const [previousWord, setPreviousWord] = useState("");
	const [word, setWord] = useState("");
	const [score, setScore] = useState(0);
	const [matchType, setMatchType] = useState("exact");
	const [isNew, setIsNew] = useState(true);

	useEffect(() => {
		const type = position?.word?.indexOf("%") == 0 ? "contains" : "exact";
		setPreviousWord(position?.word);
		setWord(type == "contains" ? position?.word.replace(/%/g, "") : position?.word || "");
		setScore(position?.score || 0);
		setMatchType(type);
		setIsNew(position?.score == undefined);
	}, [position]);

	const saveScore = () => {
		saveScoring({ word: matchType == "exact" ? word : `%${word}%`, score: score });
		setPosition(null);
	};
	const deleteScore = () => {
		saveScoring({ word: previousWord, score: 0 });
		setPosition(null);
	};

	return (
		<Modal opened={position != null} onClose={() => setPosition(null)} centered title={dict.addPoint}>
			<Stack>
				<TextInput value={word} onChange={e => setWord(e.currentTarget.value)} label={dict.position} required />
				<NumberInput value={score} onChange={setScore} label={dict.points} required />
				<SegmentedControl value={matchType} onChange={setMatchType} data={[
					{ label: dict.matchExact, value: "exact" },
					{ label: dict.matchContains, value: "contains" },
				]} />
				<Group position="center" mt="sm">
					{isNew
						? <Button leftIcon={<X size={20} />} variant="outline" onClick={() => setPosition(null)}>{dict.cancel}</Button>
						: <Button leftIcon={<Trash size={20} />} color="red" variant="outline" onClick={deleteScore}>{dict.delete}</Button>
					}
					<Button leftIcon={<Check size={20} />} onClick={saveScore}>{dict.addPoint}</Button>
				</Group>
			</Stack>
		</Modal>
	);
}