import React, { useState, useEffect } from "react";
import { Badge, Button, Group, Text, Select, Loader } from "@mantine/core";
import { Plus, X } from "tabler-icons-react";
import { fetchUrl, getUrl } from "utils/urlHelpers";
import { objectToFormData } from "utils/helpers";
import { useDictionary } from "i18n/dictionary";

export default function Tags({ entityType, entityName, entityId, entityTags }) {
	const dict = useDictionary("tag");
	const [shownTags, setShownTags] = useState(entityTags);
	const [tags, setTags] = useState(null);
	const [tagPickerVisible, setTagPickerVisible] = useState(false);
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		if (tagPickerVisible && tags == null)
			getUrl(`tag?entityType=${entityType}`, data =>
				setTags(data.map(x => ({ value: x.id + "", label: x.name })))
			);
	}, [tagPickerVisible]);

	const createTag = name => {
		fetchUrl("tag", "POST", objectToFormData({ entityType: entityType, name: name }), saving, null, tag => {
			setTags([...tags, { value: tag.id + "", label: tag.name }]);
			addTag(tag.id, tag.name);
		});
	};
	const addTag = (tagId, tagName) => {
		let data = { tagId: +tagId };
		data[`${entityName}Id`] = entityId;
		fetchUrl(`${entityName}Tag`, "POST", objectToFormData(data), setSaving, dict.tagAdded, tag => {
			tag.name = tagName || tags.find(x => +x.value == tag.tagId).label;
			setShownTags([...shownTags, tag]);
			setTagPickerVisible(false);
		});
	};
	const deleteTag = id => {
		setDeleting(id);
		fetchUrl(`${entityName}Tag/${id}`, "DELETE", null, null, dict.tagDeleted, () => {
			setShownTags(shownTags.filter(x => { return x.id !== id; }));
			setDeleting(null);
		});
	};

	let badges = shownTags.map(tag =>
		<Badge key={tag.id} size="lg">
			<Group spacing="xs">
				<Text size="xs" weight={500}>{tag.name}</Text>
				{deleting == tag.id
					? <Loader size="xs" />
					: <X size={16} style={{ cursor: "pointer" }} onClick={() => deleteTag(tag.id)} />
				}
			</Group>
		</Badge>
	);

	return (
		<>
			{badges}
			{!tagPickerVisible &&
				<Button rightIcon={<Plus size={12} />} variant="outline" radius="xl" compact onClick={() => setTagPickerVisible(true)}>
					<Text size="xs" weight={500}>{dict.addTag}</Text>
				</Button>
			}
			{tagPickerVisible &&
				<Select data={tags || []} onChange={addTag} radius="xl" placeholder={dict.select} 
					searchable creatable onCreate={createTag} getCreateLabel={lbl => `+ ${dict.add} "${lbl}"`}
					rightSection={saving && <Loader size="xs" />} />
			}
		</>
	);
}