import React, { useState, useEffect } from "react";
import { createStyles, Anchor, Tooltip } from "@mantine/core";
import { Registered, BrandLinkedin, BrandTwitter, BrandFacebook, MapPin, QuestionMark, Copyright, LetterK, LetterV, LetterP } from "tabler-icons-react";
import LeadSection from "./LeadSection";

const useStyles = createStyles(theme => ({
	link: { color: theme.colorScheme === "dark" ? theme.colors.gray[5] : theme.colors.dark[7], fontSize: theme.fontSizes.sm },
	icon: { verticalAlign: "middle", marginRight: theme.spacing.xs },
}));

export default function Matches(p) {
	const { classes } = useStyles();
	const [matches, setMatches] = useState(null);

	useEffect(() => {
		if (p.company?.matches && !p.company?.matches.find(e => e.sourceType == 1) && p.company?.countryId == 1) p.company?.matches.push({ sourceType: 1 });
		if (p.company?.matches && !p.company?.matches.find(e => e.sourceType == 6)) p.company?.matches.push({ sourceType: 6 });
		if (p.company?.matches && !p.company?.matches.find(e => e.sourceType == 10) && p.company?.countryId == 2) p.company?.matches.push({ sourceType: 10 });
		if (p.company?.matches && !p.company?.matches.find(e => e.sourceType == 15) && p.company?.countryId == 2) p.company?.matches.push({ sourceType: 15 });
		setMatches(p.company?.matches.sort((a, b) => a.sourceType - b.sourceType));
	}, [p]);

	const getMatchIcon = type => {
		const labels = {
			1: "Companies House", 2: "Gouv.fr", 5: "Google Map", 6: "LinkedIn",
			8: "Kompass", 10: "Vérif", 12: "Facebook", 13: "Twitter", 14: "Check a Trade", 15: "Pappers"
		};
		const icons = {
			1: Registered, 2: Registered, 5: MapPin, 6: BrandLinkedin,
			8: LetterK, 10: LetterV, 12: BrandFacebook, 13: BrandTwitter, 14: Copyright, 15: LetterP
		};
		return <Tooltip label={labels[type]}>
			<span>{React.createElement(icons[type] ?? QuestionMark, { className: classes.icon })}</span>
		</Tooltip>;
	};
	const getMatchUrl = match => {
		switch (match.sourceType) {
			case 1: return match.url || `https://find-and-update.company-information.service.gov.uk/search?q=${encodeURIComponent(p.company?.name)}`;
			case 2: return p.company?.regNumber
				? `https://annuaire-entreprises.data.gouv.fr/entreprise/${encodeURIComponent(p.company.regNumber)}`
				: `https://annuaire-entreprises.data.gouv.fr/rechercher?terme=${encodeURIComponent(p.company?.name)}`;
			case 5: return match.url || `https://www.google.com/maps/place/?q=place_id:${encodeURIComponent(match.sourceId)}`;
			case 6: return match.sourceId
				? `https://www.linkedin.com/company/${match.sourceId}`
				: `https://www.linkedin.com/search/results/companies/?companyHqGeo=${p.company?.countryId == 1 ? "101165590" : "105015875"}&keywords=${encodeURIComponent(p.company?.name)}`;
			case 10: return p.company?.regNumber
				? `https://www.verif.com/societe/${p.company?.regNumber}/`
				: `https://www.verif.com/recherche/?search=v&i_raison_sociale=${encodeURIComponent(p.company?.name)}/1/ca/d`;
			case 12: return `https://www.facebook.com/${match.sourceId}`;
			case 13: return `https://twitter.com/${match.sourceId}`;
			case 14: return `https://www.checkatrade.com/trades/${match.sourceId}`;
			case 15: return p.company?.regNumber
			? `https://www.pappers.fr/entreprise/${p.company?.regNumber}/`
			: `https://www.pappers.fr/recherche?q=${encodeURIComponent(p.company?.name)}`;
			default: return match.url;
		}
	};
	const matchLink = match => {
		return (
			<span key={match.sourceType}>
				<Anchor href={getMatchUrl(match)} target="_blank" className={classes.link}>
					{getMatchIcon(match.sourceType)}{match.name}
				</Anchor>
				{match.sourceId && (match.sourceType == 1 || match.sourceType == 2 || match.sourceType == 5) && <br />}
			</span>
		);
	};

	return (
		<LeadSection label={p.label}>{matches?.map(x => matchLink(x))}</LeadSection>
	);
}