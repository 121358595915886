import React from "react";
import { createStyles, Table, Skeleton, Text, Tooltip, Anchor, Center } from "@mantine/core";
import { DeviceDesktopAnalytics, Bulb } from "tabler-icons-react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import LeadsTableSize from "pages/Leads/LeadsTableSize";
import LeadsTableScore from "pages/Leads/LeadsTableScore";
dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = createStyles(theme => ({
	rowUnverifiable: { cursor: "pointer", color: theme.colors.red[7] },
	rowUnverified: { cursor: "pointer", fontWeight: 700 },
	rowBadFit: { cursor: "pointer", color: theme.colors.orange[7] },
	row: { cursor: "pointer" },
	webIcon: { marginLeft: theme.spacing.md, color: theme.colorScheme === "dark" ? theme.colors.gray[5] : theme.colors.dark[4] },
	statusUser: { color: theme.colors.gray[6] }
}));

export default function RoadLeadsTable({ dict, roadLeads, openRoadLead }) {
	const { classes } = useStyles();

	function getRowClass(lead) {
		return lead.status <= 2 && !lead.leadId ? classes.rowUnverified
			: (lead.status == 4 ? classes.rowBadFit
				: (lead.status == 10 ? classes.rowUnverifiable : classes.row));
	}
	const timeZone = JSON.parse(localStorage.getItem("user")).tz;

	const placeHolderRow = Array.from({ length: 5 }, (v, i) => <td key={i}><Skeleton height={42} /></td>);
	const placeHolder = Array.from({ length: 10 }, (v, i) => <tr key={i}>{placeHolderRow}</tr>);

	const rows = roadLeads?.map(lead => (
		<tr key={lead.id} className={getRowClass(lead)} onClick={e => openRoadLead(e, lead)}>
			<td><Text transform="capitalize">{lead.email.split("@")[0].replace(".", " ")}</Text></td>
			<td>{dayjs(lead.dateInserted + "Z").tz(timeZone).format("DD/MM/YYYY HH:mm")}</td>
			<td>{dayjs(lead.dateSpotted).format("DD/MM/YYYY HH:mm")}</td>{/* dateSpotted is stored in local time (date inserted in UTC) */}
			<td>{lead.name}</td>
			<td>{lead.town}</td>
			<td>{lead.isNew && <Bulb />}</td>
			<td><LeadsTableSize lead={lead} /></td>
			<td><LeadsTableScore lead={lead} /></td>
			<td>{lead.bestIndustry}</td>
			<td>
				{lead.leadStatusName}<br />
				<Text className={classes.statusUser}>{lead.leadStatusUserName}</Text>
			</td>
			<td><Tooltip label={dict.website} openDelay={500}><Anchor href={lead.website} className={classes.webIcon} target="_blank"><DeviceDesktopAnalytics /></Anchor></Tooltip></td>
		</tr>
	));

	return (
		<Table highlightOnHover>
			<thead>
				<tr>
					<th>{dict.from}</th>
					<th>{dict.dateSent}</th>
					<th>{dict.dateSpotted}</th>
					<th>{dict.name}</th>
					<th>{dict.spottedIn}</th>
					<th><Center>{dict.newLead}</Center></th>
					<th><Center>{dict.size}</Center></th>
					<th><Center>{dict.bestScore}</Center></th>
					<th>{dict.bestIndustry}</th>
					<th>{dict.status}</th>
					<th>{dict.website}</th>
				</tr>
			</thead>
			<tbody>{roadLeads === null ? placeHolder : rows}</tbody>
		</Table>
	);
}