import React from "react";
import { Text, TextInput } from "@mantine/core";
import { useDictionary } from "i18n/dictionary";
import LazyDropDown from "components/LazyDropDown";
import { formatDate } from "utils/helpers";
import LeadSection from "./LeadSection";

export default function LeadDetail({ label, lead }) {
	const dict = useDictionary("leadDetails");

	const getSourceText = (sourceType, source) => {
		switch (sourceType) {
			case 1: return `${dict.importFrom} ${formatDate(source.dateInserted)}`;
			case 2: return `${dict.manualEntry}`;
			default: return dict.unknown;
		}
	};

	const saveStatus = id => {
		console.log(id);
	};
	const currentStatus = { value: lead?.currentStatusId + "", label: lead?.currentStatus };
	const content = lead == null ? null :
		<>
			<TextInput label={dict.source} defaultValue={getSourceText(lead.sourceType, lead.source)} disabled />
			<LazyDropDown path="status" label={dict.status} data={[currentStatus]} initialData={[currentStatus]} defaultValue={(lead?.currentStatusId || "") + ""} onChange={val => saveStatus(val)} />
		</>;

	return (
		<LeadSection label={label} maxHeight={160}>
			{lead != null && lead.id == null
				? <Text color="red">{dict.noLead}</Text>
				: content
			}
		</LeadSection>
	);
}