import { Avatar, Burger, Group, Header, MediaQuery, Menu, Text, useMantineTheme } from "@mantine/core";
import { useDictionary } from "i18n/dictionary";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Logout, User } from "tabler-icons-react";

export default function AppHeader({ navBarOpened, setNavBarOpened, logout }) {
	const theme = useMantineTheme();
	const dict = useDictionary("menu");
	const navigate = useNavigate();

	const user = JSON.parse(localStorage.getItem("user"));
	const avatar = <Avatar radius="xl" color={theme.white}
		styles={{
			placeholder: {
				backgroundColor: theme.colors.blue[7],
				color: theme.white,
				cursor: "pointer"
			}
		}}>
		{user.firstName[0] + "." + user.lastName[0]}
	</Avatar>;
	return (
		<Header height={70} p="md"
			sx={{ borderBottomColor: theme.colorScheme === "dark" ? theme.colors.gray[7] : theme.colors.gray[4] }}>
			<Group position="apart">
				<div style={{ display: "flex", alignItems: "center", height: "100%" }}>
					<MediaQuery largerThan="sm" styles={{ display: "none" }}>
						<Burger
							opened={navBarOpened} onClick={() => setNavBarOpened((o) => !o)}
							size="sm" mr="xl" color={theme.colors.gray[6]}
						/>
					</MediaQuery>
					<img src="/logo192.png" width="32" alt="Logo" />&nbsp;&nbsp;
					<Text size="lg" weight={700} sx={{ letterSpacing: "-1.3px" }}
						color={theme.colors.blue[7]}>
						BigChange - Diamond Leads
					</Text>
				</div>
				<Menu shadow="md" width={200} trigger="hover" openDelay={100} closeDelay={400} withArrow transition="scale">
					<Menu.Target>{avatar}</Menu.Target>
					<Menu.Dropdown>
						<Menu.Label>
							{user.firstName} {user.lastName}
							<br />
							<b>{user.customerName}</b>
						</Menu.Label>
						<Menu.Item icon={<User size={18} />} onClick={() => navigate("/settings")}>{dict.mySettings}</Menu.Item>
						<Menu.Item icon={<Logout size={18} />} onClick={logout}>{dict.logout}</Menu.Item>
					</Menu.Dropdown>
				</Menu>
			</Group>
		</Header>);
}