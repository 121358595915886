import React, { useEffect, useState } from "react";
import { Group, Stack, Text } from "@mantine/core";
import TableSearch from "./SalesLeadTable/SalesLeadTable";
import { NotificationsProvider } from "@mantine/notifications";
import RoadLeadLogin from "pages/RoadLead/RoadLeadLogin";

export default function SalesLead() {
	const [token, setToken] = useState(null);
	const [tokenCheckTimer, setTokenCheckTimer] = useState(null);

	useEffect(() => {
		checkSessionValid();
		replaceManifest();	// removes Diamond Lead site manifest and add the SalesLead one (so adding shortcut on smartphones redirects to correct page)
	}, []);

	useEffect(() => {
		if (tokenCheckTimer) {
			clearInterval(tokenCheckTimer);
			setTokenCheckTimer(null);
		}
		if (token)
			setTokenCheckTimer(setInterval(checkSessionValid, 5000));
	}, [token]);

	const checkSessionValid = () => {
		const sessionDetails = JSON.parse(sessionStorage.getItem("roadLeadLogged") || "{}");
		if (sessionDetails.expire && new Date(sessionDetails.expire) > new Date()) {
			setToken(sessionDetails.token);
		} else
			setToken(undefined);
	};

	const replaceManifest = () => {
		document.head.querySelectorAll("link[rel='manifest']").forEach(function (e) {
			e.parentElement.removeChild(e);
		});
		let link = document.createElement("link");
		link.rel = "manifest";
		document.head.appendChild(link);
		link.href = `${process.env.PUBLIC_URL}/manifestSales.json`;
	};

	return (
		<NotificationsProvider>
			<Group position="center">
				<Stack sx={{ width: 450, padding: 10 }} >
					<Group position="center">
						<img src="/logo192.png" width="32" alt="Logo" />
						<Text size="lg">BigChange - Diamond Sales Leads</Text>
					</Group>
					{token === undefined && <RoadLeadLogin setToken={setToken} />}
					{token?.length > 0 && <TableSearch />}
				</Stack>
			</Group>
		</NotificationsProvider>
	);
}