import React, { useState, useEffect } from "react";
import { Accordion, TextInput, Group, Button } from "@mantine/core";
import { ArrowBigRight } from "tabler-icons-react";
import { objectToFormData, isUrl } from "utils/helpers";
import { fetchUrl } from "utils/urlHelpers";

export default function RoadLeadFormDetails({ form, setStep, setStep2Result }) {
	const [checkingStep2, setCheckingStep2] = useState(false);
	const [step2Status, setStep2Status] = useState();
	const [websiteError, setWebsiteError] = useState();

	useEffect(() => {
		let name = form.values.name.trim();
		let website = form.values.website.trim().toLowerCase();
		form.values.name = name;
		form.values.website = (isUrl(website) && website.indexOf("http") != 0 ? "https://" : "") + website;
		if (website.indexOf("facebook") != -1 || website.indexOf("linkedin") != -1) {
			setWebsiteError("Facebook and LinkedIn can't be used");
			setStep2Status(null);
		}
		else {
			setStep2Status(form.values.name.length > 2 && isUrl(form.values.website) ? "toCheck" : null);
			setWebsiteError(null);
		}
	}, [form.values.name, form.values.website]);

	const checkStep2 = () => {
		if (step2Status === "toCheck") {
			const data = objectToFormData({ name: form.values.name, website: form.values.website });
			fetchUrl("roadLead/verif", "POST", data, setCheckingStep2, null, step2Checked);
		} else
			setStep("3");
	};
	const step2Checked = data => {
		setStep2Result(data);
		setStep2Status("checked");
		form.setValues({ regNumber: data.regNumber });
		setStep("3");
	};
	return (
		<Accordion.Item value="2">
			<Accordion.Control>2. Details</Accordion.Control>
			<Accordion.Panel>
				<TextInput {...form.getInputProps("name")} label="Company Name" withAsterisk maxLength={100} />
				<TextInput {...form.getInputProps("website")} label="Website" withAsterisk type="url" maxLength={2048} error={websiteError} placeholder="Should start with https://"  />
				<Group position="right" mt="sm">
					<Button leftIcon={<ArrowBigRight size={20} />} onClick={() => checkStep2()} disabled={step2Status === null} loading={checkingStep2}>Next</Button>
				</Group>
			</Accordion.Panel>
		</Accordion.Item>
	);
}