import React, { useEffect, useState } from "react";
import { Group, TextInput, PasswordInput, Button, Anchor } from "@mantine/core";
import { Check } from "tabler-icons-react";
import { fetchUrl } from "utils/urlHelpers";
import { objectToFormData } from "utils/helpers";

export default function RoadLeadLogin({ setToken }) {
	const [company, setCompany] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [logging, setLogging] = useState("");

	useEffect(() => {
		const details = JSON.parse(localStorage.getItem("roadLeadEmail") || "{}");
		if (new Date(details.expire) > new Date()) {
			setCompany(details.company || "");
			setEmail(details.email || "");
		}
	}, []);

	const login = () => {
		fetchUrl(`user/bigchange/login/${company}`, "POST", objectToFormData({ email: email, password: password }), setLogging, null, loginOk, "Wrong credentials");
	};
	const loginOk = (data) => {
		const token = data.token;
		const savedDetails = { company: company, email: email, expire: new Date((new Date()).getTime() + 10080 * 60000) }; // 1 week
		const sessionDetails = { token: token, expire: new Date((new Date()).getTime() + 60 * 60000) }; // 1 hour
		localStorage.setItem("roadLeadEmail", JSON.stringify(savedDetails));
		sessionStorage.setItem("roadLeadLogged", JSON.stringify(sessionDetails));
		setToken(token);
	};

	return (
		<>
			<TextInput value={company} onChange={(event) => setCompany(event.currentTarget.value)} label="Company" required />
			<TextInput value={email} onChange={(event) => setEmail(event.currentTarget.value)} label="Email" required />
			<PasswordInput value={password} onChange={(event) => setPassword(event.currentTarget.value)} label="Password" required />
			<Group position="apart" mt="sm">
				<Anchor href="https://clients.bigchange.com/LoginRetrieve.aspx">Retrieve your BigChange password</Anchor>
				<Button leftIcon={<Check size={20} />} onClick={login} loading={logging}>Login</Button>
			</Group>
		</>
	);
}