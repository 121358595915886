import React, { useEffect, useState } from "react";
import { Tabs, Space } from "@mantine/core";
import { Settings, FileDescription } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import { getUrl } from "utils/urlHelpers";
import UserDetails from "./UserDetails";
import UserConfig from "./UserConfig";

export default function User(p) {
	const dict = useDictionary("user");
	const [activeTab, setActiveTab] = useState("details");
	const [user, setUser] = useState(null);

	useEffect(() => {
		if (p.user?.id)
			getUrl(`user/${p.user.id}`, setUser);
	}, [p.user?.id]);

	return (
		<Tabs value={activeTab} onTabChange={setActiveTab}>
			<Tabs.List>
				<Tabs.Tab value="details" icon={<FileDescription size={16} />}>{dict.details}</Tabs.Tab>
				{p.user?.id && <Tabs.Tab value="config" icon={<Settings size={16} />}>{dict.config}</Tabs.Tab>}
			</Tabs.List>
			<Tabs.Panel value="details">
				<Space h="lg" />
				<UserDetails dict={dict} user={user || { ...p.user, roles: [] }} setSavedUser={p.setSavedUser} setDeletedUser={p.setDeletedUser} />
			</Tabs.Panel>
			<Tabs.Panel value="config">
				<Space h="lg" />
				<UserConfig dict={dict} user={user || { ...p.user, roles: [] }} visible={activeTab == "config"} setUserOpened={p.setUserOpened} />
			</Tabs.Panel>
		</Tabs>
	);
}