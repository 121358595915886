import { Button, Group, NumberInput, Stack, Text } from "@mantine/core";
import ContentBox from "components/ContentBox";
import React, { useState } from "react";
import ContactPositionWords from "./ContactPositionWords";
import { Copy, DeviceFloppy, Trash } from "tabler-icons-react";
import Confirm from "components/Confirm";

export default function ContactPositionDetail({ dict, scoring, saveScoring, saving, deleteScoring, deleting, duplicateScoring }) {
	const scoringDef = JSON.parse(scoring.definition);
	const [sizeMin, setSizeMin] = useState(scoring.companySizeMin);
	const [sizeMax, setSizeMax] = useState(scoring.companySizeMax);
	const [scoredPositions, setScoredPositions] = useState(scoringDef.find(x => x.type == 3)?.words || []);
	const [email1Score, setEmail1Score] = useState(scoringDef.find(x => x.type == 1)?.score1 || 0);
	const [email2Score, setEmail2Score] = useState(scoringDef.find(x => x.type == 1)?.score2 || 0);
	const [phone1Score, setPhone1Score] = useState(scoringDef.find(x => x.type == 2)?.score1 || 0);
	const [phone2Score, setPhone2Score] = useState(scoringDef.find(x => x.type == 2)?.score2 || 0);
	const [deleteConfOpened, setDeleteConfOpened] = useState(null);

	const updateScoring = () => {
		let definition = [];
		if (email1Score || email2Score) definition.push({ type: 1, score1: email1Score, score2: email2Score });
		if (phone1Score || phone2Score) definition.push({ type: 2, score1: phone1Score, score2: phone2Score });
		if (scoredPositions.length) definition.push({ type: 3, words: scoredPositions });
		saveScoring({ ...scoring, definition: JSON.stringify(definition), companySizeMin: sizeMin, companySizeMax: sizeMax });
	};

	return (
		<Stack align="center">
			<div>
				<Text weight={500} size="md" align="center">{dict.sizeRange}</Text>
				<Text size="xs" align="center">{dict.sizeRangeDesc}</Text>
				<ContentBox>
					<Group>
						<NumberInput label={dict.sizeMin} value={sizeMin} onChange={setSizeMin} />
						<NumberInput label={dict.sizeMax} value={sizeMax} onChange={setSizeMax} />
					</Group>
				</ContentBox>
			</div>
			<Group spacing="xl">
				<div>
					<Text weight={500} size="md" align="center">{dict.emailScoring}</Text>
					<ContentBox>
						<Stack spacing={5} mr={50}>
							<NumberInput label={dict.scoreEmail1} value={email1Score} onChange={setEmail1Score} />
							<NumberInput label={dict.scoreEmail2} value={email2Score} onChange={setEmail2Score} />
						</Stack>
					</ContentBox>
				</div>
				<div>
					<Text weight={500} size="md" align="center">{dict.phoneScoring}</Text>
					<ContentBox>
						<Stack spacing={5} ml={50}>
							<NumberInput label={dict.scorePhone1} value={phone1Score} onChange={setPhone1Score} />
							<NumberInput label={dict.scorePhone2} value={phone2Score} onChange={setPhone2Score} />
						</Stack>
					</ContentBox>
				</div>
			</Group>
			<div>
				<Text weight={500} size="md" align="center">{dict.positionScoring}</Text>
				<ContentBox>
					<ContactPositionWords dict={dict} scoredPositions={scoredPositions} setScoredPositions={setScoredPositions} />
				</ContentBox>
			</div>
			<Group position="center" mt="sm">
				<Button leftIcon={<Trash size={20} />} variant="outline" color="red" onClick={() => setDeleteConfOpened(true)} loading={deleting} >{dict.delete}</Button>
				<Button leftIcon={<Copy size={20} />} variant="outline" onClick={duplicateScoring}>{dict.duplicate}</Button>
				<Button leftIcon={<DeviceFloppy size={20} />} onClick={updateScoring} loading={saving} >{dict.saveScoring}</Button>
			</Group>

			<Confirm opened={deleteConfOpened} setOpened={setDeleteConfOpened} yesLabel={dict.delete} yesAction={deleteScoring}>{dict.deleteScoringConf}</Confirm>
		</Stack>
	);
}