import React, { useState, useEffect } from "react";
import { TextInput, Grid, Container, Switch, Space } from "@mantine/core";
import { AB, Hierarchy, Mail, Phone } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";

export default function ContactsFilter(p) {
	const dict = useDictionary("contacts");
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [position, setPosition] = useState(null);
	const [phone, setPhone] = useState(null);
	const [email, setEmail] = useState(null);
	const [withEmail, setWithEmail] = useState(null);
	const [withPhone, setWithPhone] = useState(null);

	useEffect(() => {
		const callUpdate = () => {
			p.update({
				firstName: firstName || null, lastName: lastName || null, position: position || null, phone: phone || null, email: email || null,
				withEmail: withEmail, withPhone: withPhone,
				rowCount: 10, sortColumn: "LastName", sortDirection: "ASC"
			});
		};
		const timer = setTimeout(callUpdate, 300);
		return () => clearTimeout(timer);
	}, [firstName, lastName, position, phone, email, withEmail, withPhone]);

	return (
		<Container fluid mb="sm">
			<Grid>
				<Grid.Col sm={4} md={1.9}>
					<TextInput onChange={(e) => setFirstName(e.currentTarget.value)} label={dict.firstName} icon={<AB size={18} />} />
				</Grid.Col>
				<Grid.Col sm={4} md={1.9}>
					<TextInput onChange={(e) => setLastName(e.currentTarget.value)} label={dict.lastName} icon={<AB size={18} />} />
				</Grid.Col>
				<Grid.Col sm={4} md={1.9}>
					<TextInput onChange={(e) => setPosition(e.currentTarget.value)} label={dict.position} icon={<Hierarchy size={18} />} />
				</Grid.Col>
				<Grid.Col sm={4} md={1.9}>
					<TextInput onChange={(e) => setPhone(e.currentTarget.value)} label={dict.phone} icon={<Phone size={18} />} />
				</Grid.Col>
				<Grid.Col sm={4} md={1.9}>
					<TextInput onChange={(e) => setEmail(e.currentTarget.value)} label={dict.email} icon={<Mail size={18} />} />
				</Grid.Col>
				<Grid.Col sm={4} md={1.25} style={{ position: "relative" }}>
					<Space h="md" /><Space h="md" />
					<Switch onChange={(e) => setWithEmail(e.currentTarget.checked)} label={dict.withEmail} />
				</Grid.Col>
				<Grid.Col sm={4} md={1.25} style={{ position: "relative" }}>
					<Space h="md" /><Space h="md" />
					<Switch onChange={(e) => setWithPhone(e.currentTarget.checked)} label={dict.withPhone} />
				</Grid.Col>
			</Grid>
		</Container>
	);
}