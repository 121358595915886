import React, { useState, useEffect } from "react";
import { Tabs, Textarea, Grid, TransferList, TextInput, Group, Switch, Space, MultiSelect, Modal } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DeviceFloppy, PlayCard, Settings, Calculator, Trash } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import { objectToFormData, nullIfEmpty, isUrl } from "utils/helpers";
import { fetchUrl, getUrl } from "utils/urlHelpers";
import Confirm from "components/Confirm";
import BattleCards from "../BattleCards";
import SplitButton from "components/SplitButton";
import { shallowEqual } from "@mantine/hooks";

export default function Industry({ industryOpened, setIndustryOpened, setSavedIndustry, setDeletedIndustry }) {
	const dict = useDictionary("industries");
	const [industry, setIndustry] = useState(null);
	const [saving, setSaving] = useState(null);
	const [deleting, setDeleting] = useState(null);
	const [isLive, setIsLive] = useState(false);
	const [recalcConfOpened, setRecalcConfOpened] = useState(false);
	const [deleteConfOpened, setDeleteConfOpened] = useState(false);
	const [initialSites, setInitialSites] = useState(null);

	const distinct = (value, index, self) => { return self.indexOf(value) === index; };

	const refreshIndustry = data => {
		form.setValues({
			id: industryOpened.id, countryId: data.countryId, status: data.status, isGeneric: data.isGeneric,
			name: data.name, alternateNames: data.alternateNames || [],
			baseSites: data.baseSites?.join("\n"),
			positiveWords: data.positiveWords?.join("\n"),
			negativeWords: data.negativeWords?.join("\n"),
			words: [
				data.scrapedWords
					?.filter(word => { return !data.positiveWords?.includes(word); })
					.map(word => ({ value: word, label: word })) || [],
				data.stopWords
					?.filter(distinct)
					?.map(word => ({ value: word, label: word })) || []
			]
		});
		setIsLive(data?.status == 10);
		setIndustry(data);
		setInitialSites(data.baseSites);
	};
	useEffect(() => {
		if (!industryOpened) return;
		form.setValues({ name: industryOpened.name, status: industryOpened.status, words: [[], []], alternateNames: [], baseSites: "", positiveWords: "", negativeWords: "" });
		getUrl(`industry/${industryOpened.id}`, data => refreshIndustry(data));
	}, [industryOpened?.id]);

	const form = useForm({
		initialValues: { name: "", status: 1, words: [[], []] },
		validate: {
			name: value => {
				if (value.length == 0) return dict.invalidName;
				return form.values.status == 2 ? dict.waitProcess : null;
			},
			baseSites: value => ((value?.split("\n").filter(x => isUrl(x)).length || 0) < 7) && !form.values.isGeneric
				? dict.notEnoughSites : null,
		},
	});

	const saveIndustry = (values, recalculate) => {
		let data = { ...values };
		data.baseSites = nullIfEmpty(data.baseSites)?.split("\n").filter(x => isUrl(x));
		data.positiveWords = nullIfEmpty(data.positiveWords)?.split("\n");
		data.negativeWords = nullIfEmpty(data.negativeWords)?.split("\n");
		data.scrapedWords = data.words[0].map(x => { return x.value; });
		data.stopWords = data.words[1].map(x => { return x.value; });
		data.words = undefined;
		if (!shallowEqual(data.baseSites, initialSites)) data.status = 2;
		if (data.status >= 5 && data.positiveWords) data.scrapedWords = [...data.positiveWords, ...data.scrapedWords];
		if (data.status == 5 && isLive) data.status = 10;
		if (data.status == 10 && !isLive) data.status = 5;
		fetchUrl(`industry/${data.id}`, "PUT", objectToFormData(data), setSaving, recalculate ? null : dict.industrySaved, data => afterIndustrySaved(data, recalculate), dict.industrySaveError);
	};

	const afterIndustrySaved = (data, recalculate) => {
		if (recalculate) {
			fetchUrl(`leadProcessQueue/score?industryId=${industryOpened.id}`, "POST", null, setSaving, dict.recalculating, () => setSavedIndustry(data));
		} else
			setSavedIndustry(data);
	};

	const deleteIndustry = () => {
		setDeletedIndustry(industryOpened);
		fetchUrl(`industry/${industryOpened.id}`, "DELETE", null, setDeleting, dict.industryDeleted, () => setDeletedIndustry(industryOpened.id), dict.industryDeleteError);
	};

	const getButtonActions = () => {
		const actions = [
			{ icon: <DeviceFloppy size={20} />, label: dict.save },
			{ icon: <Trash size={20} />, label: dict.delete, onClick: () => setDeleteConfOpened(true), color: "red" },
		];
		if (industry?.status == 10)
			actions.push(
				{ icon: <Calculator size={20} />, label: dict.recalculate, onClick: () => setRecalcConfOpened(true) }
			);
		return actions;
	};

	return (
		<Modal size="95%" opened={industryOpened != null} onClose={() => setIndustryOpened(null)} centered overflow="outside" title={dict.editIndustry}>
			<Tabs defaultValue="details">
				<Tabs.List>
					<Tabs.Tab value="details" icon={<Settings size={16} />}>{dict.industry}</Tabs.Tab>
					<Tabs.Tab value="battle" icon={<PlayCard size={16} />}>{dict.battleCard}</Tabs.Tab>
				</Tabs.List>
				<Tabs.Panel value="details">
					<form onSubmit={form.onSubmit(values => saveIndustry(values, false))}>
						<Grid>
							<Grid.Col md={3}>
								<TextInput label={dict.industryName} required {...form.getInputProps("name")} maxLength={50}></TextInput>
							</Grid.Col>
							<Grid.Col md={3}>
								<MultiSelect label={dict.alternateName} {...form.getInputProps("alternateNames")}
									data={form?.values.alternateNames || []} searchable creatable
									getCreateLabel={lbl => `+ ${dict.add} "${lbl}"`}
								/>
							</Grid.Col>
							<Grid.Col md={0.5} />
							<Grid.Col md={1.5}>
								{industry &&
									<TextInput label={dict.status} readOnly value={industry.status == 1 ? dict.new : (industry.status == 2 ? dict.processing : (industry.status == 5 ? dict.processed : dict.live))} />
								}
							</Grid.Col>
							<Grid.Col md={2}>
								<Space h="md" />
								<Switch label={dict.industryLive} size="md" mt="md" disabled={industry?.status < 5} checked={isLive}
									onChange={(event) => setIsLive(event.currentTarget.checked)} />
							</Grid.Col>
						</Grid>
						<Grid>
							<Grid.Col md={6}>
								<TransferList {...form.getInputProps("words")} breakpoint="sm" listHeight={420}
									searchPlaceholder={`${dict.search}...`} nothingFound={dict.noWord}
									titles={[dict.wordsFound, dict.wordsToIgnore]}
								/>
							</Grid.Col>
							<Grid.Col md={0.5} />
							<Grid.Col md={5.5}>
								<Textarea label={dict.refSites} {...form.getInputProps("baseSites")} minRows={10} maxRows={10}>
								</Textarea>
								<Grid>
									<Grid.Col md={6}>
										<Textarea label={dict.positive} {...form.getInputProps("positiveWords")} minRows={8} maxRows={8}></Textarea>
									</Grid.Col>
									<Grid.Col md={6}>
										<Textarea label={dict.negative} {...form.getInputProps("negativeWords")} minRows={8} maxRows={8}></Textarea>
									</Grid.Col>
								</Grid>
							</Grid.Col>
						</Grid>
						<Group position="center" mt="sm">
							<SplitButton loading={saving || deleting} type="submit" actions={getButtonActions()} />
						</Group>
					</form>
				</Tabs.Panel>
				<Tabs.Panel value="battle">
					<BattleCards entityType={1} entityId={industryOpened?.id} />
				</Tabs.Panel>
			</Tabs>
			<Confirm opened={recalcConfOpened} setOpened={setRecalcConfOpened} yesLabel={dict.recalculate} yesAction={() => saveIndustry(form.values, true)}>
				{dict.recalculateConf}
			</Confirm>
			<Confirm opened={deleteConfOpened} setOpened={setDeleteConfOpened} yesLabel={dict.delete} yesAction={deleteIndustry}>
				{dict.deleteConf}
			</Confirm>
		</Modal>
	);
}