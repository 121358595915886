import React, { useState } from "react";
import { Modal, Stack, Text, TextInput, NumberInput, Group, Button } from "@mantine/core";
import { fetchUrl } from "utils/urlHelpers";
import { X, Check } from "tabler-icons-react";

export default function LeadsActionHubspot({ dict, opened, setOpened, rowCount, filters }) {
	const [campaign, setCampaign] = useState("");
	const [contactCount, setContactCount] = useState(5);
	const [minContactScore, setMinContactScore] = useState("");

	const exportHubspot = () => {
		const url = `leadProcessQueue/exportHubspot?contactCount=${contactCount}&minContactScore=${minContactScore}&campaign=${encodeURIComponent(campaign)}`;
		fetchUrl(url, "POST", JSON.stringify(filters), null, dict.exportHubspotSent);
	};

	return (
		<Modal centered title={dict.export} opened={opened} onClose={() => setOpened(false)}>
			<Stack>
				<Text>{dict.exportHubspotConfirm?.replace("{count}", rowCount)}</Text>
				<TextInput value={campaign} label={dict.exportHubspotName} onChange={(e) => setCampaign(e.currentTarget.value)} />
				<NumberInput value={contactCount} label={dict.exportHubspotContact} onChange={setContactCount} />
				<NumberInput value={minContactScore} label={dict.exportHubspotMinScore} onChange={setMinContactScore} />
				<Group position="center" mt="sm">
					<Button leftIcon={<X size={20} />} variant="outline" onClick={() => setOpened(false)}>{dict.cancel}</Button>
					<Button leftIcon={<Check size={20} />} onClick={exportHubspot}>{dict.export}</Button>
				</Group>
			</Stack>
		</Modal>
	);
}