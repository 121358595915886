import React, { useEffect, useState } from "react";
import { Stack, NumberInput, Group, Button, TransferList, Switch } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DeviceFloppy } from "tabler-icons-react";
import { objectToFormData } from "utils/helpers";
import { getUrl, fetchUrl } from "utils/urlHelpers";

export default function UserConfig({ dict, visible, user, setUserOpened }) {
	const [saving, setSaving] = useState(null);
	const [industriesLoaded, setIndustriesLoaded] = useState(false);
	const [viewAll, setViewAll] = useState(true);

	useEffect(() => {
		if (user?.maxCompanySize !== undefined && visible && !industriesLoaded) {
			getUrl(`user/${user.id}/industryRestriction`, (industries) => {
				let ko = industries.filter(x => !x.restricted).map(x => ({ value: x.id + "", label: x.name }));
				let ok = industries.filter(x => x.restricted === true).map(x => ({ value: x.id + "", label: x.name }));
				setIndustriesLoaded(true);
				setViewAll(ok.length == 0);
				form.setValues({ minCompanySize: user.minCompanySize || "", maxCompanySize: user.maxCompanySize || "", industries: [ko, ok] });
			});
		}
	}, [user?.maxCompanySize, visible]);

	const form = useForm({
		initialValues: { minCompanySize: "", maxCompanySize: "", industries: [[], []] }
	});

	const saveConfig = (values) => {
		let data = {
			minCompanySize: values.minCompanySize || undefined,
			maxCompanySize: values.maxCompanySize || undefined,
			restrictionIndustryIds: viewAll || values.industries[0].length == 0 ? [] : values.industries[1].map(x => parseInt(x.value, 10))
		};
		fetchUrl(`user/${user.id}/config`, "PUT", objectToFormData(data), setSaving, dict.configSaved, () => { setUserOpened(null); });
	};

	return (
		<form onSubmit={form.onSubmit(values => saveConfig(values))}>
			<Stack>
				<Group position="apart" spacing="xs">
					<NumberInput label={dict.minCompanySize} {...form.getInputProps("minCompanySize")} />
					<NumberInput label={dict.maxCompanySize} {...form.getInputProps("maxCompanySize")} />
					<Switch mt={20} checked={viewAll} onChange={(event) => setViewAll(event.currentTarget.checked)} label={dict.viewAllIndustries} />
				</Group>
				<TransferList {...form.getInputProps("industries")} breakpoint="sm" listHeight={320}
					sx={{ visibility: viewAll ? "hidden" : undefined }}
					searchPlaceholder={`${dict.search}...`} titles={[dict.industryAll, dict.industryOk]}
				/>
				<Group position="center" mt="sm">
					<Button type="submit" leftIcon={<DeviceFloppy size={20} />} loading={saving}>{dict.save}</Button>
				</Group>
			</Stack>
		</form>
	);
}