import React, { useEffect, useState } from "react";
import { Modal, Group, Stepper, Text } from "@mantine/core";
import { useDictionary } from "i18n/dictionary";
import { getUrl, fetchUrl } from "utils/urlHelpers";
import { objectToFormData } from "utils/helpers";
import StepperButtons from "components/StepperButtons";
import LeadsActionAssignParam from "./LeadsActionAssignParam";
import LeadsActionAssignRatio from "./LeadsActionAssignRatio";

export default function LeadsActionAssign({ opened, setOpened, filters, rowCount }) {
	const dict = useDictionary("assign");
	const [activeStep, setActiveStep] = useState(0);
	const [saving, setSaving] = useState(false);
	const [allUsers, setAllUsers] = useState(null);
	const [users, setUsers] = useState([]);
	const [ratioType, setRatioType] = useState("percent");
	const [userRatio, setUserRatio] = useState([]);
	const [totalRatio, setTotalRatio] = useState(null);
	const [assignLeftovers, setAssignLeftovers] = useState(true);

	useEffect(() => {
		getUrl("user", (json) => {
			setAllUsers(json.map(x => ({ value: x.id, label: x.firstName + " " + x.lastName })));
		});
	}, []);
	useEffect(() => {
		setActiveStep(0);
		setUserRatio([]);
		setUsers([]);
	}, [filters]);


	useEffect(() => {
		const total = ratioType == "percent" ? 100 : rowCount;
		setUserRatio(users.map(x => ({ id: x, ratio: Math.floor(total / users.length) })));
		setTotalRatio(total);
	}, [users, ratioType]);

	const assign = () => {
		const param = { filters: JSON.stringify(filters), userRatio: JSON.stringify(userRatio), ratioType: ratioType == "percent" ? 1 : 2, assignLeftovers: assignLeftovers };
		fetchUrl("assignment", "POST", objectToFormData(param), setSaving, dict.assignSaved, () => setOpened(false));
	};

	return (
		<Modal centered title={dict.assignLeads} opened={opened} onClose={() => setOpened(false)} size={700}>
			<Stepper active={activeStep} onStepClick={setActiveStep} breakpoint="sm">
				<Stepper.Step label={dict.step1} description={dict.step1Desc} allowStepSelect={activeStep > 0}>
					<LeadsActionAssignParam dict={dict} allUsers={allUsers} users={users} setUsers={setUsers} ratioType={ratioType} setRatioType={setRatioType}
						assignLeftovers={assignLeftovers} setAssignLeftovers={setAssignLeftovers} />
					<StepperButtons dict={dict} hidePrev={true} setActiveStep={setActiveStep} disabled={users.length == 0} />
				</Stepper.Step>
				<Stepper.Step label={dict.step2} description={dict.step2Desc} allowStepSelect={activeStep > 1}>
					<LeadsActionAssignRatio dict={dict} rowCount={rowCount} allUsers={allUsers} userRatio={userRatio} setUserRatio={setUserRatio} ratioType={ratioType}
						totalRatio={totalRatio} setTotalRatio={setTotalRatio} />
					<StepperButtons dict={dict} setActiveStep={setActiveStep} disabled={totalRatio > (ratioType == "percent" ? 100 : rowCount)} />
				</Stepper.Step>
				<Stepper.Step label={dict.stepFinal} description={dict.stepFinalDesc} allowStepSelect={activeStep > 3}>
					<Text mb="sm" align="center">
						{dict.assignRecap?.replace("[x]", rowCount)?.replace("[y]", users.length)}
					</Text>
					<StepperButtons dict={dict} hideNext={true} setActiveStep={setActiveStep} lastLabel={dict.assign} lastButtonAction={assign} loading={saving} />
				</Stepper.Step>
				<Stepper.Completed>
					<Group position="center" mt="xl">
						{dict.processImport}
					</Group>
				</Stepper.Completed>
			</Stepper>
		</Modal>
	);
}