import { Space, Text, useMantineTheme } from "@mantine/core";
import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";

export default function LeadCompanySize({ dict, company }) {
	const [sizeHistory, setSizeHistory] = useState(null);
	const theme = useMantineTheme();

	useEffect(() => {
		setSizeHistory(company?.documents?.filter(doc => doc.employeeCount != null)
			?.map(doc => [new Date(doc.date), doc.employeeCount, doc.employeeCount]));
	}, [company]);

	const getSizeText = company => {
		if (company.size !== null) return company.size;
		return company?.sizeMin !== null ? `${company.sizeMin} - ${company.sizeMax}` : null;
	};
	const getSizeTicks = () => {
		const years = sizeHistory.map(x => new Date(x[0])),
			minDt = new Date(Math.min.apply(null, years)),
			maxDt = new Date(Math.max.apply(null, years));
		let ticks = [];
		for (let i = minDt.getFullYear() + (minDt.getMonth() > 6 ? 1 : 0); i <= maxDt.getFullYear() + (maxDt.getMonth() < 5 ? 0 : 1); i++)
			ticks.push(new Date(i, 0, 1));
		return ticks;
	};
	const graphColor = theme.colorScheme === "dark" ? theme.colors.gray[5] : theme.colors.gray[7];

	return (
		sizeHistory?.length > 1
			? <>
				<Space h="xs" />
				<Chart chartType="LineChart" height="140px"
					data={[[dict.year, dict.employees, { role: "annotation" }]].concat(sizeHistory)}
					options={{
						title: dict.employees, legend: { position: "none" }, pointSize: 5,
						hAxis: { format: "yyyy", textStyle: { fontSize: 12, color: graphColor }, gridlines: { color: "transparent" }, ticks: getSizeTicks() },
						vAxis: { textPosition: "none", gridlines: { color: "transparent" } },
						annotations: { textStyle: { fontSize: 12 } },
						titleTextStyle: { fontSize: 13, color: graphColor },
						backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : "#FFF",
					}}
				/>
			</>
			: ((company.size != null || company.sizeMin != null) &&
				<>
					<Text weight={500} size="sm" ml={40}>{dict.employees}</Text>
					<Text size="sm" ml={60}>{getSizeText(company)}</Text>
				</>)

	);
}