import { ActionIcon, Grid, Select, TextInput } from "@mantine/core";
import LazyDropDown from "components/LazyDropDown";
import React, { useEffect, useState } from "react";
import { Trash } from "tabler-icons-react";

export default function LeadFilterAiDataItem({ dict, item, setItem, deleteItem }) {
	const [property, setProperty] = useState(item.property);
	const [comparison, setComparison] = useState(item.comparison);
	const [value, setValue] = useState(item.value);

	useEffect(() => {
		if (property && comparison && value)
			setItem({ property, comparison, value });
	}, [property, comparison, value]);


	return (
		<Grid columns={50} gutter="xs">
			<Grid.Col span="auto">
				<LazyDropDown placeholder={dict.property} value={property} onChange={setProperty} path="leadAiData/properties" />
			</Grid.Col>
			<Grid.Col span={9}>
				<Select value={comparison} onChange={setComparison} data={["=", "<>", ">=", "<="]} />
			</Grid.Col>
			<Grid.Col span={14}>
				<TextInput placeholder={dict.value} value={value} onChange={e => setValue(e.target.value)} />
			</Grid.Col>
			<Grid.Col span="content">
				<ActionIcon variant="outline" color="blue" size="sm" onClick={deleteItem} mt={6}>
					<Trash size={16} strokeWidth={1} />
				</ActionIcon>
			</Grid.Col>
		</Grid>
	);
}