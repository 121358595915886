import React, { useEffect, useState } from "react";
import { Skeleton, Table, Text } from "@mantine/core";
import { getUrl } from "utils/urlHelpers";
import { Bulb, Check } from "tabler-icons-react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export default function AssignTable({ dict }) {
	const [assignments, setAssignments] = useState(null);

	useEffect(() => {
		getUrl("assignment", setAssignments);
	}, []);

	const getRatioType = (type) => {
		return type == 1 ? dict.percentage : (type == 2 ? dict.fixed : null);
	};
	const timeZone = JSON.parse(localStorage.getItem("user")).tz;
	
	const rows = assignments?.map(x => (
		<tr key={x.id}>
			<td>{dayjs(x.dateInserted+"Z").tz(timeZone).format("DD/MM/YYYY HH:mm")}</td>
			<td align="center">{JSON.parse(x.userRatio).length}</td>
			<td align="center">{getRatioType(x.ratioType)}</td>
			<td align="center">{x.assignLeftovers ? dict.yes : dict.no}</td>
			<td align="center">{x.completed ? <Check /> : <Bulb />}</td>
		</tr>
	));
	const placeHolderRow = Array.from({ length: 5 }, (v, i) => <td key={i}><Skeleton height={42} /></td>);
	const placeHolder = Array.from({ length: 10 }, (v, i) => <tr key={i}>{placeHolderRow}</tr>);

	return (
		assignments?.length == 0
			? <Text ml={100} my={50}>{dict.nothing}</Text>
			: <Table sx={{ width: 700 }}>
				<thead>
					<tr>
						<th>{dict.date}</th>
						<th style={{ textAlign: "center" }}>{dict.recipient}</th>
						<th style={{ textAlign: "center" }}>{dict.ratioType}</th>
						<th style={{ textAlign: "center" }}>{dict.leftovers}</th>
						<th style={{ textAlign: "center" }}>{dict.status}</th>
					</tr>
				</thead>
				<tbody>{rows || placeHolder}</tbody>
			</Table>
	);
}