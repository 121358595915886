import React, { useState, useEffect } from "react";
import { Grid, Group, Image, Table, Anchor, Text, Button, TextInput, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { objectToFormData } from "utils/helpers";
import { getUrl, fetchUrl } from "utils/urlHelpers";
import dayjs from "dayjs";
import { Checks, DeviceFloppy, HomeQuestion, PuzzleOff, Search } from "tabler-icons-react";
import SplitButton from "components/SplitButton";
import Confirm from "components/Confirm";
import { shallowEqual } from "@mantine/hooks";

export default function RoadLeadEdit({ dict, roadLead, setSavedRoadLead }) {
	const [saving, setSaving] = useState(null);
	const [photoUrl, setPhotoUrl] = useState(null);
	const [lead, setLead] = useState(null);
	const [commentPopupOpened, setCommentPopupOpened] = useState(false);
	const [comment, setComment] = useState("");
	const [initValues, setInitValues] = useState(null);

	useEffect(() => {
		if (!roadLead) return;
		getUrl(`roadLead/${roadLead.id}`, roadLeadLoaded);
	}, [roadLead?.id]);

	const roadLeadLoaded = data => {
		setLead(data);
		const val = {
			website: data.website || null, regNumber: data.regNumber || null, name: data.name || null, phone: data.phone || null, status: data.status
		};
		setInitValues(val);
		form.setValues(val);
		if (data.hasPhoto)
			getUrl(`roadLead/${roadLead.id}/photo`, photo => setPhotoUrl(photo.url));
	};

	const form = useForm({
		initialValues: {
			website: "", regNumber: "", name: lead?.name || "", phone: "", status: roadLead?.status
		}, transformValues: values => ({ phone: values.phone || undefined })
	});

	const saveLead = (values, status) => {
		let data = { ...lead, ...values, ...{ statusComment: status != 5 && comment || undefined, status: status || form.values.status } };
		fetchUrl(`roadLead/${roadLead.id}`, "PUT", objectToFormData(data), setSaving, dict.roadLeadUpdated, data => setSavedRoadLead({ ...roadLead, ...data }), dict.roadLeadUpdateError);
	};
	const isDirty = !shallowEqual(initValues, form.values);

	const leadContent = roadLead?.id &&
		<tbody>
			<tr>
				<td><b>{dict.status}</b></td>
				<td>
					<Group position="apart">
						{dict[`status${roadLead?.status}`]}
						{roadLead?.status <= 2 && roadLead?.isNew &&
							<SplitButton compact variant="outline" loading={saving} disabled={isDirty}
								actions={[
									{ label: dict.status5, icon: <Checks size={20} />, onClick: () => saveLead(lead, 5) },
									{ label: dict.status4, icon: <PuzzleOff size={20} />, onClick: () => { form.setValues({ status: 4 }); setCommentPopupOpened(true); } },
									{ label: dict.status10, icon: <HomeQuestion size={20} />, onClick: () => { form.setValues({ status: 10 }); setCommentPopupOpened(true); } },
								]} />
						}
					</Group>
					<Text>{lead?.statusComment}</Text>
				</td>
			</tr>
			<tr>
				<td><b>{dict.from}</b></td><td><Text transform="capitalize">{roadLead?.email.split("@")[0].replace(".", " ")}</Text></td>
			</tr>
			<tr>
				<td><b>{dict.dateSpotted}</b></td><td>{dayjs(roadLead?.dateSpotted).format("DD/MM/YYYY HH:mm")}</td>
			</tr>
			<tr>
				<td><b>{dict.spottedIn}</b></td><td>{lead?.address}<br />{lead?.postcode} {roadLead?.town}</td>
			</tr>
			<tr>
				<td><b>{dict.name}</b></td>
				<td>
					{roadLead?.status <= 2 && roadLead?.isNew
						? <TextInput {...form.getInputProps("name")} />
						: roadLead?.name}
				</td>
			</tr>
			<tr>
				<td><b>{dict.reg}</b></td>
				<td>
					{roadLead?.status <= 2 && roadLead?.isNew
						? <TextInput {...form.getInputProps("regNumber")} />
						: lead?.regNumber}
				</td>
			</tr>
			<tr>
				<td><b>{dict.website}</b></td>
				<td>
					{roadLead?.status <= 2 && roadLead?.isNew
						? <TextInput {...form.getInputProps("website")} />
						: <Anchor href={roadLead?.website} target="_blank">{roadLead?.website}</Anchor>}
				</td>
			</tr>
			{lead?.phone && <tr>
				<td><b>{dict.phone}</b></td>
				<td>
					{roadLead?.status <= 2 && roadLead?.isNew
						? <TextInput {...form.getInputProps("phone")} />
						: lead?.phone}
				</td>
			</tr>}
			{lead?.note && <tr>
				<td><b>{dict.note}</b></td><td>{lead?.note?.split("\n").map(x => <div key={x}>{x}</div>)}</td>
			</tr>}
			{lead?.leadId &&
				<tr>
					<td><b>{dict.lead}</b></td>
					<td>
						<Anchor href={`/leads/${lead.companyId}`} target="_blank">https://diamond.bigchange.com/leads/{lead.companyId}</Anchor>
					</td>
				</tr>
			}
			{roadLead?.status <= 2 && roadLead?.isNew &&
				<tr>
					<td colSpan={2}>
						<Group mt="md" spacing="xs">
							<Search size={18} />
							<Anchor target="_blank" href={roadLead?.website}>{dict.website}</Anchor>
							|<Anchor target="_blank" href={`https://find-and-update.company-information.service.gov.uk/company/${lead?.regNumber}`}>C.H. by reg.</Anchor>
							|<Anchor target="_blank" href={`https://find-and-update.company-information.service.gov.uk/search?q=${encodeURIComponent(roadLead?.name)}`}>C.H. by name</Anchor>
							|<Anchor target="_blank" href={`https://www.google.com/search?q=${encodeURIComponent(roadLead?.name)}`}>Google</Anchor>
							|<Anchor target="_blank" href={`https://www.linkedin.com/search/results/companies/?keywords=${encodeURIComponent(roadLead?.name)}`}>LinkedIn</Anchor>
						</Group>
					</td>
				</tr>}
		</tbody>;
	return (
		<>
			<form onSubmit={form.onSubmit(() => saveLead(form.values, 5))}>
				<Grid align="center">
					<Grid.Col md={5}>
						<Stack>
							<Table>
								{leadContent}
							</Table>
							{roadLead?.status <= 2 && roadLead?.isNew &&
								<Group position="center">
									<Button type="submit" leftIcon={<DeviceFloppy size={20} />} disabled={!isDirty} loading={saving}>{dict.save}</Button>
								</Group>
							}
						</Stack>
					</Grid.Col>
					<Grid.Col md={7} style={{ maxHeight: 500 }}>
						{lead?.hasPhoto && <Image radius="md" src={photoUrl} withPlaceholder height={450} fit="scale-down" />}
					</Grid.Col>
				</Grid>
				<Group position="center" mt="sm">
				</Group>
			</form>
			<Confirm opened={commentPopupOpened} setOpened={setCommentPopupOpened}
				yesLabel={dict[`status${form.values.status}`]}
				yesAction={() => saveLead(lead)}>
				{dict[`status${form.values.status}Comment`]}
				<TextInput value={comment} onChange={e => setComment(e.currentTarget.value)} />
			</Confirm>
		</>
	);
}