import React, { useEffect, useState } from "react";
import { ActionIcon, Button, Group, Skeleton, Stack, Table, Text } from "@mantine/core";
import { Eye, Plus } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import { getUrl } from "utils/urlHelpers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Import from "./Import";
import ImportTableError from "./importTableError";
dayjs.extend(utc);
dayjs.extend(timezone);

export default function ImportTable() {
	const dict = useDictionary("import");
	const [importOpened, setImportOpened] = useState(false);
	const [imports, setImports] = useState(null);
	const [errorOpened, setErrorOpened] = useState(null);

	useEffect(() => {
		getUrl("import", setImports);
	}, []);

	const getType = (type) => {
		return type == 1 ? dict.leads : (type == 2 ? dict.contacts : (type == 3 ? dict.tags : null));
	};
	const timeZone = JSON.parse(localStorage.getItem("user")).tz;

	const rows = imports?.map(x => (
		<tr key={x.id}>
			<td>{x.name}</td>
			<td>{getType(x.importType)}</td>
			<td>{x.rowCount}</td>
			<td>
				{x.processStart && dayjs(x.processStart + "Z").tz(timeZone).format("DD/MM/YYYY HH:mm")}
			</td>
			<td>{x.processEnd && dayjs(x.processEnd + "Z").tz(timeZone).format("DD/MM/YYYY HH:mm")}</td>
			<td style={{ display: "flex" }}>
				{dict[`status${x.status}`]}
				{x.status == 6 && 
				<ActionIcon variant="transparent" color="blue" onClick={() => setErrorOpened(x.errors)} sx={{ height:"auto", minHeight:"auto"}}>
					<Eye size={18} />
				</ActionIcon>}
			</td>
		</tr>
	));
	const placeHolderRow = Array.from({ length: 6 }, (v, i) => <td key={i}><Skeleton height={42} /></td>);
	const placeHolder = Array.from({ length: 10 }, (v, i) => <tr key={i}>{placeHolderRow}</tr>);

	return (
		<>
			<Stack>
				<Group position="right">
					<Button leftIcon={<Plus size={20} />} onClick={() => setImportOpened(true)}>{dict.newImport}</Button>
				</Group>
			</Stack>
			{imports?.length == 0
				? <Text ml={100} mt={10}>{dict.nothing}</Text>
				: <Table mt={4}>
					<thead>
						<tr>
							<th>{dict.importFile}</th>
							<th>{dict.type}</th>
							<th>{dict.rowCount}</th>
							<th>{dict.processStart}</th>
							<th>{dict.processEnd}</th>
							<th>{dict.status}</th>
						</tr>
					</thead>
					<tbody>{rows || placeHolder}</tbody>
				</Table>
			}
			<Import dict={dict} opened={importOpened} setOpened={setImportOpened} />
			{errorOpened && <ImportTableError dict={dict} errors={errorOpened} setErrorOpened={setErrorOpened} />}
		</>
	);
}