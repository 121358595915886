import React from "react";
import { Accordion, Group, Button, Text, Table } from "@mantine/core";
import { ArrowBigRight } from "tabler-icons-react";
import { formatDate } from "utils/helpers";

export default function RoadLeadFormCompanyFound({ step2Result: lead, setStep }) {
	const tableContent = [
		{ label: "Hubspot name", data: lead.name },
		{ label: "Hubspot status", data: lead.status && `${lead.status} (${formatDate(lead.currentStatusDate)})` },
		{ label: "Town", data: lead.town },
		{ label: "Size", data: lead.size !== null ? lead.size : (lead.sizeMin !== null ? `${lead.sizeMin} - ${lead.sizeMax}` : null) },
		{ label: "Best score", data: lead.bestScore > 10 ? `${lead.bestScore} (${lead.bestIndustry})` : null },
		{ label: "Bigchange name", data: lead.dupName?.replace("Ltd", "") },
		{ label: "Bigchange group", data: lead.dupGroup },
		{ label: "Bigchange last touched", data: formatDate(lead.dupLast) },

	].filter(x => x.data != null && x.data != undefined)
		.map(x =>
			<tr key={x.label}>
				<td><Text weight={500}>{x.label}</Text></td>
				<td><Text>{x.data}</Text></td>
			</tr>
		);
	return (
		<Accordion.Item value="3">
			<Accordion.Control>3. Confirm</Accordion.Control>
			<Accordion.Panel>
				<Text>The following matching company has been found</Text>
				<br />
				<Table>
					<tbody>{tableContent}</tbody>
				</Table>
				<Group position="right" mt="sm">
					<Button leftIcon={<ArrowBigRight size={20} />} onClick={() => setStep("4")}>Next</Button>
				</Group>
			</Accordion.Panel>
		</Accordion.Item>
	);
}