import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import Dashboard from "./pages/Dashboard/Dashboard";
import Leads from "./pages/Leads/Leads";
import Lead from "./pages/Lead/Lead";
import RoadLeads from "./pages/RoadLeads/RoadLeads";
import RoadLeadEdit from "./pages/RoadLeads/RoadLeadEdit";
import RoadLead from "./pages/RoadLead/RoadLead";
import Contacts from "./pages/Contacts/Contacts";
import Parameters from "./pages/Parameters/Parameters";
import Industries from "./pages/Admin/Industry/Industries";
import Competitors from "./pages/Admin/Competitor/Competitors";
import ContactPosition from "./pages/Admin/Contact/ContactPosition";
import Users from "./pages/Admin/User/Users";
import Processes from "pages/Process/Processes";
import SalesLead from "pages/SalesLead/SalesLead";
import Issues from "pages/Admin/Issue/Issues";

const root = ReactDOM.createRoot(document.getElementById("root"));
const scope = "create:api_keys create:companies create:lead_process_queues create:contacts create:imports create:industries create:issue_reports create:leads create:lead_status_historic create:statuses create:users create:battle_cards create:lead_duplicates create:bigchange_duplicates create:export create:competitors create:tags create:lead_tags create:contact_tags create:assignment " +
	"delete:api_keys delete:contacts delete:industries delete:leads delete:lead_status_historic delete:statuses delete:users delete:battle_cards delete:competitors delete:tags delete:lead_tags delete:contact_tags delete:roadLeads " +
	"read:api_keys_customer read:companies read:company_categories read:company_documents read:company_match read:company_match_extras read:company_webpages read:contacts read:customers read:imports read:industries read:issue_reports read:lead_duplicates read:lead_extras read:lead_extra_types read:leads read:lead_scores read:lead_status_historic read:nearby_poi read:parameters read:parameters_all read:statuses read:users read:users_all read:user_role read:battle_cards read:export read:competitors read:tags read:lead_tags read:contact_tags read:contact_scoring read:assignment read:roadLeads read:lead_process_queues read:company_webAddresses read:lead_aiData read:company_parents " +
	"update:companies update:contacts update:imports update:industries update:issue_reports update:leads update:lead_status_historic update:parameters update:parameters_all update:statuses update:users update:users_all update:battle_cards update:competitors update:tags update:lead_tags update:contact_tags update:contact_scoring update:assignment update:roadLeads update:company_webAddresses";

root.render(
	<Auth0Provider domain="bigchange.eu.auth0.com" useRefreshTokens cacheLocation="localstorage"
		clientId="ydp2IZCITA8xs6JKNdy4mz3zpijEqTsT"
		scope={scope} redirectUri={window.location.origin}
		audience="https://api.diamond.bigchange.com"
	>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />}>
					<Route path="dashboard" element={<Dashboard />} />
					<Route path="leads" element={<Leads />} >
						<Route path=":companyId" element={<Lead />} />
					</Route>
					<Route path="roadLeads" element={<RoadLeads />} >
						<Route path=":roadLeadId" element={<RoadLeadEdit />} />
					</Route>
					<Route path="contacts" element={<Contacts />} />
					<Route path="process/:tabValue" element={<Processes />} />
					<Route path="settings" element={<Parameters forUser={true} />} />
					<Route path="admin" >
						<Route path="industries" element={<Industries />} />
						<Route path="competitors" element={<Competitors />} />
						<Route path="contacts" element={<ContactPosition />} />
						<Route path="users" element={<Users />} />
						<Route path="issues" element={<Issues />}>
							<Route path=":companyId" element={<Lead />} />
						</Route>
						<Route path="settings" element={<Parameters forUser={false} />} />
					</Route>
					<Route path="battle/:companyId" element={<Lead />} />
				</Route>
				<Route path="road" element={<RoadLead />} />
				<Route path="sales" element={<SalesLead />} />
				<Route path="*" element={<main style={{ padding: "1rem" }}><p>Nothing there yet!</p></main>} />
			</Routes>
		</BrowserRouter>
	</Auth0Provider>
);