import React, { useEffect, useState } from "react";
import { Stack, Tabs } from "@mantine/core";
import { getUrl } from "utils/urlHelpers";
import { useDictionary } from "i18n/dictionary";
import ParameterField from "./ParameterField";
import ContentBox from "components/ContentBox";

export default function Parameters({ forUser }) {
	const dict = useDictionary("parameters");
	const [types, setTypes] = useState(null);
	const [params, setParams] = useState(null);

	useEffect(() => {
		getUrl(`parameter/type?forUser=${forUser}`, data => {
			setTypes(data);
			getUrl(`parameter${forUser ? "/my" : ""}`, setParams);
		});
	}, []);

	const categories = [...new Set(types?.map(x => x.category))];

	const tabList = categories.map(cat =>
		<Tabs.Tab key={cat} value={cat}>{dict[`category${cat}`]}</Tabs.Tab>
	);
	const getTabContent = category => {
		return types.filter(x => x.category == category).map(x =>
			<ParameterField dict={dict} key={x.name} type={x} param={params.find(y => x.id == y.type)} />
		);
	};
	const tabPanels = categories.map(cat =>
		<Tabs.Panel key={cat} value={cat} pt="md">
			<ContentBox>
				<Stack>
					{params && getTabContent(cat)}
				</Stack>
			</ContentBox>
		</Tabs.Panel>
	);
	return (
		categories.length &&
		<Tabs defaultValue={categories[0]}>
			<Tabs.List>
				{tabList}
			</Tabs.List>
			{tabPanels}
		</Tabs>
		|| <></>
	);
}