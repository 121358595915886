import React, { useState, useEffect } from "react";
import { Text, Group, Stack, Button, Center } from "@mantine/core";
import { Trophy, Star, ArrowBigTop  } from "tabler-icons-react";
import { getUrl } from "utils/urlHelpers";

export default function RoadLeadRanking() {
	const [ranking, setRanking] = useState(null);

	useEffect(() => {
		getUrl("roadLead/ranking", setRanking);
	}, []);

	return (
		ranking?.score &&
		<Stack spacing="xs">
			<Text mt="md" mb="xs" align="center" weight={500}>Road Lead received, thank you!</Text>
			<Group>
				<Star fill="#FD9D00" color="#B97300"/><Text>You have submitted <b>{ranking.score}</b> leads this month</Text>
			</Group>
			<Group>
				<Trophy fill="#EEEB00" color="#CBC800"/><Text>Your current monthly ranking is <b>{ranking.ranking}</b>!</Text>
			</Group>
			{ranking.gap > 0 && <Group>
				<ArrowBigTop fill="#5AEF00" color="#46B900"/><Text>Only <b>{ranking.gap}</b> extra lead needed to move up the rankings</Text>
			</Group>}
			<Center mt="xl">
				<Button onClick={() => location.reload()}>Send another</Button>
			</Center>
		</Stack>
	);
}