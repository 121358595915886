import React from "react";
import { ScrollArea, Text, Slider } from "@mantine/core";

export default function LeadsActionAssignRatio({ dict, rowCount, allUsers, userRatio, ratioType, totalRatio, setTotalRatio }) {
	const updateSlider = (ratio, value) => {
		ratio.ratio = value;
		setTotalRatio(userRatio.map(x => x.ratio).reduce((x, y) => x + y));
	};

	const max = Math.round((ratioType == "percent" || userRatio.length == 0 ? 100 : rowCount) / userRatio.length * 2);
	const unit = ratioType == "percent" ? "%" : "";
	const sliderMarks = [{ value: 0, label: 0 + unit }, { value: Math.round(max / 2), label: Math.round(max / 2) + unit }, { value: max, label: max + unit }];

	const content = userRatio.map(ratio =>
		<tr key={ratio.id}>
			<td>
				<Text>{allUsers.find(x => x.value === ratio.id).label}</Text>
			</td>
			<td style={{ width: 400 }}>
				<Slider labelAlwaysOn defaultValue={ratio.ratio} min={0} max={max} marks={sliderMarks} onChangeEnd={value => updateSlider(ratio, value)} />
			</td>
		</tr>
	);

	return (
		<ScrollArea style={{ height: 550 }}>
			<table cellPadding="12">
				<tbody>
					<tr>
						<td></td>
						<td align="right"><Text weight={500} color={totalRatio > (ratioType == "percent" ? 100 : rowCount) ? "red" : undefined}>
							{dict.assigned} {totalRatio} / {ratioType == "percent" ? 100 : rowCount}</Text>
						</td>
					</tr>
					{content}
				</tbody>
			</table>
		</ScrollArea>
	);
}