import React, { useState, useEffect } from "react";
import { Group, Pagination, Stack, Text } from "@mantine/core";
import ContactsFilter from "./ContactsFilter";
import ContactsTable from "./ContactsTable";
import { fetchUrl } from "utils/urlHelpers";
import ContentBox from "components/ContentBox";
import { useDictionary } from "i18n/dictionary";

export default function Contacts() {
	const dict = useDictionary("contacts");
	const [contacts, setContacts] = useState(null);
	const [contactCount, setContactCount] = useState(null);
	const [filters, setFilters] = useState(null);
	const [activePage, setActivePage] = useState(1);

	useEffect(() => {
		if (filters === null) return;
		if (filters.rowStart === undefined && activePage > 1) {	// filter has changed => back to page 1
			setActivePage(1);
			return;
		}
		filters.rowStart = (activePage - 1) * filters.rowCount;
		const onSuccess = json => {
			setContacts(json.contacts);
			setContactCount(json.rowCount);
		};
		fetchUrl("contact/search", "POST", JSON.stringify(filters), null, null, onSuccess);
	}, [filters, activePage]);

	return (
		<Stack>
			<ContentBox>
				<ContactsFilter update={setFilters} />
				<Group position="right" my="xs">
					<Pagination total={filters == null ? 0 : Math.ceil(contactCount / filters?.rowCount)} page={activePage} onChange={setActivePage} />
				</Group>
				<ContactsTable contacts={contacts} dict={dict}/>
			</ContentBox>
			{contacts !== null && <Text align="center" size="sm">{dict.contactCount?.replace("{count}", contactCount)}</Text>}
		</Stack>
	);
}