import { useState, useEffect } from "react";

export function useDictionary(module) {
	const [dictionary, setDictionary] = useState({});

	useEffect(() => {
		let lang = JSON.parse(localStorage.getItem("user"))?.lang
			|| (navigator.language || navigator.userLanguage || "en").substring(0, 2);
		if (["fr", "en"].indexOf(lang) === -1) lang = "en";
		
		import(`i18n/lang.${lang}.json`).then(dict =>
			setDictionary({ ...dict.common, ...dict[module] })
		);
	}, []);

	return dictionary;
}