import React, { useEffect, useState } from "react";
import { ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchUrl } from "utils/urlHelpers";
import { useNavigate } from "react-router-dom";
import { NotificationsProvider } from "@mantine/notifications";
import AppShell from "./AppShell";
import AppLogin from "./AppLogin";

export default function DiamondApp() {
	const navigate = useNavigate();
	const { loginWithRedirect, logout, isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0();
	const [loadingUser, setLoadingUser] = useState(false);
	const [userFullyLoggedIn, setUserFullyLoggedIn] = useState(false);
	const [colorScheme, setColorScheme] = useState("light");

	const getRedirectUrl = () => {
		let redirect = JSON.parse(localStorage.getItem("redirect"));
		return redirect && new Date(redirect.expire) > new Date() && redirect.url.length > 1
			? redirect.url : null;
	};
	const doLogout = () => {
		localStorage.clear();
		logout({ returnTo: window.location.origin });
	};
	const getUserMetadata = async () => {
	try {
			const accessToken = await getAccessTokenSilently({ audience: "https://api.diamond.bigchange.com" });
			const userFromStorage = JSON.parse(localStorage.getItem("user"));

			if (accessToken == localStorage.getItem("token") && userFromStorage) {	// simple page refresh, no need to re-load user data
				setUserFullyLoggedIn(true);
				setColorScheme(userFromStorage.skin || "light");
				trackWithPendo(userFromStorage);
				return;
			}
			localStorage.setItem("token", accessToken);
		} catch (e) {
			if (e.error === "login_required" || e.error === "consent_required")
				loginWithRedirect();
			return;
		}

		const onSuccess = user => {
			localStorage.setItem("user", JSON.stringify(user));
			setColorScheme(user.skin);
			setUserFullyLoggedIn(true);
			trackWithPendo(user);
			let redirectUrl = getRedirectUrl();
			localStorage.removeItem("redirect");
			if (redirectUrl) navigate(redirectUrl);
		};
		fetchUrl("user/login", "GET", null, setLoadingUser, null, onSuccess, null, doLogout);
	};

	useEffect(() => {
		if (!localStorage.getItem("user") && !getRedirectUrl())
			localStorage.setItem("redirect", JSON.stringify({
				url: window.location.pathname,
				expire: new Date((new Date()).getTime() + 2 * 60000)	// gives users 2 mn to log in
			}));
	}, []);

	useEffect(() => {
		if (isAuthenticated && user?.sub)
			getUserMetadata();
	}, [getAccessTokenSilently, user?.sub]);

	const trackWithPendo = user => {
		// eslint-disable-next-line no-undef
		pendo.initialize({
			visitor: {
				id: user.email,
				full_name: `${user.firstName} ${user.lastName}`,
			}, account: {
				id: user.bigchangeId ? `PROD-${user.bigchangeId}` : `PROD-Diamond-${user.customerId}`,
				name: user.customerName
			}
		});
	};
	return (
		<ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={setColorScheme}>
			<MantineProvider theme={{
				colorScheme, fontFamily: "Roboto", components: {
					Input: {
						styles: theme => ({ input: { backgroundColor: theme.colorScheme === "dark" ? undefined : theme.colors.gray[1] } })
					},
					MultiSelect: {
						styles: theme => ({ value: { backgroundColor: theme.colorScheme === "dark" ? undefined : theme.colors.gray[4] } })
					},
				}
			}}>
				<NotificationsProvider>
					{isLoading || loadingUser || isAuthenticated && !userFullyLoggedIn ? <></>
						: (userFullyLoggedIn
							? <AppShell logout={doLogout} />
							: <AppLogin loginWithRedirect={loginWithRedirect} />
						)
					}
				</NotificationsProvider>
			</MantineProvider>
		</ColorSchemeProvider>
	);
}