import React, { useEffect, useState } from "react";
import { ActionIcon, Anchor, Modal, Tooltip } from "@mantine/core";
import { BinaryTree } from "tabler-icons-react";
import { getUrl } from "utils/urlHelpers";
import MermaidChart from "components/MermaidChart";
import usePathFinder from "hooks/pathFinder";

export default function LeadCompanyHierarchy({ dict, regNumber }) {
	const [hierarchy, setHierarchy] = useState(null);
	const [hierarchyOpened, setHierarchyOpened] = useState(false);
	const getCompanyLink = usePathFinder(hierarchy);

	useEffect(() => {
		getUrl(`company/${regNumber}/hierarchy?duplicateSource=1&duplicateGroup=Customers`, setHierarchy);
	}, []);

	const customerFound = hierarchy?.find(x => x.isInGroup);

	const getFillColor = x => {
		if (x.isInGroup) return "#FFBBBB";
		return x.regNumber == regNumber ? "#BBFFBB" : "#FFF";
	};
	const cleanCompanyName = x => {
		const name = x.name.length > 100 ? x.name.substring(0, 100) + "..." : x.name;
		return name.match(/(.{1,22})(\s|$)/g).join("\n");
	};
	const convertToMermaidSyntax = data => {
		const isBattle = window.location.pathname.indexOf("/battle/") != -1;
		let mermaidCode = "graph LR\n";

		data.forEach(x => mermaidCode += `${x.regNumber}("${cleanCompanyName(x)}\n${x.regNumber}")\n`);
		data.filter(x => x.parentReg !== null)
			.forEach(x => mermaidCode += `${x.parentReg} --> ${x.regNumber}\n`);
		data.forEach(x => {
			const strokeColor = x.isInGroup ? "#FF0000" : "#000";
			mermaidCode += `style ${x.regNumber} fill:${getFillColor(x)}, stroke:${strokeColor}\n`;
		});
		data.forEach(x => mermaidCode += `click ${x.regNumber} "${window.location.origin}/${isBattle ? "battle" : "leads"}/${x.id}" _blank\n`);
		return mermaidCode;
	};

	if (hierarchy?.length > 1)
		return (
			<>
				<Tooltip label={dict.viewHierarchy} openDelay={500}>
					<BlinkingActionIcon onClick={() => setHierarchyOpened(true)}
						color={customerFound ? "red" : "blue"}
						customerFound={customerFound} />
				</Tooltip>
				{hierarchyOpened &&
					<Modal opened={hierarchyOpened} onClose={() => setHierarchyOpened(false)} title={dict.hierarchy} size="xl">
						{customerFound &&
							getCompanyLink(regNumber)
								.map((x, i, arr) => <span key={x.id}>
									<Anchor href={`./${x.id}`} target="_blank">{`${x.name || x.regNumber}`}</Anchor>
									{i < arr.length - 1 && <>&nbsp;➡️&nbsp;</>}
								</span>)
						}
						<div style={{ height: 10 }} />
						<MermaidChart mermaidCode={convertToMermaidSyntax(hierarchy)} />
					</Modal>
				}
			</>
		);
}

function BlinkingActionIcon({ onClick, color, customerFound }) {
	const [blink, setBlink] = useState(false);

	useEffect(() => {
		const timer = setInterval(() => { setBlink(prev => !prev); }, 1000);
		return () => clearInterval(timer);
	}, []);

	return (
		<ActionIcon variant="outline" onClick={onClick}
			color={color}
			style={{ opacity: blink || !customerFound ? 1 : 0.3 }}>
			<BinaryTree size={18} strokeWidth={1.5} />
		</ActionIcon>
	);
}