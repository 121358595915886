import React from "react";
import { createStyles, Center } from "@mantine/core";
import { Circle } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
	score1: { fill: theme.colors.green[6], marginRight: theme.spacing.sm, strokeWidth: 1 },
	score2: { fill: theme.colors.yellow[6], marginRight: theme.spacing.sm, strokeWidth: 1 },
	score3: { fill: theme.colors.red[7], marginRight: theme.spacing.sm, strokeWidth: 1 },
	score4: { marginRight: theme.spacing.sm, strokeWidth: 1 },
}));

export default function LeadsTableScore({ lead }) {
	const { classes } = useStyles();

	const getScoreClass = (score) => {
		return score > 39 && classes.score1 || score > 29 && classes.score2 || score > 19 && classes.score3 || classes.score4;
	};

	return (
		<Center>
			{lead.bestScore && <>
				<Circle className={getScoreClass(Math.round(lead.bestScore))} />
				{Math.round(lead.bestScore)}
			</>
			}
		</Center>
	);
}