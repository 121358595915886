import React, { useState, useEffect } from "react";
import { Group, Text, SegmentedControl, TextInput, Stack, NumberInput } from "@mantine/core";

export default function RoadLeadsFilter({ dict, setFilters }) {
	const [verified, setVerified] = useState("-1");
	const [name, setName] = useState("");
	const [scoreMin, setScoreMin] = useState(null);


	useEffect(() => {
		const callUpdate = () => {
			setFilters({
				verified: verified == "-1" ? null : verified == "1",
				name: name.length > 2 ? name : undefined,
				scoreMin: scoreMin,
				rowCount: 10, sortColumn: "DateInserted", sortDirection: "DESC"
			});
		};
		const timer = setTimeout(callUpdate, 300);
		return () => clearTimeout(timer);
	}, [verified, name, scoreMin]);

	const verifiedOptions = [{ value: "0", label: dict.no }, { value: "1", label: dict.yes }, { value: "-1", label: dict.either }];

	return (
		<Group>
			<Stack spacing={0}>
				<Text size="sm" weight={500}>{dict.status5}</Text>
				<SegmentedControl data={verifiedOptions} value={verified} onChange={setVerified} />
			</Stack>
			<TextInput label={dict.name} value={name} onChange={e => setName(e.currentTarget.value)} />
			<NumberInput onChange={val => setScoreMin(val)} label={dict.scoreMin} value={scoreMin || ""} min={0} max={100} />
		</Group>
	);
}