import React from "react";
import { Stack, MultiSelect, Switch, SegmentedControl, Group, Text } from "@mantine/core";

export default function LeadsActionAssignParam({ dict, allUsers, users, setUsers, ratioType, setRatioType, assignLeftovers, setAssignLeftovers }) {
	return (
		<Stack spacing="xl">
			<MultiSelect label="Users" data={allUsers || []} value={users} onChange={setUsers} searchable multiple />
			<Group>
				<Text>{dict.basedOn}</Text>
				<SegmentedControl value={ratioType} onChange={setRatioType} data={[{ label: dict.percent, value: "percent" }, { label: dict.fixed, value: "value" }]} />
			</Group>
			<Switch label={dict.leftovers} checked={assignLeftovers} onChange={e => setAssignLeftovers(e.currentTarget.checked)}></Switch>
		</Stack>
	);
}