import React from "react";
import { Modal, Button, Text, Group } from "@mantine/core";
import { useDictionary } from "i18n/dictionary";
import { X, Check } from "tabler-icons-react";

export default function Confirm({ opened, setOpened, children, yesLabel, yesAction }) {
	const dict = useDictionary();
	const doYes = () =>{
		yesAction();
		setOpened(false);
	};

	return (
		<Modal opened={opened} withCloseButton={false} centered onClose={() => setOpened(false)}>
			<Text>
				{children}
			</Text>
			<Group position="center" mt="sm">
				<Button leftIcon={<X size={20}/>} variant="outline" onClick={()=> setOpened(false)}>{dict.cancel}</Button>
				<Button leftIcon={<Check size={20}/>} onClick={doYes}>{yesLabel}</Button>
			</Group>
		</Modal>
	);
}