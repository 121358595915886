import React from "react";
import { Anchor, createStyles } from "@mantine/core";
import { formatDate } from "utils/helpers";
import LeadSection from "./LeadSection";

const useStyles = createStyles(theme => ({
	link: { color: theme.colorScheme === "dark" ? theme.colors.gray[5] : theme.colors.dark[7], fontSize: theme.fontSizes.sm, textTransform: "capitalize" },
}));

export default function Documents(p) {
	const { classes } = useStyles();

	const content = p.company?.documents?.map(doc =>
		<div key={doc.id}>
			<Anchor href={`https://find-and-update.company-information.service.gov.uk/company/${p.company?.regNumber}/filing-history/${doc.sourceId}/document?format=pdf&download=0`}
				target="_blank" className={classes.link}>
				{formatDate(doc.date)} {doc.description.replace("accounts-", "").replace("with-accounts-type-", "").replaceAll("-", " ")} ({doc.pageCount} pages)
			</Anchor>
		</div>
	);

	return (
		<LeadSection label={p.label} maxHeight={p.height - 55} minHeight={p.height}>{content}</LeadSection>
	);
}