import { ActionIcon, Group, Modal, Tabs, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { AddressBook, BrandLinkedin, Diamond, FileDescription, WorldWww } from "tabler-icons-react";
import { getUrl } from "utils/urlHelpers";
import CognismContact from "./CognismContact";
import CognismDetails from "./CognismDetails";
import DiamondDetails from "../PopupDiamond/DiamondDetails";
import DiamondContact from "../PopupDiamond/DiamondContact";

export default function PopupCognism({ company, setCompany }) {
	const [activeTab, setActiveTab] = useState("details");
	const [diamondCompany, setDiamondCompany] = useState(null);

	const loadDiamondCompany = () => {
		if (company.url && !diamondCompany)
		getUrl(`providerSearch/diamond/company/website/${encodeURIComponent(company.url)}`, setDiamondCompany);
	};
	useEffect(loadDiamondCompany, []);

	const title = <Group position="left" spacing={0}>
		<Text mr="lg">{company.name}</Text>
		{company.url && <ActionIcon onClick={() => window.open(`https://${company.url}`)} color="blue"><WorldWww size={24} /></ActionIcon>}
		{company.linkedIn && <ActionIcon onClick={() => window.open(company.linkedIn)} color="blue" ><BrandLinkedin size={24} /></ActionIcon>}
	</Group>;

	return (
		<Modal opened={company != null} onClose={() => setCompany(null)} title={title} size={800}>
			<Tabs value={activeTab} onTabChange={setActiveTab}>
				<Tabs.List mb="xl">
					<Tabs.Tab value="details" icon={<FileDescription size={18} />}>Details</Tabs.Tab>
					<Tabs.Tab value="contacts" icon={<AddressBook size={18} />}>Contacts</Tabs.Tab>
					{diamondCompany && <>
						<Tabs.Tab value="diamondLead" icon={<Diamond size={18} />}>Diamond Data</Tabs.Tab>
						<Tabs.Tab value="diamondContacts" icon={<Diamond size={18} />}>Diamond Contacts</Tabs.Tab>
					</>}
				</Tabs.List>
				<Tabs.Panel value="details">
					<CognismDetails company={company} />
				</Tabs.Panel>
				<Tabs.Panel value="contacts">
					<CognismContact company={company} visible={activeTab == "contacts"} loadDiamondCompany={loadDiamondCompany} />
				</Tabs.Panel>
				{diamondCompany && <>
					<Tabs.Panel value="diamondLead">
						<DiamondDetails company={diamondCompany} visible={activeTab == "diamondLead"} />
					</Tabs.Panel>
					<Tabs.Panel value="diamondContacts">
						<DiamondContact company={diamondCompany} visible={activeTab == "diamondContacts"} />
					</Tabs.Panel>
				</>}
			</Tabs>
		</Modal>
	);
}