import { ActionIcon, Grid, Group, Paper, Stack, Text } from "@mantine/core";
import { useDictionary } from "i18n/dictionary";
import LeadsTableScore from "pages/Leads/LeadsTableScore";
import React, { useEffect, useState } from "react";
import { ExternalLink } from "tabler-icons-react";
import { formatDate } from "utils/helpers";
import { getUrl } from "utils/urlHelpers";

export default function DiamondDetails({ company, visible }) {
	const [lead, setLead] = useState(null);
	const dict = useDictionary("leadCompany");

	const formatInfo = (lbl, val) => {
		return <Text size="sm"><span style={{ fontWeight: 500 }}>{lbl}</span> {val}</Text>;
	};

	useEffect(() => {
		if (visible && lead == null) {
			getUrl(`providerSearch/diamond/company/${company.id}`, setLead);
		}
	}, [company, visible]);

	const duplicates = lead?.duplicates.map(dup => {
		const source = dup.sourceType == 1 ? "BigChange:" : "Hubspot:";
		const lastTouched = dup.lastTouched ? `Last touched on ${formatDate(dup.lastTouched)}` : "Never touched";

		return (
			<Group key={dup.id}>
				{formatInfo(source, lastTouched)}
				{dup.url && dup.sourceType == 1 && <ActionIcon onClick={() => window.open(dup.url)} color="blue"><ExternalLink size={18} /></ActionIcon>}
			</Group>
		);
	});
	const scores = lead?.scores.map(score => (
		<Group key={score.id}>
			<Text><LeadsTableScore lead={{ bestScore: score.score }} /></Text>
			<Text>{score.industry}</Text>
		</Group>
	));
	return (
		<Grid>
			<Grid.Col span={6}>
				<Paper withBorder p="xs" sx={{ backgroundColor: "#EEE" }}>
					<Stack spacing="xs">
						{company.dateOpened && formatInfo("Founded:", formatDate(company.dateOpened))}
						{company.regNumber && <Group>
							{formatInfo("Reg Number:", company.regNumber)}
							<ActionIcon onClick={() => window.open(`https://find-and-update.company-information.service.gov.uk/company/${company.regNumber}`)} color="blue"><ExternalLink size={18} /></ActionIcon>
						</Group>}
						{company.status && formatInfo("Company Status:", dict[`status${company.status}`])}
						{company.size != null && formatInfo("Head count:", company.size)}
						{company.sizeMin != null && formatInfo("Size:", company.sizeMin + " - " + company.sizeMax)}
						{company.industries?.length > 0 && formatInfo("Industries:", company.industries.join(", "))}
					</Stack>
				</Paper>
			</Grid.Col>
			<Grid.Col span={6}>
				<Paper withBorder p="xs" sx={{ backgroundColor: "#EEE" }}>
					<Stack spacing="xs">
						{company.address1 && <Text size="sm">{company.address1}<br /></Text>}
						{company.address2 && <Text size="sm">{company.address2}<br /></Text>}
						{(company.postcode || company.town) && <Text size="sm">{company.postcode} {company.town}<br /></Text>}
					</Stack>
				</Paper>
			</Grid.Col>
			{lead &&
				<Grid.Col span={6}>
					<Paper withBorder p="xs" sx={{ backgroundColor: "#EEE" }}>
						<Stack spacing="xs">
							{formatInfo("Lead Status:", `${lead.currentStatus} ${formatDate(lead.currentStatusDate)}`)}
							{duplicates}
						</Stack>
					</Paper>
				</Grid.Col>
			}
			{lead?.scores?.length > 0 &&
				<Grid.Col span={6}>
					<Paper withBorder p="xs" sx={{ backgroundColor: "#EEE" }}>
						{scores}
					</Paper>
				</Grid.Col>
			}
		</Grid>
	);
}