import React, { useState, useEffect } from "react";
import { Accordion, Group, Button, Text } from "@mantine/core";
import { Trash } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import { fetchUrl } from "utils/urlHelpers";

export default function IndustriesDupWords(p) {
	const dict = useDictionary("industries");
	const [dupWords, setDupWords] = useState(null);
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		if (!p.opened) return;

		const wordCount = {};
		p.industries?.forEach(x => x.scrapedWords?.forEach(y => { if (wordCount[y]) wordCount[y].push(x.name); else wordCount[y] = [x.name]; }));
		let topWords = [];
		for (let word in wordCount)
			topWords.push({ word: word, count: wordCount[word].length, industries: wordCount[word] });
		topWords.sort((a, b) => { return b.count - a.count; });
		topWords = topWords.filter(x => { return x.count > 2; });
		setDupWords(topWords);
	}, [p]);

	const removeWord = word => {
		const onSuccess = data => {
			p.setIndustries(data);
			setDupWords(dupWords.filter(x => { return x.word != word; }));
		};
		fetchUrl(`industry/word/${encodeURIComponent(word)}`, "DELETE", null, setDeleting, dict.wordRemoved, onSuccess, dict.wordNotRemoved);
	};

	const accordionItems = dupWords?.map(word => {
		return (
			<Accordion.Item key={word.word} value={word.word}>
				<Accordion.Control>
					<Text weight={600}>{`${word.word} (${word.count})`}</Text>
				</Accordion.Control>
				<Accordion.Panel>
					{word.industries.join(", ")}
					<Group position="center" mt="sm">
						<Button leftIcon={<Trash size={20} />} loading={deleting} onClick={() => removeWord(word.word)}>{dict.remove} {word.word}</Button>
					</Group>
				</Accordion.Panel>
			</Accordion.Item>
		);
	});
	return (
		<Accordion>{accordionItems}</Accordion>
	);
}