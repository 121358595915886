import React, { useEffect, useState } from "react";
import { Modal, SegmentedControl, Group, Button, MultiSelect } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { X, Check } from "tabler-icons-react";
import { getUrl, fetchUrl } from "utils/urlHelpers";
import { objectToFormData } from "utils/helpers";

export default function LeadsActionTag({ dict, opened, setOpened, filters }) {
	const [addRemove, setAddRemove] = useState("add");
	const [tags, setTags] = useState(null);
	const [selectedTags, setSelectedTags] = useState([]);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		getUrl("tag?entityType=1", json => {
			setTags(json.map(tag => ({ value: tag.id + "", label: tag.name })));
		});
	}, []);

	const updateTags = async () => {
		if (!selectedTags.length)
			return showNotification({ icon: <X size={18} />, color: "red", message: dict.selectTag });

		const tagIds = tags.map(tag => tag.value);
		let tags2Save = selectedTags.filter(tag => tagIds.includes(tag)).map(id => +id);
		const createNewTags = selectedTags.filter(tag => addRemove == "add" && !tagIds.includes(tag)).map(tag =>
			new Promise((ok, ko) =>
				fetchUrl("tag", "POST", objectToFormData({ entityType: 1, name: tag }), null, null, ok, null, ko)
			)
		);
		let newTags = await Promise.all(createNewTags);
		tags2Save = tags2Save.concat(newTags.map(tag => tag.id));
		fetchUrl("leadTag/batch?tagIds=" + tags2Save.join("&tagIds="), addRemove == "add" ? "POST" : "DELETE",
			JSON.stringify(filters), setSaving, addRemove == "add" ? dict.tagApplied : dict.tagRemoved, () => setOpened(false));
	};
	return (
		<Modal centered title={dict.addRemoveTags} opened={opened} onClose={() => setOpened(false)}>
			<SegmentedControl data={[{ label: dict.addTags, value: "add" }, { label: dict.removeTags, value: "remove" }]}
				value={addRemove} onChange={setAddRemove} />

			{tags &&
				<MultiSelect mt="md" label={dict.tags} data={tags} onChange={setSelectedTags}
					multiple searchable creatable={addRemove == "add"} getCreateLabel={lbl => `+ ${dict.add} "${lbl}"`} />}

			<Group position="center" mt="xl">
				<Button leftIcon={<X size={20} />} variant="outline" onClick={() => setOpened(false)}>{dict.cancel}</Button>
				<Button leftIcon={<Check size={20} />} onClick={updateTags} loading={saving}>{addRemove == "add" ? dict.addTags : dict.removeTags}</Button>
			</Group>
		</Modal>
	);
}