import React, { useState } from "react";
import { Anchor, Button, Group, Modal, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DeviceFloppy } from "tabler-icons-react";
import { objectToFormData } from "utils/helpers";
import { fetchUrl, getUrl } from "utils/urlHelpers";
import Confirm from "components/Confirm";

export default function LeadCompanyReg({ dict, company, setCompany, opened, setOpened }) {
	const [saving, setSaving] = useState(null);
	const [emptyConfOpened, setEmptyConfOpened] = useState(null);
	const [mergeConfOpened, setMergeConfOpened] = useState(null);
	const [mergeCompany, setMergeCompany] = useState(null);

	const form = useForm({
		initialValues: { regNumber: "" },
	});

	const checkReg = values => {
		if (values.regNumber == "") {
			setEmptyConfOpened(true);
			return;
		}
		getUrl(`company/regNumber/${values.regNumber}?countryId=${company.countryId}`, comp => {
			if (!comp)
				saveRegNumber();
			else {
				setMergeCompany(comp);
				setMergeConfOpened(true);
			}
		});
	};

	const saveRegNumber = () => {
		fetchUrl(`company/${company.id}/regNumber`, "PUT", objectToFormData(form.values), setSaving, dict.regSaved, () => {
			const comp = {
				...company, ...{
					regNumber: form.values.regNumber, dateOpened: null, documents: [], status: null,
					size: null, previousSize: null, sizeMin: null, sizeMax: null, processed: false,
					matches: company.matches.filter(x => x.sourceType != 1 && x.sourceType != 2),
					contacts: company.contacts.filter(x => x.matches[0].sourceType != 1 && x.matches[0].sourceType != 2)
				}
			};
			setCompany(comp);
			setOpened(false);
		});
	};

	const merge = () => {
		// remove current reg then merge
		fetchUrl(`company/${company.id}/regNumber`, "PUT", objectToFormData({ regNumber: "" }), setSaving, null, () => {
			fetchUrl(`company/merge/${mergeCompany.id}/with/${company.id}`, null, null, setSaving, dict.merged, () => setOpened(false));
		});
	};

	const searchUrl = company?.countryId == 1
		? `https://find-and-update.company-information.service.gov.uk/search?q=${encodeURIComponent(company?.name)}`
		: `https://annuaire-entreprises.data.gouv.fr/rechercher?terme=${encodeURIComponent(company?.name)}`;

	return (
		<>
			<Modal opened={opened} onClose={() => setOpened(false)} title={dict.editReg} centered>
				<form onSubmit={form.onSubmit(values => checkReg(values))}>
					<TextInput label={dict.regNumber} {...form.getInputProps("regNumber")} maxLength={50} data-autofocus />
					{(company?.countryId == 1 || company?.countryId == 2) &&
						<Group position="right">
							<Anchor href={searchUrl} target="_blank">
								{dict.search}
							</Anchor>
						</Group>
					}
					<Group position="center" mt="xl">
						<Button type="submit" leftIcon={<DeviceFloppy size={20} />} loading={saving}>{dict.save}</Button>
					</Group>
				</form>
			</Modal>
			<Confirm opened={emptyConfOpened} setOpened={setEmptyConfOpened} yesLabel={dict.remove} yesAction={saveRegNumber}>{dict.removeRegConf}</Confirm>
			<Confirm opened={mergeConfOpened} setOpened={setMergeConfOpened} yesLabel={dict.merge} yesAction={merge}>
				{dict.mergeConf?.replace("[name]", mergeCompany?.name).replace("[town]", mergeCompany?.town).replace("[web]", mergeCompany?.website)}
			</Confirm>
		</>
	);
}