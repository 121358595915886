import React from "react";
import { showNotification } from "@mantine/notifications";
import { X, Check } from "tabler-icons-react";

export function getUrl(url, successCallback) {
	fetchUrl(url, "GET", null, null, null, successCallback);
}
export function fetchUrl(url, method, body, stateAction, successMessage, successCallback, errorMessage, errorCallback) {
	const truncate = (str, n) => {
		return (str?.length > n) ? str.substr(0, n - 1) + "..." : str;
	};
	const isJson = str => {
		try { JSON.parse(str); } catch (e) { return false; }
		return true;
	};

	const handleError = (err, status) => {
		stateAction && stateAction(false);
		errorCallback && errorCallback();

		// load language pack to show error message
		const lang = JSON.parse(localStorage.getItem("user"))?.lang || "en";
		import(`i18n/lang.${lang}.json`).then(dict => {
			if (status === 404 && !(errorMessage?.length > 0)) errorMessage = dict.fetch.notFound;
			if (status === 401 || status === 403) errorMessage = dict.fetch.forbidden;
			errorMessage = errorMessage || dict.fetch.unexpectedError;

			const jsonErr = isJson(err?.message) ? JSON.parse(err.message) : (isJson(err) ? JSON.parse(err) : null);
			showNotification({
				icon: <X size={18} />, color: "red", title: errorMessage ? "" : "Error",
				message: errorMessage
					? `${errorMessage} ${(truncate(err?.message, 200) || "")}`
					: jsonErr?.title
			});
		});
	};
	const handleSuccess = data => {
		stateAction && stateAction(false);
		successCallback && successCallback(data);
		if (successMessage)
			showNotification({
				icon: <Check size={18} />, color: "teal", message: successMessage
			});
	};

	stateAction && stateAction(true);

	let headers = new Headers({ Authorization: `Bearer ${localStorage.getItem("token")}` });
	if (isJson(body)) headers.append("Content-Type", "application/json");
	const roadLeadToken = JSON.parse(sessionStorage.getItem("roadLeadLogged"))?.token;
	if (roadLeadToken) headers.append("userToken", roadLeadToken);

	fetch(`${process.env.REACT_APP_API_URL}${url}`, {
		method: method || "GET", body: body || undefined, headers: headers
	}).then(res => {
		return res.ok
			? (res.status == 204 // empty result
				? handleSuccess()
				: res.json().then(data => handleSuccess(data)))
			: res.text().then(text => handleError(text, res.status));
	}).catch(err => {
		console.log(err);
		handleError();
	});
}