import React, { useState } from "react";
import { createStyles, Grid, Text, Group, Divider, Stack, ActionIcon, Tooltip } from "@mantine/core";
import { useDictionary } from "i18n/dictionary";
import { formatDate } from "utils/helpers";
import LeadSection from "../LeadSection";
import { X, Check, Edit } from "tabler-icons-react";
import LeadCompanyAddress from "./LeadCompanyAddress";
import LeadCompanyRoadLead from "./LeadCompanyRoadLead";
import LeadCompanyReg from "./LeadCompanyReg";
import LeadCompanySize from "./LeadCompanySize";
import LeadCompanyHierarchy from "./LeadCompanyHierarchy";

const useStyles = createStyles((theme) => ({
	check: { strokeWidth: 4, color: theme.colors.green[6], width: 20 },
	cross: { strokeWidth: 4, color: theme.colors.red[7], width: 20 },
}));

export default function Company({ company, setCompany, label, height }) {
	const { classes } = useStyles();
	const dict = useDictionary("leadCompany");
	const [editRegOpened, setEditRegOpened] = useState(null);

	const content = company == null ? null :
		<Grid gutter={6}>
			<Grid.Col md={4}>
				<Group>
					<Stack spacing={0}>
						<Text weight={500} size="sm">{dict.reg}</Text>
						<Text size="sm">{company.regNumber || "N/A"}</Text>
					</Stack>
					<Tooltip label={dict.editReg} openDelay={500}>
						<ActionIcon variant="outline" color="blue" onClick={() => setEditRegOpened(true)}>
							<Edit size={18} />
						</ActionIcon>
					</Tooltip>
				</Group>
			</Grid.Col>
			<Grid.Col md={4}>
				{company.dateOpened &&
					<><Text weight={500} size="sm">{dict.dateCreated}</Text>
						<Text size="sm">{formatDate(company.dateOpened)}</Text>
					</>
				}
			</Grid.Col>
			<Grid.Col md={1}>
				{company.regNumber && <LeadCompanyHierarchy dict={dict} regNumber={company.regNumber} />}
			</Grid.Col>
			<Grid.Col md={3}>
				{company.status != null && company.status != undefined &&
					<Group spacing={5} position="right">
						{company.status === 1 ? <Check className={classes.check} /> : <X className={classes.cross} />}
						<Text weight={500} size="sm">{dict[`status${company.status}`]}</Text>
					</Group>
				}
			</Grid.Col>
			<Grid.Col md={12}><Divider /></Grid.Col>
			<Grid.Col md={6}>
				<LeadCompanyAddress dict={dict} company={company} setCompany={setCompany} />
			</Grid.Col>
			<Grid.Col md={6}>
				<LeadCompanySize dict={dict} company={company} />
			</Grid.Col>
			{company?.roadLead &&
				<Grid.Col mt={8}>
					<LeadCompanyRoadLead dict={dict} company={company} />
				</Grid.Col>
			}
		</Grid>;

	return (
		<>
			<LeadSection label={label} maxHeight={height - 55} minHeight={height}>{content}</LeadSection>
			<LeadCompanyReg dict={dict} company={company} setCompany={setCompany} opened={editRegOpened} setOpened={setEditRegOpened} />
		</>
	);
}