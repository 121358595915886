import React from "react";
import { createStyles, Table, Center, Anchor, Skeleton, Tooltip, Loader } from "@mantine/core";
import { BrandLinkedin, DeviceDesktopAnalytics } from "tabler-icons-react";
import { useNavigate, Outlet } from "react-router-dom";
import LeadsTableScore from "./LeadsTableScore";
import LeadsTableSize from "./LeadsTableSize";

const useStyles = createStyles(theme => ({
	row: { cursor: "pointer" },
	rowInactive: {
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2],
		cursor: "pointer", fontStyle: "italic"
	},
	icon: { color: theme.colorScheme === "dark" ? theme.colors.gray[5] : theme.colors.dark[7] },
	regNumber: {
		color: theme.colorScheme === "dark" ? theme.colors.gray[7] : theme.colors.gray[6]
	},
}));

export default function LeadsTable({ leads, dict }) {
	const { classes } = useStyles();
	const navigate = useNavigate();

	const openLead = (e, lead) => {
		if (e.target.tagName != "svg")
			navigate(`/leads/${lead.companyId}`);
	};
	const formatLastTouched = d => {
		let month = d == null ? null : (new Date(d) - new Date()) / 2592000000;
		return month == null ? dict.never : (month > -3 ? `0-3 ${dict.months}` : (month > -6 ? `3-6 ${dict.months}` : (month > -9 ? `6-9 ${dict.months}` : (month > -12 ? `9-12 ${dict.months}` : `12+ ${dict.months}`))));
	};

	const placeHolderRow = Array.from({ length: 9 }, (v, i) => <td key={i}><Skeleton height={42} /></td>);
	const placeHolder = Array.from({ length: 10 }, (v, i) => <tr key={i}>{placeHolderRow}</tr>);

	const rows = leads?.map(lead => (
		<tr key={lead.companyId} className={lead.companyStatus == 1 ? classes.row : classes.rowInactive} onClick={e => openLead(e, lead)}>
			<td>
				<b>{lead.name}</b>
				<div className={classes.regNumber}>{lead.regNumber}</div>
			</td>
			<td>{lead.dateOpened ? new Date(lead.dateOpened).getFullYear() : ""}</td>
			<td>{lead.town}</td>
			<td><LeadsTableSize lead={lead} /></td>
			<td><LeadsTableScore lead={lead} /></td>
			<td>{lead.bestIndustry}</td>
			<td>{lead.leadStatus}</td>
			<td>{formatLastTouched(lead.lastTouched)}</td>
			<td>
				{lead.processed ? "" : <Tooltip label={dict.processing}><span><Loader size="sm" /></span></Tooltip>}
				{lead.website ? <Tooltip label={dict.viewWebsite} openDelay={500}><Anchor href={lead.website} className={classes.icon} target="_blank"><DeviceDesktopAnalytics /></Anchor></Tooltip> : ""}
				{lead.linkedInId ? <Tooltip label={dict.viewLinkedIn} openDelay={500}><Anchor href={"https://www.linkedin.com/company/" + lead.linkedInId} className={classes.icon} target="_blank"><BrandLinkedin /></Anchor></Tooltip> : ""}
			</td>
		</tr>
	));

	return (
		<>
			<Table highlightOnHover>
				<thead>
					<tr>
						<th>{dict.name}</th>
						<th>{dict.created}</th>
						<th>{dict.town}</th>
						<th><Center>{dict.size}</Center></th>
						<th><Center>{dict.bestScore}</Center></th>
						<th>{dict.bestIndustry}</th>
						<th>{dict.status}</th>
						<th>{dict.lastTouched}</th>
						<th></th>
					</tr>
				</thead>
				<tbody>{leads === null ? placeHolder : rows}</tbody>
			</Table>
			<Outlet />
		</>
	);
}