import React from "react";
import { Text } from "@mantine/core";
import LeadSection from "./LeadSection";

export default function LeadExtra({ label, height, lead }) {
	const content = !lead
		? null
		: lead.extras?.map(extra => (
			<Text key={extra.id}>
				{extra.label}: <b>{extra.value}</b>
			</Text>
		)) || "";

	return (
		<LeadSection label={label} maxHeight={height - 55} minHeight={height}>{content}</LeadSection>
	);
}