import React, { useState, useEffect } from "react";
import { Badge, ActionIcon } from "@mantine/core";
import { X } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";

export default function LeadsActiveFilters(p) {
	const dict = useDictionary();
	const [filters, setFilters] = useState([]);

	useEffect(() => {
		if (p.lastChangedFilter == null) return;

		let newFilters = [...filters];
		let i = filters.findIndex(x => x.caption == p.lastChangedFilter.caption);

		if (i == -1 && p.lastChangedFilter.value != undefined)
			newFilters.push(p.lastChangedFilter);
		if (i != -1 && JSON.stringify(filters[i].data) != JSON.stringify(p.lastChangedFilter.data)) {
			if (p.lastChangedFilter.data !== undefined && p.lastChangedFilter.data !== null && p.lastChangedFilter.data !== ""
				&& (p.lastChangedFilter.data?.constructor !== Array || p.lastChangedFilter.data.length > 0))
				newFilters[i] = p.lastChangedFilter;
			else
				newFilters.splice(i, 1);
		}
		setFilters(newFilters);

	}, [p.lastChangedFilter]);

	const removeFilter = filter => {
		filter.setFunction(null);	// will set the filter to null in LeadsFilter.js
		setFilters(filters.filter(x => { return x.caption != filter.caption; })); // removes the badge
	};
	const getFilterValue = data => {
		return data?.constructor === Array
			? data.map(x => typeof x == "string" ? x : x.label).join(", ")
			: (data?.label || data);
	};

	const content = filters?.map(filter =>
		<Badge key={filter.caption} sx={{ paddingRight: 3 }}
			rightSection={<ActionIcon size="xs" color="blue" onClick={() => removeFilter(filter)}><X size={12} /></ActionIcon>}>
			{filter.caption}{dict.semiColon}{getFilterValue(filter.data)}
		</Badge>
	);
	return (
		<>
			{content}
		</>
	);
}