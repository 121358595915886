import React, { useState, useEffect } from "react";
import { Accordion, Group, FileButton, Button, Image } from "@mantine/core";
import { Camera, ArrowBigRight, Trash } from "tabler-icons-react";
import { fetchUrl } from "utils/urlHelpers";
import { objectToFormData } from "utils/helpers";

export default function RoadLeadFormPhoto({ form, photo, setPhoto, setStep, ocr, setOcr }) {
	const [preview, setPreview] = useState(null);
	const [extractingText, setExtractingText] = useState(false);

	useEffect(() => {
		if (!photo) return;
		const objectUrl = URL.createObjectURL(photo);
		setPreview(objectUrl);
		setOcr(null);
		return () => URL.revokeObjectURL(objectUrl); // free memory whenever this component is unmounted
	}, [photo]);
	const removePhoto = () => {
		setPreview(null);
		setPhoto(null);
	};
	const validatePhoto = () => {
		if (photo && !ocr)
			fetchUrl("roadLead/ocr", "POST", objectToFormData({ photo: photo }), setExtractingText, null, data => {
				form.setValues({ photoDate: data.date, photoLatitude: data.latitude, photoLongitude: data.longitude });
				setOcr(data.ocr);
				setStep("2");
			});
		else
			setStep("2");
	};

	return (
		<Accordion.Item value="1">
			<Accordion.Control>1. Photo</Accordion.Control>
			<Accordion.Panel>
				<Group position="center">
					{photo
						? <Image radius="md" src={preview} style={{ maxWidth: "75%" }} />
						: <FileButton leftIcon={<Camera size={20} />} onChange={setPhoto} accept="image/png,image/jpeg">
							{(props) => <Button {...props}>Take a photo</Button>}
						</FileButton>
					}
				</Group>
				<Group position="apart" mt="sm">
					{photo && <Button leftIcon={<Trash size={20} />} color="red" variant="outline" onClick={removePhoto}>Remove</Button>}
					<div />
					<Button leftIcon={<ArrowBigRight size={20} />} onClick={validatePhoto} loading={extractingText}>Next</Button>
				</Group>
			</Accordion.Panel>
		</Accordion.Item>
	);
}