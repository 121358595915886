import React, { useState, useEffect } from "react";
import { MultiSelect, Loader, Select } from "@mantine/core";
import { useDictionary } from "i18n/dictionary";
import { getUrl } from "utils/urlHelpers";

export default function LazyDropDown(p) {
	const dict = useDictionary();
	const [data, setData] = useState(null);
	const [openedOnce, setOpenedOnce] = useState(false);

	useEffect(() => {
		if (data == null && (openedOnce || p.value?.length > 0 && !p.initialData)) {
			const onSuccess = json => {

				let data = json.length > 0 && typeof json[0] === "string"
					? json
					: json.map(item => ({ value: item[p.propValue || "id"] + "", label: item[p.propLabel || "name"] }));

				if (p.dictionary) data.forEach(x => x.label = p.dictionary[x.label]); // translate if required
				setData(data);
			};

			getUrl(p.path, onSuccess);
		}
	}, [openedOnce, p.value]);

	const param = {
		...p, nothingFound: data && dict.nothingFound,
		onDropdownOpen: () => setOpenedOnce(true), initialData: undefined,
		rightSection: !data && openedOnce && <Loader size="xs" />
	};

	const onChangeMultiSelect = value => {
		if (value == "--loading--") return;
		const val = value && value.map(x => !isNaN(x) && +x || x);
		const dat = data.filter(x => value.includes(typeof x === "string" ? x : x.value));
		p.onChange(val, dat);
	};
	const onChangeSelect = value => {
		const val = value && (!isNaN(value) && +value || value);
		const dat = data.find(x => value?.includes(x.value));
		p.onChange(val, dat);
	};

	return (
		p.multiple === true
			? <MultiSelect {...param} data={data || [{ id: "--loading--", label: "" }]} searchable onChange={onChangeMultiSelect} />
			: <Select {...param} data={data || p.initialData || []} onChange={onChangeSelect} />
	);
}