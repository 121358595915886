import React, { useEffect, useState } from "react";
import { Group, Container, Text, TextInput, ScrollArea, Table, Skeleton, useMantineTheme } from "@mantine/core";
import { getUrl } from "utils/urlHelpers";
import ContactPositionNewScore from "./ContactPositionNewScore";

export default function ContactPositionWords({ dict, scoredPositions, setScoredPositions }) {
	const theme = useMantineTheme();
	const [positions, setPositions] = useState(null);
	const [positionFilter, setPositionFilter] = useState("");
	const [scoredPosFilter, setScoredPosFilter] = useState("");
	const [newPositionScore, setNewPositionScore] = useState(null);

	useEffect(() => {
		if (scoredPositions && !positions)
			getUrl("contact/position", (pos) => { colorPositions(pos, scoredPositions); });
	}, [scoredPositions]);

	const savePositionScore = (scoring) => {
		let words = scoredPositions.filter(pos => pos.word.replace(/%/g, "") !== scoring.word.replace(/%/g, ""));
		if (scoring.score > 0) words.push(scoring);
		words = words.sort((a, b) => { return b.score - a.score; });
		setScoredPositions(words);
		colorPositions(positions, words);
	};
	const colorPositions = (pos, sco) => {
		const scoredWords = sco.map(x => x.word);
		const scoredPartialWords = scoredWords.filter(x => x.indexOf("%") != -1).map(x => x.replace(/%/g, ""));
		const colorIndex = theme.colorScheme === "dark" ? 9 : 3;
		for (let i in pos)
			pos[i].color = scoredWords.includes(pos[i].position) ? theme.colors.green[colorIndex] // "#81C995"
				: (scoredPartialWords.find(x => pos[i].position.indexOf(x) != -1) ? theme.colors.yellow[colorIndex] : undefined);

		setPositions(pos);
	};
	const positionRows = positions?.filter(x => !positionFilter || x.position.toLowerCase().indexOf(positionFilter) != -1)?.map(x =>
		<tr key={x.position} style={{ backgroundColor: x.color, cursor: !x.color ? "pointer" : undefined }}
			onClick={() => !x.color && setNewPositionScore({ word: x.position })}>
			<td><Text color={x.color || theme.colorScheme === "light" ? theme.colors.dark[7] : theme.colors.gray[3]}>{x.position}</Text></td>
			<td><Text color={x.color || theme.colorScheme === "light" ? theme.colors.dark[7] : theme.colors.gray[3]}>{x.occurrence}</Text></td>
		</tr>
	);
	const scoredPositionRows = scoredPositions?.filter(x => !scoredPosFilter || x.word.toLowerCase().indexOf(scoredPosFilter) != -1)?.map(x =>
		<tr key={x.word} style={{ cursor: "pointer" }} onClick={() => setNewPositionScore(x)}>
			<td>{x.word}</td>
			<td>{x.score}</td>
		</tr>
	);
	const placeHolderRow = Array.from({ length: 9 }, (v, i) => <td key={i}><Skeleton height={30} width={120} /></td>);
	const placeHolder = Array.from({ length: 10 }, (v, i) => <tr key={i}>{placeHolderRow}</tr>);

	return (
		<>
			<Group spacing="xl">
				<Container size={370}>
					<Text weight={500} size="sm">{dict.topPositions}</Text>
					<TextInput size="xs" placeholder={dict.filter} value={positionFilter} onChange={e => setPositionFilter(e.currentTarget.value)} />
					<ScrollArea style={{ height: 500 }} type="auto" pr={10}>
						<Table verticalSpacing={1}>
							<thead><tr><th>{dict.position}</th><th>{dict.occurrence}</th></tr></thead>
							<tbody>{positions && positionRows || placeHolder}</tbody>
						</Table>
					</ScrollArea>
				</Container>
				<Container size={370}>
					<Text weight={500} size="sm">{dict.scoredPositions}</Text>
					<TextInput size="xs" placeholder={dict.filter} value={scoredPosFilter} onChange={e => setScoredPosFilter(e.currentTarget.value)} />
					<ScrollArea style={{ height: 500 }} type="auto" pr={10}>
						<Table verticalSpacing={1}>
							<thead><tr><th>{dict.position}</th><th>{dict.points}</th></tr></thead>
							<tbody>{scoredPositionRows}</tbody>
						</Table>
					</ScrollArea>
				</Container>
			</Group>
			<ContactPositionNewScore dict={dict} position={newPositionScore} setPosition={setNewPositionScore} saveScoring={savePositionScore} />
		</>
	);
}