import React, { useEffect, useState } from "react";
import { ActionIcon, Group, Image, Modal, Paper, Stack, Text, Tooltip } from "@mantine/core";
import { Eye, Photo } from "tabler-icons-react";
import dayjs from "dayjs";
import { getUrl } from "utils/urlHelpers";

export default function LeadCompanyRoadLead({ dict, company }) {
	const [photoUrl, setPhotoUrl] = useState(null);
	const [photoVisible, setPhotoVisible] = useState(null);

	useEffect(() => {
		if (photoUrl) setPhotoVisible(true);
	}, [photoUrl]);

	const showRoadLeadPhoto = () => {
		if (!photoUrl)
			getUrl(`roadLead/${company.roadLead.id}/photo`, photo => setPhotoUrl(photo.url));
		else
			setPhotoVisible(true);
	};

	return (
		<>
			<Paper>
				<Group position="apart">
					<Group>
						<Eye />
						<Stack spacing={0}>
							<Text size="sm">{dict.spottedOn} {dayjs(company.roadLead.dateSpotted).format("DD/MM/YYYY HH:mm")}</Text>
							<Text size="sm">
								{company.roadLead.address} {company.roadLead.town} {company.roadLead.postcode}
							</Text>
						</Stack>
					</Group>
					<Group>
						{company.roadLead.hasPhoto &&
							<Tooltip label={dict.viewPhoto} openDelay={500}>
								<ActionIcon color="blue" mr="md" onClick={showRoadLeadPhoto}>
									<Photo size={28} />
								</ActionIcon>
							</Tooltip>
						}
					</Group>
				</Group>
			</Paper>
			<Modal opened={photoVisible} centered withCloseButton={false} size="50%" overflow="inside" onClose={() => setPhotoVisible(false)}>
				<Image radius="md" src={photoUrl} withPlaceholder />
			</Modal>
		</>
	);
}