import React, { useEffect, useState } from "react";
import { Button, Stack, Tabs } from "@mantine/core";
import { fetchUrl, getUrl } from "utils/urlHelpers";
import { useDictionary } from "i18n/dictionary";
import ContactPositionDetail from "./ContactPositionDetail";
import { Plus } from "tabler-icons-react";
import { objectToFormData } from "utils/helpers";

export default function ContactPosition() {
	const dict = useDictionary("positions");
	const [allScoring, setAllScoring] = useState([]);
	const [currentScoringId, setCurrentScoringId] = useState(null);
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(null);

	useEffect(() => {
		getUrl("ContactScoring", data => {
			data = data.sort((a, b) => a.companySizeMin - b.companySizeMin);
			setAllScoring(data);
			if (data.length) setCurrentScoringId(data[0].id + "");
		});
	}, []);

	const saveScoring = scoring => {
		fetchUrl(`ContactScoring/${scoring.id || ""}`, scoring.id ? "PUT" : "POST", objectToFormData(scoring), setSaving, dict.scoringSaved, data => {
			const newScoring = scoring.id
				? allScoring.map(x => x.id == data.id ? data : x)
				: [...allScoring, data].filter(x => x.id !== undefined);
			setAllScoring(newScoring.sort((a, b) => a.companySizeMin - b.companySizeMin));
			setCurrentScoringId(data.id + "");
		});
	};
	const deleteScoring = () => {
		const afterDelete = id => {
			const newScoring = allScoring.filter(x => x.id !== id);
			setAllScoring(newScoring);
			setCurrentScoringId(newScoring.length ? newScoring[0].id + "" : null);
			window.scrollTo(0, 0);
		};
		if (currentScoringId == "0")
			afterDelete();
		else
			fetchUrl(`ContactScoring/${currentScoringId}`, "DELETE", null, setDeleting, null, () => { afterDelete(+currentScoringId); });
	};

	const addScoring = () => {
		setAllScoring([...allScoring, { definition: "[]" }]);
		setCurrentScoringId("0");
	};

	const duplicateScoring = () => {
		const scoring = allScoring.find(x => x.id == currentScoringId);
		setAllScoring([...allScoring, { ...scoring, id: undefined }]);
		setCurrentScoringId("0");
		window.scrollTo(0, 0);
	};

	const getTabLabel = (id, min, max) => {
		if (!id) return dict.newScoring;
		if (!min && !max) return dict.default;
		if (!min) return `${dict.upTo} ${max}`;
		if (!max) return `${min} ${dict.over}`;
		return `${min} - ${max}`;
	};

	return (
		<Stack align="center" spacing="xl" sx={{ maxWidth: 800, margin: "0 auto" }}>
			<Button compact leftIcon={<Plus size={18} />} onClick={addScoring} disabled={allScoring.some(x => x.id == undefined)}>{dict.newScoring}</Button>
			{allScoring.length
				? <Tabs value={currentScoringId} onTabChange={setCurrentScoringId}>
					<Tabs.List>
						{allScoring.map(x => (
							<Tabs.Tab key={x.id || 0} value={x.id ? x.id + "" : "0"}>{getTabLabel(x.id, x.companySizeMin, x.companySizeMax)}</Tabs.Tab>
						))}
					</Tabs.List>
					{allScoring.map(x => (
						<Tabs.Panel key={x.id || 0} value={x.id ? x.id + "" : "0"} pt="xs">
							<ContactPositionDetail dict={dict} scoring={x} saveScoring={saveScoring} saving={saving}
								deleteScoring={deleteScoring} deleting={deleting} duplicateScoring={duplicateScoring} />
						</Tabs.Panel>
					))}
				</Tabs >
				: <></>
			}
		</Stack>
	);
}