import React, { useEffect, useState } from "react";
import { Button, Group, Modal, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Check, DeviceFloppy, Trash, X } from "tabler-icons-react";
import Confirm from "components/Confirm";
import { isEmail, isPhone, objectToFormData } from "utils/helpers";
import { fetchUrl, getUrl } from "utils/urlHelpers";
import LeadContactMatches from "./LeadContactMatches";
import { showNotification } from "@mantine/notifications";
import Tags from "pages/Tags";

export default function LeadContactEdit({ dict, contactOpened, setContactOpened, setUpdatedContact, setDeletedContact, getMatchedSource }) {
	const [saving, setSaving] = useState(null);
	const [deleting, setDeleting] = useState(null);
	const [deleteConfOpened, setDeleteConfOpened] = useState(false);
	const [tags, setTags] = useState(null);
	
	useEffect(() => {
		if (contact.id)
			getUrl(`contact/${contact.id}/tag`, setTags);
	}, []);

	let contact = JSON.parse(JSON.stringify(contactOpened));

	const form = useForm({
		initialValues: {
			id: contact.id, firstName: contact.firstName || "", lastName: contact.lastName || "",
			position: contact.position || ""
		},
		validate: {},
	});
	const deleteContact = () => {
		fetchUrl(`contact/${contact.id}`, "DELETE", null, setDeleting, dict.contactDeleted, () => setDeletedContact(contact.id));
	};
	const setContactMatches = matches => {
		contact.matches = matches;
	};
	const validateMatches = () => {
		if (contact.matches.some(x =>
			x.email && !isEmail(x.email) || x.phone && !isPhone(x.phone) || x.id == 0 && !x.email && !x.phone
		)) {
			showNotification({ icon: <X size={18} />, color: "red", message: dict.invalidPhoneOrEmail });
			return false;
		}
		return true;
	};
	const saveContact = values => {
		if (!validateMatches()) return;
		let formData = objectToFormData({ ...contact, ...values });
		fetchUrl(`contact/${values.id || ""}`, values.id ? "PUT" : "POST", formData, setSaving, null, savedContact => saveMatches(savedContact));
	};
	const saveMatches = savedContact => {
		let promises = contact.matches.map(match => new Promise((ok, ko) => {
			const method = match.deleted ? "DELETE" : (match.id == 0 ? "POST" : "PUT");
			const data = objectToFormData({ ...match, contactId: savedContact.id, customerId: savedContact.customerId });
			fetchUrl(`contactMatch/${match.id || ""}`, method, data, setSaving, null, ok, null, ko);
		}));
		Promise.all(promises).then(() => {
			showNotification({ icon: <Check size={18} />, color: "teal", message: dict.contactSaved });
			contact.matches = contact.matches.filter(x => !x.deleted);
			setUpdatedContact({ ...contact, ...savedContact });
		});
	};


	const popupTitle = contact.id
		? <Group position="apart">
			{contact.firstName || ""} {contact.lastName || ""}
			{tags && <Tags entityType={2} entityName="contact" entityId={contact.id} entityTags={tags} />}

		</Group>
		: dict.newContact;

	return (
		<Modal opened={contactOpened != null} onClose={() => setContactOpened(null)} size={600} title={popupTitle}>
			<form onSubmit={form.onSubmit(values => saveContact(values))}>
				<Group grow>
					<TextInput label={dict.firstName} {...form.getInputProps("firstName")} maxLength={50}></TextInput>
					<TextInput label={dict.lastName} {...form.getInputProps("lastName")} maxLength={50} required></TextInput>
				</Group>
				<Group grow>
					<TextInput label={dict.position} {...form.getInputProps("position")} maxLength={60}></TextInput>
					<div></div>
				</Group>
				<LeadContactMatches dict={dict} contactMatches={contact.matches} setContactMatches={setContactMatches} getMatchedSource={getMatchedSource} />
				<Group position="center" mt="xl">
					{contact.id &&
						<Button leftIcon={<Trash size={20} />} color="red" variant="outline" loading={deleting} onClick={() => setDeleteConfOpened(true)}>
							{dict.delete}
						</Button>
					}
					<Button type="submit" leftIcon={<DeviceFloppy size={20} />} loading={saving}>{dict.save}</Button>
				</Group>
			</form>
			<Confirm opened={deleteConfOpened} setOpened={setDeleteConfOpened} yesLabel={dict.delete} yesAction={deleteContact}>{dict.contactDelConf}</Confirm>
		</Modal>
	);
}