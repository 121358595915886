import React, { useEffect, useState } from "react";
import { Skeleton, Table, Text } from "@mantine/core";
import { getUrl } from "utils/urlHelpers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export default function ExportTable({ dict }) {
	const [exports, setExports] = useState(null);

	useEffect(() => {
		getUrl("export", setExports);
	}, []);

	const getType = type => {
		switch (type) {
			case 1: return dict.leads;
			case 2: return dict.contacts;
			case 3: return dict.webAddress;
		}
	};
	const timeZone = JSON.parse(localStorage.getItem("user")).tz;

	const rows = exports?.map(x => (
		<tr key={x.id}>
			<td>{dayjs(x.dateInserted+"Z").tz(timeZone).format("DD/MM/YYYY HH:mm")}</td>
			<td>{x.recipient}</td>
			<td>{getType(x.type)}</td>
		</tr>
	));
	const placeHolderRow = Array.from({ length: 3 }, (v, i) => <td key={i}><Skeleton height={42} /></td>);
	const placeHolder = Array.from({ length: 10 }, (v, i) => <tr key={i}>{placeHolderRow}</tr>);

	return (
		exports?.length == 0
			? <Text ml={100} my={50}>{dict.nothing}</Text>
			: <Table sx={{ width: 700 }}>
				<thead>
					<tr>
						<th>{dict.date}</th>
						<th>{dict.recipient}</th>
						<th>{dict.type}</th>
					</tr>
				</thead>
				<tbody>{rows || placeHolder}</tbody>
			</Table>
	);
}