import React, { useEffect, useState } from "react";
import { Paper, Tabs } from "@mantine/core";
import { Diamond, Search } from "tabler-icons-react";
import { fetchUrl } from "utils/urlHelpers";
import TableDiamond from "./TableDiamond";
import TableFilter from "./TableFilter";
import TableCognism from "./TableCognism";

export default function TableSearch() {
	const [loading, setLoading] = useState(null);
	const [diamondResult, setDiamondResult] = useState(null);
	const [cognismResult, setCognismResult] = useState(null);
	const [activeTab, setActiveTab] = useState("diamond");
	const [filter, setFilter] = useState(null);

	useEffect(() => {
		if (filter && activeTab == "diamond" && !diamondResult)
			searchDiamond();
		if (filter && activeTab == "cognism" && !cognismResult)
			searchCognism();
	}, [activeTab]);

	useEffect(() => {
		setDiamondResult(null);
		setCognismResult(null);
		if (filter && activeTab == "diamond")
			searchDiamond();
		if (filter && activeTab == "cognism")
			searchCognism();
	}, [filter]);

	const searchDiamond = () => {
		fetchUrl("lead/search/bigchange", "POST", JSON.stringify(filter), setLoading, null, setDiamondResult);
	};

	const searchCognism = () => {
		fetchUrl("providerSearch/cognism/company", "POST", JSON.stringify(filter), setLoading, null, setCognismResult);
	};

	return (
		<Paper shadow="xl" p="xl" withBorder sx={{ maxWidth: 850 }}>
			<TableFilter setFilter={setFilter} loading={loading} />
			{(diamondResult || cognismResult) &&
				<Tabs value={activeTab} onTabChange={setActiveTab}>
					<Tabs.List mt="lg" mb="sm">
						<Tabs.Tab value="diamond" icon={<Diamond size={18} />}>Diamond</Tabs.Tab>
						<Tabs.Tab value="cognism" icon={<Search size={18} />}>Cognism</Tabs.Tab>
					</Tabs.List>
					<Tabs.Panel value="diamond">
						{diamondResult && <TableDiamond leads={diamondResult?.leads} />}
					</Tabs.Panel>
					<Tabs.Panel value="cognism">
						{cognismResult && <TableCognism companies={cognismResult} />}
					</Tabs.Panel>
				</Tabs>
			}
		</Paper>
	);
}