import React, { useEffect } from "react";
import { Stack, Text, Select } from "@mantine/core";

export function ImportMapping({ dict, importType, colDef, setColDef, setColDefOk, uploadedData, processType }) {
	useEffect(() => {
		setColDefOk(requiredFieldsOk(colDef));
	}, []);
	
	const contactFields = [
		{ value: "CompanyId", label: `${dict.lead} Id`, required: true },
		{ value: "ContactFirstName", label: dict.firstName },
		{ value: "ContactLastName", label: dict.lastName, required: true },
		{ value: "ContactEmail", label: dict.email, required: true },
		{ value: "ContactPhone", label: dict.phone },
		{ value: "ContactPosition", label: dict.position }
	];
	const tagFields = [
		{ value: "CompanyId", label: `${dict.lead} Id`, required: true },
		{ value: "TagName", label: dict.tag, required: true }
	];
	const leadFields = [
		{ value: "RegNumber", label: dict.reg, required: "processType6" },
		{ value: "Name", label: dict.name, required: true },
		{ value: "Website", label: dict.website, required: "processType1" },
		{ value: "Address1", label: `${dict.address} 1` },
		{ value: "Address2", label: `${dict.address} 2` },
		{ value: "Postcode", label: dict.postcode },
		{ value: "Town", label: dict.town },
		{ value: "Phone", label: dict.phone },
		{ value: "Email", label: dict.email },
		{ value: "Size", label: dict.size },
	];
	const contactDelFields = [
		{ value: "ContactEmail", label: dict.email, required: true },
	];
	
	const fields = {
		"1": leadFields,
		"2": contactFields,
		"3": tagFields,
		"4": contactDelFields
	}[importType];

	const fieldPicked = (field, col) => {
		let newDef = { ...colDef };
		for (let k in newDef)
			if (newDef[k] == col) delete newDef[k];
		if (field) newDef[field] = col;
		setColDef(newDef);
		setColDefOk(requiredFieldsOk(newDef));
	};

	const requiredFieldsOk = def => {
		for (let i in fields)
			if (def[fields[i].value] == undefined &&
				(fields[i].required === true
					|| fields[i].required == "processType1" && processType & 1
					|| fields[i].required == "processType6" && processType & 6
				))
				return false;
		return true;
	};

	const rows = uploadedData[0].map((field, col) =>
		<tr key={col}>
			<td>{field}</td>
			<td>
				<Select clearable onChange={field => fieldPicked(field, col)}
					value={Object.keys(colDef).find(k => colDef[k] === col) || ""}
					data={fields.filter(x => colDef[x.value] == undefined || colDef[x.value] == col)}
				/>
			</td>
		</tr>
	);

	return (
		<Stack align="center">
			<Text weight="600">{dict.step3Desc}</Text>
			<table>
				<tbody>{rows}</tbody>
			</table>
		</Stack>
	);


}