import { createStyles } from "@mantine/core";

export const AppMenuStyles = createStyles((theme, _params, getRef) => {
	const icon = getRef("icon");

	return {
		navbar: {
			backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : "#153548",
		},
		link: {
			...theme.fn.focusStyles(),
			display: "flex",
			alignItems: "center",
			textDecoration: "none",
			color: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.white,
			padding: `6px ${theme.spacing.sm}px`,
			borderRadius: theme.radius.sm,
			fontWeight: 500,
			"&:hover": {
				backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : "#20516e",
				color: theme.white,
				textDecoration: "none",
				[`& .${icon}`]: {
					color: theme.white,
				},
			},
		},
		parentLinkButton: {
			paddingTop: 8, paddingBottom: 8,
			"&:hover": {
				backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : "#20516e",
				color: theme.white,
			},
			["&:hover div, &:hover svg"]: {
				color: theme.white,
			}
		},
		parentLink: {
			color: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.white,
			fontWeight: 500
		},
		linkIcon: {
			ref: icon, 
			color: theme.colorScheme === "dark" ? theme.colors.dark[2] : "#9eb2bd",
			strokeWidth: 1, width:28, height: 28,
			marginRight: theme.spacing.sm,
		},
		parentIcon: {
			color: theme.colorScheme === "dark" ? theme.colors.dark[2] : "#9eb2bd",
			strokeWidth: 1, width:32, height: 32,
			marginLeft: -4
		},
		linkActive: {
			"&, &:hover": {
				backgroundColor:
					theme.colorScheme === "dark"
						? theme.fn.rgba(theme.colors[theme.primaryColor][8], 0.25)
						: theme.colors.blue[7],
				color: theme.white,
				[`& .${icon}`]: {
					color: theme.white
				},
			},
		},
		divider: {
			borderTopColor: theme.colorScheme === "dark" ? theme.colors.dark[2] : "#9eb2bd",
		}
	};
});