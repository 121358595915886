import React, { useState } from "react";
import { Modal, Stack, Text, NumberInput, Group, Button } from "@mantine/core";
import { fetchUrl } from "utils/urlHelpers";
import { X, Check } from "tabler-icons-react";

export default function LeadsActionFetchContact({ dict, opened, setOpened, rowCount, filters }) {
	const [contactCount, setContactCount] = useState(2);
	const [minScore, setMinScore] = useState(50);

	const fetchContacts = () => {
		const url = `leadProcessQueue/fetchContacts?contactCount=${contactCount}&minScore=${minScore}`;
		fetchUrl(url, "POST", JSON.stringify(filters), null, dict.buyContactSent, () => setOpened(false));
	};

	return (
		<Modal centered title={dict.buyContact} opened={opened} onClose={() => setOpened(false)}>
			<Stack>
				<Text>{dict.buyContactConf?.replace("{count}", rowCount)}</Text>
				<NumberInput value={contactCount} label={dict.buyContactCount} onChange={setContactCount} />
				<NumberInput value={minScore} label={dict.buyMinScore} onChange={setMinScore} />
				<Group position="center" mt="sm">
					<Button leftIcon={<X size={20} />} variant="outline" onClick={() => setOpened(false)}>{dict.cancel}</Button>
					<Button leftIcon={<Check size={20} />} onClick={fetchContacts}>{dict.buy}</Button>
				</Group>
			</Stack>
		</Modal>
	);
}