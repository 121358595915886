import { Table } from "@mantine/core";
import LeadsTableScore from "pages/Leads/LeadsTableScore";
import LeadsTableSize from "pages/Leads/LeadsTableSize";
import React, { useState } from "react";
import PopupDiamond from "../PopupDiamond/PopupDiamond";

export default function TableDiamond({ leads }) {
	const [openedCompany, setOpenedCompany] = useState(null);

	const openLead = lead => {
		setOpenedCompany({
			id: lead.companyId, url: lead.website, status: lead.companyStatus,
			linkedIn: lead.linkedInId ? `https://www.linkedin.com/company/${lead.linkedInId}` : null,
			...lead
		});
	};

	const rows = leads?.map(lead => (
		<tr style={{ cursor: "pointer" }} key={lead.companyId} onClick={() => openLead(lead)}>
			<td>{lead.name}</td>
			<td>{lead.town}</td>
			<td><LeadsTableSize lead={lead} /></td>
			<td><LeadsTableScore lead={lead} /></td></tr>
	));
	return (
		<>
			<Table highlightOnHover>
				<thead><tr>
					<th>Name</th>
					<th>Town</th>
					<th>Size</th>
					<th>Score</th>
				</tr></thead>
				<tbody>{rows}</tbody>
			</Table>
			{openedCompany && <PopupDiamond company={openedCompany} setCompany={setOpenedCompany} />}
		</>
	);
}