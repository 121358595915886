import React, { useState } from "react";
import { createStyles, Text, Anchor, Tooltip, Button, Group } from "@mantine/core";
import { Star, FileArrowRight } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import { formatDate, objectToFormData } from "utils/helpers";
import LeadSection from "./LeadSection";
import { fetchUrl } from "utils/urlHelpers";

const useStyles = createStyles(theme => ({
	text: { fontSize: theme.fontSizes.sm },
	star: { fill: theme.colors.yellow[5], verticalAlign: "middle" },
}));

export default function LeadDuplicate(p) {
	const { classes } = useStyles();
	const dict = useDictionary("leaDuplicate");
	const [sending, setSending] = useState(null);

	const sendToBigchange = () => {
		fetchUrl(`lead/${p.lead.id}/bigchangeDuplicate`, "POST", null, setSending, dict.BCContactCreated, dup => p.lead.duplicates.push(dup));
	};
	const sendToHubspot = () => {
		const data = {
			customerId: JSON.parse(localStorage.getItem("user")).customerId,
			companyId: p.company.id, leadId: p.lead.id,
			userId: JSON.parse(localStorage.getItem("user")).id,
			processType: 256, priority: 0
		};
		fetchUrl("leadProcessQueue", "POST", objectToFormData(data), setSending, dict.hubspotBeingCreated);
	};

	const getReliabilityIcon = nb => {
		return Array.from({ length: nb }, (v, i) =>
			<Star key={i} className={classes.star} strokeWidth={0} size={16} />
		);
	};
	const getReliabilityText = reliability => {
		return ["", dict.veryReliable, dict.veryReliable, dict.moreOrLessReliable, dict.toBeVerified, dict.toBeVerified][reliability];
	};
	const getSourceIcon = type => {
		if (type === 1) return <img src="https://cdn.beta.bigchange.com/img/Icon/favicon-32x32.png?2" width={16} style={{ verticalAlign: "text-bottom" }} alt="Bigchange"></img>;
		if (type === 3) return <img src="https://static.hsappstatic.net/StyleGuideUI/static-3.265/img/sprocket/favicon-32x32.png" width={20} style={{ verticalAlign: "text-bottom" }} alt="Hubspot"></img>;
	};
	const getLink = dup => {
		if (dup.url) return dup.url;
		if (dup.sourceType == 1) return `https://client.bigchange.com/contact.aspx?contactId=${dup.sourceId}`;
	};

	const content = p.lead?.duplicates?.length === 0
		? <Text>{dict.noMatch}</Text>
		: p.lead?.duplicates
			?.filter(dup => { return p.bigchange && dup.sourceType == 1 || p.hubspot && dup.sourceType == 3; })
			.map(dup =>
				<div key={dup.id} style={{marginBottom: 12}}>
					<Text className={classes.text}>
						<Text>
							<Tooltip label={getReliabilityText(dup.matchReliability)}>
								<span>{getReliabilityIcon(6 - dup.matchReliability)}</span>
							</Tooltip> &nbsp;
							<Anchor href={getLink(dup)} target="_blank">{dup.name}</Anchor><br />
						</Text>
						<b>{dict.group}</b> {dup.group}  &nbsp; {getSourceIcon(dup.sourceType)}<br />
						<b>{dict.lastTouched}</b> {formatDate(dup.lastTouched, dict.neverTouched)}<br />
						{dup.parent && <><b>{dict.parent}</b> {dup.parent}<br /></>}
						{dup.lastAppointment && <>
							<b>{dict.lastAppt}</b> {formatDate(dup.lastAppointment)}
							{dup.lastAppointmentStatus && <> - {dup.lastAppointmentStatus}</>}
						</>}
					</Text>
				</div>
			);

	const bigchangeButton = p.lead != null && !p.lead?.duplicates?.some(dup => p.bigchange && dup.sourceType == 1)
		&& JSON.parse(localStorage.getItem("user")).canSendToBigchange === true
		&& <Group position="center">
			<Button leftIcon={<FileArrowRight size={20} />} mt="lg" loading={sending} onClick={sendToBigchange}>{dict.sendToBC}</Button>
		</Group>;

	const hubspotButton = p.lead != null && !p.lead?.duplicates?.some(dup => p.hubspot && dup.sourceType == 3)
		&& <Group position="center">
			<Button leftIcon={<FileArrowRight size={20} />} mt="lg" loading={sending} onClick={sendToHubspot}>{dict.sendToHub}</Button>
		</Group>;

	return (
		<LeadSection label={p.label} maxHeight={p.height - 55} minHeight={p.height}>
			{p.lead == null 
				? null
				: p.lead.duplicates ? <>{content}{bigchangeButton}{hubspotButton}</> : ""}
		</LeadSection>
	);
}