import React, { useState, useEffect } from "react";
import { Button, Group, MultiSelect, NumberInput, PasswordInput, Select, TextInput, Textarea, useMantineColorScheme } from "@mantine/core";
import { DeviceFloppy } from "tabler-icons-react";
import { fetchUrl } from "utils/urlHelpers";
import { objectToFormData } from "utils/helpers";

export default function ParameterField({ dict, type, param }) {
	const [value, setValue] = useState(undefined);
	const [showSave, setShowSave] = useState(false);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(undefined);
	const { toggleColorScheme } = useMantineColorScheme();

	useEffect(() => {
		const val = param?.value || type.defaultValue;
		if (type.kind == "int[]")
			setValue(val == null ? [] : val.split(","));
		else
			setValue(val);
	}, []);

	const label = dict[`param_${type.id}`];

	const updateValue = val => {
		if (type.kind == "int[]" && val.some(x => isNaN(x))) {
			setShowSave(false);
			setError(dict.notANumber);
		}
		else {
			setError(false);
			setShowSave(true);
			setValue(val);
		}
	};
	const save = () => {
		let user = JSON.parse(localStorage.getItem("user"));
		const data = {
			id: param?.id, type: type.id,
			value: type.kind == "int[]" ? value.join(",") : value
		};
		const paramSaved = () => {
			setShowSave(false);
			if (type.id == 14) {
				localStorage.setItem("user", JSON.stringify({ ...user, ...{ lang: value } }));
				location.reload();
			}
			if (type.id == 23)
				localStorage.setItem("user", JSON.stringify({ ...user, ...{ tz: value } }));
			if (type.id == 24){
				localStorage.setItem("user", JSON.stringify({ ...user, ...{ skin: value } }));
				toggleColorScheme(value);
			}
		};
		const url = "parameter/" + (type.userLevel ? "my/" : "") + (param?.id || "");
		fetchUrl(url, param ? "PUT" : "POST", objectToFormData(data), setSaving, dict.paramSaved, paramSaved, dict.paramSaveError);
	};

	const getField = () => {
		switch (type.kind) {
			case "string":
				return <TextInput value={value == undefined ? "" : value} onChange={e => updateValue(e.currentTarget.value)} label={label} />;
			case "text":
				return <Textarea value={value == undefined ? "" : value} onChange={e => updateValue(e.currentTarget.value)} label={label} autosize maxRows={10}  />;
			case "password":
				return <PasswordInput value={value} onChange={e => updateValue(e.currentTarget.value)} label={label} visibilityToggleIcon={() => <></>} />;
			case "int":
				return <NumberInput value={value == null ? undefined : +value} onChange={val => updateValue(val)} label={label} />;
			case "int[]":
				return <MultiSelect value={value} data={value.map(x => ({ value: x, label: x }))}
					onChange={updateValue} label={label} error={error}
					multiple searchable creatable getCreateLabel={lbl => `+ ${dict.add} "${lbl}"`} />;
			case "dropdown":
				return <Select value={value} data={type.options.map(x => ({ value: x.value, label: dict[`option_${type.id}_${x.key}`] || x.key }))}
					onChange={updateValue} label={label} searchable />;
		}
	};

	return (
		<Group>
			<div style={{ width: 500 }}>
				{value !== undefined && label !== undefined && getField()}
			</div>
			{showSave && <Button mt="xl" leftIcon={<DeviceFloppy size={20} />} loading={saving} onClick={save}>{dict.save}</Button>}
		</Group>
	);
}