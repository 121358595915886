import { Grid, Paper, Stack, Tabs, Text } from "@mantine/core";
import React from "react";

export default function CognismDetails({ company }) {

	const formatInfo = (lbl, val) => {
		return <Text size="sm"><span style={{ fontWeight: 500 }}>{lbl}</span> {val}</Text>;
	};
	const address = company.address || company.addresses.length && company.addresses[0];

	return (
		<Grid>
		{company.shortDescription &&
			<Grid.Col span={12}>
				<Text size="sm">{company.shortDescription}</Text>
			</Grid.Col>
		}
		{(company.yearFounded || company.size || company.sizeMin || company.industries?.length > 0 || company.revenue) &&
			<Grid.Col span={6}>
				<Paper withBorder p="xs" sx={{ backgroundColor: "#EEE" }}>
					<Stack spacing="xs">
						{company.yearFounded && formatInfo("Founded:", company.yearFounded)}
						{company.size != null && formatInfo("Head count:", company.size)}
						{company.sizeMin != null && formatInfo("Size:", company.sizeMin + " - " + company.sizeMax)}
						{company.industries?.length > 0 && formatInfo("Industries:", company.industries.join(", "))}
						{company.revenue && formatInfo("Revenue:", company.revenue.toLocaleString("en"))}
					</Stack>
				</Paper>
			</Grid.Col>
		}
		{address &&
			<Grid.Col span={6}>
				<Paper withBorder p="xs" sx={{ backgroundColor: "#EEE" }}>
					<Tabs defaultValue="0" orientation="vertical">
						<Tabs.List mr="xl">
							{company.addresses
								.sort((a, b) => (b.phones?.length || 0) - (a.phones?.length || 0))
								.slice(0, 5)
								.map((x, i) => <Tabs.Tab pl={0} value={i + ""} key={i}><Text tt="capitalize">{x.type}</Text></Tabs.Tab>)}
						</Tabs.List>
						{company.addresses.map((x, i) =>
							<Tabs.Panel value={i + ""} key={i}>
								<Text size="sm">{x.address1}</Text>
								<Text size="sm">{x.town} {x.postcode}</Text>
								{x.phones && x.phones.map((y, j) => <Text size="sm" weight={500} key={j}>{y.number}</Text>)}
							</Tabs.Panel>
						)}
					</Tabs>
				</Paper>
			</Grid.Col>
		}
		{company.tech?.length > 0 &&
			<Grid.Col span={6}>
				<Paper withBorder p="xs" sx={{ backgroundColor: "#EEE" }}>
					<Text size="sm">{formatInfo("Tech stack:", company.tech.join(", "))}</Text>
				</Paper>
			</Grid.Col>
		}
	</Grid>
	);
}