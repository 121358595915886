import React from "react";
import { Tabs, Text } from "@mantine/core";
import { useDictionary } from "i18n/dictionary";
import { ArrowsShuffle, FileExport, FileImport, Tool } from "tabler-icons-react";
import { useNavigate, useParams } from "react-router-dom";
import ImportTable from "./Import/ImportTable";
import ExportTable from "./ExportTable";
import RunningTaskTable from "./RunningTaskTable";
import AssignTable from "./AssignTable";
import ContentBox from "components/ContentBox";

export default function Processes() {
	const dict = useDictionary("process");
	const navigate = useNavigate();
	const { tabValue } = useParams();

	return (
		<>
			<Tabs value={tabValue} onTabChange={value => navigate(`/process/${value}`)} mb="md" >
				<Tabs.List>
					<Tabs.Tab value="import" icon={<FileImport size={18} />}>{dict.imports}</Tabs.Tab>
					<Tabs.Tab value="export" icon={<FileExport size={18} />}>{dict.exports}</Tabs.Tab>
					<Tabs.Tab value="assign" icon={<ArrowsShuffle size={18} />}>{dict.assignments}</Tabs.Tab>
					<Tabs.Tab value="process" icon={<Tool size={18} />}>{dict.processes}</Tabs.Tab>
				</Tabs.List>
			</Tabs>
			<ContentBox>
			{tabValue == "import" && <ImportTable />
			|| tabValue == "export" && <ExportTable dict={dict} />
			|| tabValue == "assign" && <AssignTable dict={dict} />
			|| tabValue == "process" && <RunningTaskTable dict={dict} />
			|| <Text my={50} ml={100}>{dict.selectProcess}</Text>}
			</ContentBox>
		</>
	);
}