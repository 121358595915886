import React, { useState } from "react";
import { Accordion, Group, Button, Text, Table } from "@mantine/core";
import { ArrowBigRight } from "tabler-icons-react";
import Confirm from "components/Confirm";

export default function RoadLeadFormCompanyNotFound({ form, step2Result, setStep }) {
	const [regNumber, setRegNumber] = useState(null);
	const [regToConfirm, setRegToConfirm] = useState(null);
	const [confirmOpened, setConfirmOpened] = useState(null);

	const rowClick = x => {
		if (x.status == "active")
			setRegNumber(x.regNumber);
		else {
			setRegToConfirm(x.regNumber);
			setConfirmOpened(true);
		}
	};
	const nextStep = () => {
		form.setValues({ regNumber: regNumber });
		setStep("4");
	};
	const hasSize = step2Result?.candidates?.some(x => x.sizeMin > 0);

	const tableContent = step2Result?.candidates?.map(x =>
		<tr key={x.name} onClick={() => rowClick(x)}
			style={{ cursor: "pointer", outline: x.regNumber == regNumber ? "highlight auto 1px" : undefined }}>
			<td><Text transform="capitalize" italic={x.status != "active"}>{x.name.toLowerCase()}</Text></td>
			<td><Text italic={x.status != "active"}>{x.town}</Text></td>
			{hasSize && <td><Text>{x.sizeMin} - {x.sizeMax}</Text></td>}
		</tr>
	);
	return (
		<Accordion.Item value="3">
			<Accordion.Control disabled={!step2Result?.candidates}>3. Confirm</Accordion.Control>
			{step2Result?.candidates?.length > 0
				? <Accordion.Panel>
					<Text>No matching company has been found.</Text>
					<Text>Please carefully select one of the company below</Text>
					<Table mt="md" highlightOnHover>
						<thead>
							<tr>
								<th>Name</th>
								<th>Town</th>
								{hasSize && <th>Size</th>}
							</tr>
						</thead>
						<tbody>{tableContent}</tbody>
					</Table>
					<Group position="right" mt="sm">
						<Button leftIcon={<ArrowBigRight size={20} />} disabled={regNumber == null} onClick={nextStep}>Next</Button>
					</Group>
				</Accordion.Panel>
				: <Accordion.Panel>No matching company found on Diamond nor on Companies House. Please check the spelling</Accordion.Panel>
			}
			<Confirm opened={confirmOpened} setOpened={setConfirmOpened} yesLabel="Yes" yesAction={() => setRegNumber(regToConfirm) }>
				This company is flagged as not being active on the Register. Are you 100% you have selected the right match?
			</Confirm>

		</Accordion.Item>
	);
}