import React, { useState, useEffect } from "react";
import { createStyles, Grid, Button, Paper, Modal, Center, Text, Spoiler } from "@mantine/core";
import { SquarePlus } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import BattleCardsEdit from "./BattleCardsEdit";
import { getUrl } from "utils/urlHelpers";

const useStyles = createStyles((theme) => ({
	paper: {
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2],
		minHeight: 250
	},
	clickablePaper: {
		cursor: "pointer",
		"&:hover": { backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[3] }
	}
}));

export default function BattleCards(p) {
	const { classes } = useStyles();
	const dict = useDictionary();
	const [cards, setCards] = useState(null);
	const [cardOpened, setCardOpened] = useState(null);
	const [savedCard, setSavedCard] = useState(null);
	const [deletedCard, setDeletedCard] = useState(null);

	useEffect(() => {
		getUrl(`battleCard/${p.entityType}/${p.entityId}`, setCards);
	}, []);

	useEffect(() => {
		if (cards && savedCard != null) {
			let newCards = cards.slice();
			if (cardOpened.id == savedCard.id)
				newCards.forEach((card, i) => {
					if (card.id == savedCard.id)
						newCards[i] = { ...savedCard };
				});
			else {
				newCards.push(savedCard);
			}
			setCards(newCards);
			setCardOpened(null);
		}
	}, [savedCard]);

	useEffect(() => {
		if (cards && deletedCard != null) {
			let newCards = cards.filter(function (card) {
				return card.id != deletedCard.id;
			});
			setCards(newCards);
			setCardOpened(null);
		}
	}, [deletedCard]);

	const openCard = card => {
		setCardOpened(card);
		setSavedCard(null);
		setDeletedCard(null);
	};
	const content = cards?.map(c =>
		<Grid.Col key={c.id} span={4}>
			<Paper radius="md" p="xs" className={[classes.paper, !p.readOnly && classes.clickablePaper]} onClick={p.readOnly ? undefined : () => openCard(c)}>
				<Spoiler maxHeight={200} showLabel={dict.showMore} hideLabel="Hide">
					<Center><Text><b>{c.title}</b></Text></Center>
					<div dangerouslySetInnerHTML={{ __html: c.content }} />
				</Spoiler>
			</Paper>
		</Grid.Col>
	);
	return (
		<>
			<Grid>
				{content}
				{!p.readOnly &&
					<Grid.Col span={4}>
						<Paper radius="md" p="xs" className={[classes.paper, classes.clickablePaper]} onClick={() => openCard({ id: null, entityType: p.entityType, entityId: p.entityId, title: "", content: "" })}>
							<Center style={{ height: 200 }}>
								<Button leftIcon={<SquarePlus size={20} />}>{dict.create}</Button>
							</Center>
						</Paper>
					</Grid.Col>
				}
			</Grid>
			<Modal centered opened={cardOpened != null} onClose={() => setCardOpened(null)} size={800}>
				<BattleCardsEdit card={cardOpened} setSavedCard={setSavedCard} setDeletedCard={setDeletedCard} />
			</Modal>
		</>
	);
}