import React, { useState } from "react";
import { Button, Group, Modal, Select, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { objectToFormData } from "utils/helpers";
import { fetchUrl } from "utils/urlHelpers";
import { DeviceFloppy, X } from "tabler-icons-react";

export default function IssueComment({ dict, issue, setIssueOpened, issues, setIssues }) {
	const [saving, setSaving] = useState(null);

	const form = useForm({
		initialValues: { id: issue.id, comment: issue.comment || "", status: issue.status + "" }
	});

	const saveComment = values => {
		let data = { ...values };
		fetchUrl(`issueReport/${data.id}`, "PUT", objectToFormData(data), setSaving, dict.issueUpdated, afterIssueSaved);
	};
	const afterIssueSaved = () => {
		setIssues(issues.map(x => x.id == issue.id ? { ...x, ...form.values } : x));
		setIssueOpened(null);
	};

	const statuses = [{ value: "1", label: dict.status1 }, { value: "2", label: dict.status2 }, { value: "3", label: dict.status3 }, { value: "4", label: dict.status4 }];

	return (
		<Modal opened={true} onClose={() => setIssueOpened(null)}>
			<form onSubmit={form.onSubmit(saveComment)}>
				<Textarea label={dict.comment} {...form.getInputProps("comment")} maxLength={500}></Textarea>
				<Select data={statuses} {...form.getInputProps("status")} required label={dict.status} />
				<Group position="center" mt="sm">
					<Button leftIcon={<X size={20} />} variant="outline" onClick={() => setIssueOpened(null)}>{dict.cancel}</Button>
					<Button type="submit" leftIcon={<DeviceFloppy size={20} />} loading={saving} name={"Save"}>{dict.save}</Button>
				</Group>
			</form>
		</Modal>
	);
}