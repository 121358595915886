import React, { useState, useEffect } from "react";
import { Stepper, Text, Group, Modal } from "@mantine/core";
import { fetchUrl } from "utils/urlHelpers";
import { objectToFormData } from "utils/helpers";
import StepperButtons from "components/StepperButtons";
import { ImportTypeSelect } from "./ImportTypeSelect";
import { ImportFile } from "./ImportFile";
import { ImportMapping } from "./ImportMapping";

export default function Import({ dict, opened, setOpened }) {
	const [activeStep, setActiveStep] = useState(0);
	const [importType, setImportType] = useState("1");
	const [processType, setProcessType] = useState(0);
	const [fileName, setFileName] = useState(null);
	const [fileContent, setFileContent] = useState(null);
	const [uploadedData, setUploadedData] = useState(null);
	const [colDef, setColDef] = useState({});
	const [colDefOk, setColDefOk] = useState(false);
	const [uploading, setUploading] = useState(null);

	useEffect(() => {
		setColDef({});
		if (uploadedData?.length > 0)
			setActiveStep(2);
	}, [uploadedData]);

	const importTypes = [{ label: dict.leads, value: "1" }, { label: dict.contacts, value: "2" }, { label: dict.contactDel, value: "4" }, { label: dict.tags, value: "3" }];

	const doImport = () => {
		const data = {
			name: fileName, fileContent: fileContent, importType: +importType, processType: processType,
			columnDefinition: JSON.stringify(colDef), rowCount: uploadedData.length
		};
		fetchUrl("import", "POST", objectToFormData(data), setUploading, null, () => { setActiveStep(4); });
	};
	return (
		<Modal opened={opened} onClose={() => setOpened(false)} size={700}>
			<Stepper active={activeStep} onStepClick={setActiveStep} breakpoint="sm">
				<Stepper.Step label={dict.step1} description={dict.step1Desc} allowStepSelect={activeStep > 0}>
					<ImportTypeSelect dict={dict} importType={importType} setImportType={setImportType} importTypes={importTypes} setProcessType={setProcessType} processType={processType} />
					<StepperButtons dict={dict} hidePrev={true} setActiveStep={setActiveStep} />
				</Stepper.Step>
				<Stepper.Step label={dict.step2} description={dict.step2Desc} allowStepSelect={activeStep > 1}>
					<ImportFile dict={dict} setUploadedData={setUploadedData} setFileContent={setFileContent} setFileName={setFileName} />
					<StepperButtons dict={dict} disabled={!(uploadedData?.length > 0)} setActiveStep={setActiveStep} />
				</Stepper.Step>
				<Stepper.Step label={dict.step3} description={dict.step3Desc} allowStepSelect={activeStep > 2}>
					<ImportMapping dict={dict} importType={importType} uploadedData={uploadedData} colDef={colDef} setColDef={setColDef} setColDefOk={setColDefOk} processType={processType} />
					<StepperButtons dict={dict} disabled={!colDefOk} setActiveStep={setActiveStep} />
				</Stepper.Step>
				<Stepper.Step label={dict.stepFinal} description={dict.stepFinalDesc} allowStepSelect={activeStep > 3}>
					<Text mb="sm" align="center">
						{dict.importRecap?.replace("[x]", uploadedData?.length - 1)
							.replace("[entity]", importTypes.find(x => x.value == importType).label.toLowerCase())}
					</Text>
					<StepperButtons dict={dict} hideNext={true} setActiveStep={setActiveStep} lastLabel={dict.import} lastButtonAction={doImport} loading={uploading} />
				</Stepper.Step>
				<Stepper.Completed>
					<Group position="center" mt="xl">
						{dict.processImport}
					</Group>
				</Stepper.Completed>
			</Stepper>
		</Modal>
	);
}