import React from "react";
import { createStyles, Button, Menu, Group, ActionIcon, Text } from "@mantine/core";
import { ChevronDown } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
	button: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},

	menuControl: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		borderLeft: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white}`,
	},
}));

export default function SplitButton({ type, actions, variant, compact, loading, disabled }) {
	const { classes, theme } = useStyles();

	const items = actions.slice(1).map(item =>
		<Menu.Item key={item.label} icon={item.icon} onClick={item.onClick} color={item.color || theme.colors.blue[7]}>
			<Text weight={500}>{item.label}</Text>
		</Menu.Item>
	);

	return (
		<Group noWrap spacing={0}>
			<Button type={type} className={classes.button} leftIcon={actions[0].icon}
				variant={variant || "filled"} compact={compact} onClick={actions[0].onClick}
				loading={loading} disabled={disabled}>
				{actions[0].label}
			</Button>
			<Menu transition="pop" position="bottom-end" offset={0}>
				<Menu.Target>
					<ActionIcon variant={variant || "filled"} size={compact ? 26 : 36}
						color={theme.primaryColor} className={classes.menuControl} disabled={disabled}>
						<ChevronDown size={16} />
					</ActionIcon>
				</Menu.Target>
				<Menu.Dropdown>
					{items}
				</Menu.Dropdown>
			</Menu>
		</Group>
	);
}