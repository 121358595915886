import React, { useEffect, useState } from "react";
import { Button, Group, SimpleGrid, Stack, Text, Textarea } from "@mantine/core";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import LeadSection from "./LeadSection";
import { fetchUrl, getUrl } from "utils/urlHelpers";
import { Wand } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import { objectToFormData } from "utils/helpers";

export default function LeadAI({ label, height, company }) {
	const [summary, setSummary] = useState(null);
	const [summaryLoading, setSummaryLoading] = useState(null);
	const [caseStudy, setCaseStudy] = useState(null);
	const [caseStudyLoading, setCaseStudyLoading] = useState(null);
	const [aiPromptExtra, setAiPromptExtra] = useState(null);
	const [aiPromptResult, setAiPromptResult] = useState(null);
	const [aiPromptLoading, setAiPromptLoading] = useState(null);
	const [userParam, setUserParam] = useState([]);
	const dict = useDictionary("leadAI");

	useEffect(() => {
		getUrl("parameter/my", setUserParam);
	}, []);

	const loadSummary = () => {
		fetchUrl(`company/${company.id}/webSummary`, null, null, setSummaryLoading, null, setSummary);
	};
	const loadCaseStudy = () => {
		const data = objectToFormData({ question: summary.answer, templateId: 212 });
		fetchUrl("gpt", "POST", data, setCaseStudyLoading, null, setCaseStudy);
	};
	const loadAiPromptResult = paramId => {
		const prompt = userParam.find(x => x.id == paramId).value;
		const data = objectToFormData({ question: `${prompt}\n${aiPromptExtra}\n\n${summary.answer}` });
		fetchUrl("gpt", "POST", data, setAiPromptLoading, null, setAiPromptResult);
	};

	if (!company?.webPageCount) return (
		<LeadSection label={label} maxHeight={height - 55} minHeight={height}>
			<Text size="sm">{dict.noWeb}</Text>
		</LeadSection>
	);

	const LinkRenderer = ({ href, children }) => {
		if (href.startsWith("http"))
			return <a href={href} target="_blank" rel="noReferrer">{children}</a>;
	};

	return (
		<LeadSection label={label} maxHeight={height - 55} minHeight={height}>
			<SimpleGrid cols={3}>
				<Stack align="center">
					<Button onClick={loadSummary} leftIcon={<Wand />} loading={summaryLoading} className="AiSummaryButton">{dict.summary}</Button>
					{summary && <Text size="sm">
						<ReactMarkdown components={{ a: LinkRenderer }} remarkPlugins={[remarkGfm]}>
							{summary.answer}
						</ReactMarkdown>
					</Text>}
				</Stack>
				<Stack align="center">
					<Button onClick={loadCaseStudy} leftIcon={<Wand />} loading={caseStudyLoading} disabled={!summary} className="AiCaseStudyButton">{dict.caseStudy}</Button>
					{caseStudy && <Text size="sm">
						<ReactMarkdown components={{ a: LinkRenderer }} remarkPlugins={[remarkGfm]}>
							{caseStudy.answer}
						</ReactMarkdown>
					</Text>}
				</Stack>
				<Stack align="center" spacing={8}>
					<Group position="center">
						{userParam.filter(x => [29, 30, 31, 32].includes(x.type)).map(x =>
							<Button key={x.id} sx={{ width: 120, paddingRight: 4, paddingLeft: 4 }}
								onClick={() => loadAiPromptResult(x.id)} leftIcon={<Wand />}
								loading={aiPromptLoading} disabled={!summary} className="AiPromptButton"
							>
								<Text truncate>{x.value.split("\n")[0]}</Text>
							</Button>
						)}
					</Group>
					<Textarea autosize rows={2} value={aiPromptExtra} onChange={e => setAiPromptExtra(e.target.value)} placeholder={dict.extraPrompt} sx={{ width: "100%" }} />
					{aiPromptResult && <Text size="sm">
						<ReactMarkdown components={{ a: LinkRenderer }} remarkPlugins={[remarkGfm]}>
							{aiPromptResult.answer}
						</ReactMarkdown>
					</Text>}
				</Stack>
			</SimpleGrid>
		</LeadSection>
	);
}