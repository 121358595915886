import React, { useState } from "react";
import { Modal, Stack, Text, Group, Button, SegmentedControl } from "@mantine/core";
import { fetchUrl } from "utils/urlHelpers";
import { X, Check } from "tabler-icons-react";
import { objectToFormData } from "utils/helpers";

export default function LeadsActionExport({ dict, opened, setOpened, rowCount, filters, user }) {
	const [type, setType] = useState("1");

	const exportCSV = () => {
		const param = {
			userId: user.id, recipient: user.email, type: +type, filters: JSON.stringify(filters)
		};
		fetchUrl("export", "POST", objectToFormData(param), null, dict.leadExported, () => setOpened(false));
	};

	return (
		<Modal centered title={dict.exportResult} opened={opened} onClose={() => setOpened(false)}>
			<Stack>
				<Group>
					<Text>{dict.export}</Text>
					<SegmentedControl data={[{ label: dict.leads, value: "1" }, { label: dict.contacts, value: "2" }, { label: dict.webAddress, value: "3" }]}
						value={type} onChange={setType} />
				</Group>
				<Text>{dict[`exportCSV${type}Confirm`]?.replace("{count}", rowCount)}</Text>
				<Group position="center" mt="sm">
					<Button leftIcon={<X size={20} />} variant="outline" onClick={() => setOpened(false)}>{dict.cancel}</Button>
					<Button leftIcon={<Check size={20} />} onClick={exportCSV}>{dict.export}</Button>
				</Group>
			</Stack>
		</Modal>
	);
}