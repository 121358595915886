import { Button, Group, Modal, Stack, Stepper, TextInput } from "@mantine/core";
import { useDictionary } from "i18n/dictionary";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { X } from "tabler-icons-react";
import { objectToFormData } from "utils/helpers";
import { fetchUrl } from "utils/urlHelpers";

export default function CompanyCreate({ opened, setOpened }) {
	const dict = useDictionary("companyCreate");
	const [name, setName] = useState("");
	const [reg, setReg] = useState("");
	const [url, setUrl] = useState("");
	const [activeStep, setActiveStep] = useState(0);
	const [stepLoading, setStepLoading] = useState(0);
	const [failedStep, setFailedStep] = useState(0);
	const [user, setUser] = useState(null);
	const [creating, setCreating] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!opened) return;
		setReg("");
		setUrl("");
		setActiveStep(0);
		setStepLoading(0);
		setFailedStep(0);
		setUser(JSON.parse(localStorage.getItem("user")));

	}, [opened]);

	const performCheck = (url, step, dataValid, callback) => {
		const failStep = () => {
			setFailedStep(step);
			setStepLoading(0);
		};
		setFailedStep(0);
		setStepLoading(step);
		setTimeout(() => {
			fetchUrl(url, "GET", null, null, null, data => {
				if (!dataValid(data))
					failStep();
				else {
					setStepLoading(0);
					setActiveStep(step + 1);
					if (callback) callback();
				}
			}, null, failStep);
		}, 1000);
	};

	const checkRegDup = () => {
		if (!reg) return setFailedStep(1);
		const getUrl = `company/regNumber/${encodeURIComponent(reg.trim())}?countryId=${user.countryId}`;
		performCheck(getUrl, 1, data => !data, checkUrlDup);
	};
	const checkUrlDup = () => {
		if (!url) return setFailedStep(2);
		const getUrl = `company/website/${encodeURIComponent(url.trim())}?countryId=${user.countryId}`;
		performCheck(getUrl, 2, data => !data, checkRegValid);
	};
	const checkRegValid = () => {
		const getUrl = `company/register/${encodeURIComponent(reg.trim())}?countryId=${user.countryId}`;
		performCheck(getUrl, 3, data => {
			setName(data?.name);
			return data;
		});
	};
	const createCompany = () => {
		setCreating(true);
		fetchUrl("status", "GET", null, null, null, statuses => {
			const newStatusId = statuses.find(x => x.type == 1).id;
			fetchUrl("company", "POST", objectToFormData({ regNumber: reg, name: name, website: url, countryId: user.countryId }), null, null, company => {
				const leadData = objectToFormData({
					companyId: company.id, sourceType: 2, currentStatusId: newStatusId,
					currentStatusUserId: user.id,
					currentStatusDate: new Date().toISOString()
				});
				fetchUrl("lead", "POST", leadData, null, null, lead => {
					fetchUrl("leadStatusHistoric", "POST", objectToFormData({
						leadId: lead.id, statusId: newStatusId, userId: user.id
					}), null, null, () => {
						fetchUrl("leadProcessQueue", "POST", objectToFormData({
							companyId: company.id, leadId: lead.id, userId: user.id, customerId: user.customerId,
							priority: 0, processType: 1 + 2 + 4 + 16 + 128 + 1024 + 65536
						}), null, null, () => {
							setCreating(false);
							navigate(`/battle/${company.id}`);
						});
					});

				});
			});
		});
	};

	return (
		<Modal centered title={dict.createNewCompany} opened={opened} onClose={() => setOpened(false)} size={700}>
			<Group position="apart" >
				<Stack spacing="md">
					<TextInput label={dict.reg} required maxLength={50} value={reg} onChange={e => setReg(e.target.value)} />
					<TextInput label={dict.name} value={name} disabled />
					<TextInput label="Url" required maxLength={50} value={url} onChange={e => setUrl(e.target.value)} />
					<Group position="center">
						<Button onClick={() => setOpened(false)} variant="outline">{dict.cancel}</Button>
						<Button onClick={checkRegDup}>{dict.check}</Button>
						<Button onClick={createCompany} disabled={activeStep != 4} loading={creating}>{dict.create}</Button>
					</Group>
				</Stack>
				<Stack spacing="md">
					<Stepper active={activeStep} onStepClick={setActiveStep} orientation="vertical" allowNextStepsSelect={false}>
						<Stepper.Step label={dict.checkRegUnique} allowStepClick={false} allowStepSelect={false}
							description={failedStep == 1 ? dict.failRegUnique : null} color={failedStep == 1 ? "red" : null}
							loading={stepLoading == 1} icon={failedStep == 1 ? <X size={18} color="red" /> : null} />
						<Stepper.Step label={dict.checkUrlUnique} allowStepClick={false} allowStepSelect={false}
							description={failedStep == 2 ? dict.failUrlUnique : null} color={failedStep == 2 ? "red" : null}
							loading={stepLoading == 2} icon={failedStep == 2 ? <X size={18} color="red" /> : null} />
						<Stepper.Step label={dict.checkRegValid} allowStepClick={false} allowStepSelect={false}
							description={failedStep == 3 ? dict.failRegValid : null} color={failedStep == 3 ? "red" : null}
							loading={stepLoading == 3} icon={failedStep == 3 ? <X size={18} color="red" /> : null} />
						<Stepper.Step label={dict.companyCreating} allowStepClick={false} allowStepSelect={false}
							description={activeStep == 4 && stepLoading != 4 ? dict.checkName : null}
							loading={stepLoading == 4}
							color={activeStep == 4 && stepLoading != 4 ? "green" : null} />
					</Stepper>
				</Stack>
			</Group>
		</Modal>
	);
}