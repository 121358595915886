import React from "react";
import { Accordion, TextInput, Textarea, Group, Button, SegmentedControl, Text, SimpleGrid } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { Send } from "tabler-icons-react";

export default function RoadLeadFormExtra({ form, saving }) {

	return (
		<Accordion.Item value="4">
			<Accordion.Control disabled={form.values.regNumber == null}>4. Extra info</Accordion.Control>
			<Accordion.Panel>
				<TextInput {...form.getInputProps("phone")} label="Phone" type="tel" maxLength={15} />
				<Textarea {...form.getInputProps("note")} label="Notes" maxLength={255} />
				{(!form.values.photoDate || !form.values.photoLongitude) &&
					<>
						<Group mt="sm">
							<Text>Road lead spotted</Text>
							<SegmentedControl {...form.getInputProps("spotted")}
								data={[{ label: "Now", value: "now" }, { label: "Earlier", value: "earlier" }]} />
						</Group>
						{form.values.spotted == "earlier" && !form.values.photoDate &&
							<SimpleGrid cols={2}>
								<DatePicker {...form.getInputProps("date")} label="When" withAsterisk dropdownType="modal"
									clearable={false} inputFormat="DD/MM/YYYY" maxDate={new Date()} />
								<TimeInput {...form.getInputProps("time")} label="What time" withAsterisk />
							</SimpleGrid>}
						{form.values.spotted == "earlier" && !form.values.photoLongitude &&
							<>
								<TextInput {...form.getInputProps("address")} label="Address" maxLength={255} />
								<SimpleGrid cols={2}>
									<TextInput {...form.getInputProps("postcode")} label="Postcode" maxLength={20} />
									<TextInput {...form.getInputProps("town")} label="Town" maxLength={255} withAsterisk />
								</SimpleGrid>
							</>}
					</>}
				<Group position="right" mt="sm">
					<Button type="submit" leftIcon={<Send size={20} />} loading={saving}>Send</Button>
				</Group>
			</Accordion.Panel>
		</Accordion.Item>
	);
}