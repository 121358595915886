import React, { useState } from "react";
import { Textarea, Button, Group } from "@mantine/core";
import { DeviceFloppy } from "tabler-icons-react";
import { useForm } from "@mantine/form";
import { useDictionary } from "i18n/dictionary";
import { fetchUrl } from "utils/urlHelpers";

export default function LeadIssue(p) {
	const dict = useDictionary("leadIssue");
	const [saving, setSaving] = useState(null);
	const form = useForm({
		initialValues: { issue: "" }
	});
	const saveIssue = values => {
		const user = JSON.parse(localStorage.getItem("user"));
		let formData = new FormData(), companyId = window.location.pathname.match(/\d+/)[0];
		formData.append("userId", user.id);
		formData.append("type", p.section);
		formData.append("description", values.issue);

		fetchUrl(`issueReport/${companyId}`, "POST", formData, setSaving, dict.issueSent, p.closePopup, dict.errorSavingIssue);
	};
	return (
		<form onSubmit={form.onSubmit(values => saveIssue(values))}>
			<Textarea required autosize minRows={3} maxLength={500} placeholder={dict.describeIssue?.replace("{module}", p.section.toLowerCase())}
				label={dict.issue} data-autofocus {...form.getInputProps("issue")} />
			<Group position="right" mt="sm">
				<Button type="submit" leftIcon={<DeviceFloppy size={20} />} loading={saving}>{dict.save}</Button>
			</Group>
		</form>
	);
}