import { Group, Select } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import LazyDropDown from "components/LazyDropDown";
import React from "react";

export default function IssueFilter({ dict, filters, setFilters }) {

	const status = [1, 2, 3, 4].map(x => ({ label: dict[`status${x}`], value: x }));
	return (
		<Group>
			<LazyDropDown path="user" label={dict.user} clearable
				defaultValue={filters.userId} propLabel="fullName"
				onChange={val => setFilters({ ...filters, userId: val })} />
			<DateRangePicker label={dict.dateRange} sx={{ width: 220 }}
				value={filters.dateRange} inputFormat="DD/MM/YYYY" maxDate={new Date()}
				onChange={val => setFilters({ ...filters, dateRange: val })}
			/>
			<Select data={status} label={dict.status} clearable
				value={filters.status} onChange={val => setFilters({ ...filters, status: val })} />
		</Group>
	);
}