import React from "react";
import { Text } from "@mantine/core";
import LeadSection from "./LeadSection";

export default function LeadCategories({ label, height, company }) {
	const content = company?.categories.map(cat =>
		<tr key={cat.id}>
			<td valign="top">
				<Text size="sm">{cat.code}</Text>
			</td>
			<td valign="top">
				<Text size="sm">{cat.name}</Text>
			</td>
		</tr>
	);

	return (
		<LeadSection label={label} maxHeight={height - 55} minHeight={height}>
			<table cellSpacing={4}><tbody>{content}</tbody></table>
		</LeadSection>
	);
}