import React from "react";
import { Group, Button } from "@mantine/core";
import { ArrowBigLeft, ArrowBigRight, Check } from "tabler-icons-react";

export default function StepperButtons({ dict, disabled, hidePrev, hideNext, nextLabel, lastLabel, lastButtonAction, setActiveStep, loading }) {

	const nextStep = () => setActiveStep(current => current + 1);
	const prevStep = () => setActiveStep(current => current - 1);

	return (
		<Group position="center" mt="xl">
			{!hidePrev && <Button onClick={prevStep} variant="outline" leftIcon={<ArrowBigLeft/>}>{dict.previousStep}</Button>}
			{!hideNext && <Button onClick={nextStep} disabled={disabled} rightIcon={<ArrowBigRight/>}>{nextLabel || dict.nextStep}</Button>}
			{lastButtonAction && <Button onClick={lastButtonAction} rightIcon={<Check/>} loading={loading}>{lastLabel}</Button>}
		</Group>
	);
}