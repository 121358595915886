import React, { useState, useEffect } from "react";
import { createStyles, Text, Table, Select, Tabs, Tooltip } from "@mantine/core";
import { useDictionary } from "i18n/dictionary";
import LeadSection from "./LeadSection";
import { getUrl } from "utils/urlHelpers";
import LazyDropDown from "components/LazyDropDown";
import { InfoCircle } from "tabler-icons-react";

const useStyles = createStyles(theme => ({
	row: {
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		cursor: "pointer",
	},
}));

const distance = (lat1, lon1, lat2, lon2) => {
	const p = 0.017453292519943295;
	const c = Math.cos;
	const a = 0.5 - c((lat2 - lat1) * p) / 2 +
		c(lat1 * p) * c(lat2 * p) *
		(1 - c((lon2 - lon1) * p)) / 2;

	return 12742 * Math.asin(Math.sqrt(a));
};

export default function LeadNearby(p) {
	const { classes } = useStyles();
	const dict = useDictionary("leadNearby");
	const [activeTab, setActiveTab] = useState("diamondIndustry");
	const [nearbyAll, setNearbyAll] = useState(null);
	const [nearbyChildren, setNearbyChildren] = useState(null);
	const [nearbyByIndustryId, setNearbyByIndustryId] = useState(null);
	const [nearbyByIndustryName, setNearbyByIndustryName] = useState(null);
	const [industryId, setIndustryId] = useState(null);
	const [industryName, setIndustryName] = useState("");
	const [industryList, setIndustryList] = useState([]);

	useEffect(() => {
		if (!p.company?.latitude) return;
		if (p.lead?.scores?.length) {
			setIndustryId(p.lead?.scores[0].industryId + "");
			let scoredIndustries = p.lead?.scores?.map(score => ({ value: score.industryId + "", label: score.industry }));
			setIndustryList(scoredIndustries.sort((a, b) => a.label < b.label ? -1 : 1));
		}
	}, [p.lead, p.company]);

	const loadNearby = (parent, children, industryId, industryName, callback) => {
		const url = `nearbyPoi/search?latitude=${p.company.latitude}&longitude=${p.company.longitude}&parent=${parent}&children=${children}&industryId=${industryId || ""}&industryName=${encodeURIComponent(industryName || "")}`;
		getUrl(url, callback);
	};

	useEffect(() => {
		if (industryId)
			loadNearby(true, false, industryId, null, json => {
				setNearbyByIndustryId(json);
				setNearbyChildren(null);
			});
	}, [industryId]);

	useEffect(() => {
		if (industryName != "")
			loadNearby(true, false, null, industryName, setNearbyByIndustryName);
	}, [industryName]);

	useEffect(() => {
		if (activeTab == "industry" && nearbyByIndustryName)
			loadNearby(true, false, null, industryName, setNearbyAll);
		if (activeTab == "all" && !nearbyAll)
			loadNearby(true, false, null, null, setNearbyAll);
		if (activeTab == "children" && !nearbyChildren)
			loadNearby(false, true, industryId, null, setNearbyChildren);
	}, [activeTab]);

	const getCustomFieldTooltip = poi => {
		const a = poi.custom1Val != null ? `${poi.custom1Name}: ${poi.custom1Val}` : null;
		const b = poi.custom2Val != null ? `${poi.custom2Name}: ${poi.custom2Val}` : null;
		return (a || "") + (a != null && b != null ? " - " : "") + (b || "");
	};
	const getRows = (data, showGroup) => {
		if (data?.length === 0)
			return <tr className={classes.row}><td colSpan={49}>{dict.noCompany}</td></tr>;
		return data?.map(poi =>
			<tr key={poi.id} className={classes.row} onClick={() => window.open(poi.url, "_blank")}>
				<td style={{ minWidth: 50 }}>
					<Text size="sm">{Math.floor(distance(poi.latitude, poi.longitude, p.company?.latitude, p.company?.longitude))} km</Text>
					<Text size="xs">{poi.town}</Text>
				</td>
				<td>
					<Text size="sm" weight={500}>
						{poi.name.replace("ltd", "").replace("Ltd", "").replace("limited", "").replace(/ *\([^)]*\) */g, "")}
						{showGroup && ` (${poi.group?.toLowerCase()})`}
					</Text>
				</td>
				<td>
					<Text size="sm">{poi.industry}</Text>
				</td>
				<td>
					{(poi.custom1Val != null || poi.custom2Val != null) &&
						<Tooltip label={getCustomFieldTooltip(poi)}>
							<span><InfoCircle size={20} /></span>
						</Tooltip>
					}
				</td>
			</tr>
		);	};
	if (p.company && !p.company.latitude)
		return <LeadSection label={p.label} maxHeight={p.height - 55} minHeight={p.height}><Text>{dict.noAddress}</Text></LeadSection>;

	const content =
		<Tabs value={activeTab} onTabChange={setActiveTab}>
			<Tabs.List>
				<Tabs.Tab value="diamondIndustry">{dict.withinDiamondIndustry}</Tabs.Tab>
				<Tabs.Tab value="industry">{dict.withinIndustry}</Tabs.Tab>
				<Tabs.Tab value="all">{dict.all}</Tabs.Tab>
				<Tabs.Tab value="children">{dict.children}</Tabs.Tab>
			</Tabs.List>
			<Tabs.Panel value="diamondIndustry">
				<Select data={industryList} value={industryId} onChange={setIndustryId} searchable mb="xs" />
				<Table highlightOnHover verticalSpacing={2} horizontalSpacing={2}>
					<tbody>{getRows(nearbyByIndustryId)}</tbody>
				</Table>
			</Tabs.Panel>
			<Tabs.Panel value="industry">
				<LazyDropDown path="nearbyPOI/industries" value={industryName} onChange={setIndustryName} searchable mb="xs" />
				<Table highlightOnHover verticalSpacing={2} horizontalSpacing={2}>
					<tbody>{getRows(nearbyByIndustryName)}</tbody>
				</Table>
			</Tabs.Panel>
			<Tabs.Panel value="all">
				<Table highlightOnHover verticalSpacing={2} horizontalSpacing={2}>
					<tbody>{getRows(nearbyAll)}</tbody>
				</Table>
			</Tabs.Panel>
			<Tabs.Panel value="children">
				<Table highlightOnHover verticalSpacing={2} horizontalSpacing={2}>
					<tbody>{getRows(nearbyChildren, true)}</tbody>
				</Table>
			</Tabs.Panel>
		</Tabs>;
	return (
		<LeadSection label={p.label} maxHeight={p.height - 55} minHeight={p.height}>{content}</LeadSection>
	);
}