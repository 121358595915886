import { Table, Text } from "@mantine/core";
import TablePlaceHolder from "components/TablePlaceHolder";
import React, { useEffect, useState } from "react";
import { getUrl } from "utils/urlHelpers";

export default function DiamondContact({ company, visible }) {
	const [contacts, setContacts] = useState(null);

	useEffect(() => {
		if (visible && contacts == null) {
			getUrl(`providerSearch/diamond/company/${company.id}/contact`, data => {
				data.sort((a, b) => { return b.score - a.score; });
				setContacts(data);
			});
		}
	}, [company, visible]);

	const getContactRow = isPublic => {
		return contacts.filter(x => isPublic && x.customerId == null || !isPublic && x.customerId != null).map(x => (
			<tr key={x.id}>
				<td>{x.firstName} {x.lastName}</td>
				<td>{x.position}</td>
				<td style={{ whiteSpace: "nowrap" }}>{x.matches.find(x => x.email != null)?.email || ""}</td>
				<td>
					{x.matches.filter(x => x.phone != null).slice(0, 3).map(x => (
						<div style={{ whiteSpace: "nowrap" }} key={x.id}>{x.phone}</div>
					))}
				</td>
			</tr>
		));
	};

	return (
		<Table>
			<thead>
				<tr>
					<th>Name</th>
					<th>Position</th>
					<th>Email</th>
					<th>Phone</th>
				</tr>
			</thead>
			<tbody>
				<tr style={{ backgroundColor: "#EEE" }}>
					<td colSpan={4}><Text weight={700}>Public contacts</Text></td>
				</tr>
				{!contacts
					? <TablePlaceHolder row={10} col={4} />
					: getContactRow(true)
				}
				<tr style={{ backgroundColor: "#EEE" }}>
					<td colSpan={4}><Text weight={700}>Private contacts</Text></td>
					</tr>
				{!contacts
					? <TablePlaceHolder row={10} col={4} />
					: getContactRow()
				}
			</tbody>
		</Table>
	);
}