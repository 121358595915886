import React, { useState } from "react";
import { Anchor, Button, Group, Modal, Space, Stack, Text, TextInput, useMantineTheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ArrowMerge, DeviceFloppy, ExternalLink, TransferIn, X } from "tabler-icons-react";
import { fetchUrl, getUrl } from "utils/urlHelpers";
import Confirm from "components/Confirm";
import { objectToFormData } from "utils/helpers";

export default function LeadWebPageUrl({ dict, company, onClose }) {
	const theme = useMantineTheme();
	const [emptyConfOpened, setEmptyConfOpened] = useState(null);
	const [mergeConfOpened, setMergeConfOpened] = useState(null);
	const [mergeCompany, setMergeCompany] = useState(null);
	const [saving, setSaving] = useState(null);

	const form = useForm({
		initialValues: { website: "" },
		validate: {
			website: value => value == "" || /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/
				.test(value) ? null : dict.invalidWebsite,
		},
	});

	const checkWebsite = values => {
		if (values.website == "") {
			setEmptyConfOpened(true);
			return;
		}
		getUrl(`company/website/${encodeURIComponent(values.website)}?countryId=${company.countryId}`, comp => {
			if (!comp || comp.id == company.id)
				saveWebsite();
			else {
				setMergeCompany(comp);
				setMergeConfOpened(true);
			}
		});
	};

	const saveWebsite = () => {
		fetchUrl(`company/${company?.id}/website`, "PUT", objectToFormData(form.values), setSaving, dict.websiteSaved, onClose, dict.errorSavingWebsite);
	};

	const merge = () => {
		setMergeConfOpened(false);
		// remove current website then merge
		fetchUrl(`company/${company.id}/website`, "PUT", objectToFormData({ website: "" }), setSaving, null, () => {
			fetchUrl(`company/merge/${mergeCompany.id}/with/${company.id}`, null, null, setSaving, dict.merged, onClose);
		});
	};
	const transfer = () => {
		setMergeConfOpened(false);
		// remove website from other company then save it here
		fetchUrl(`company/${mergeCompany.id}/website`, "PUT", objectToFormData({ website: "" }), setSaving, null, saveWebsite);
	};

	const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(company?.name.toLowerCase().replace("ltd", "").replace("limited", ""))} ${encodeURIComponent(company?.town)}` +
		" -site:service.gov.uk -site:dnb.com -site:compass-group.co.uk -site:compass-group.fr -site:endole.co.uk -site:companysearchesmadesimple.com -site:192.com -site:companiesintheuk.co.uk -site:opencorporates.com -site:cylex-uk.co.uk -site:companycheck.co.uk -site:pomanda.com -sitecheckcompany.co.uk -site:vat-search.co.uk -site:checkcompany.co.uk";

	return (
		<>
			<Space h="md"></Space>
			<form onSubmit={form.onSubmit(values => checkWebsite(values))}>
				<TextInput placeholder="https://www.website.com" label={dict.suggestWebsite} maxLength={2048} {...form.getInputProps("website")} type="url" />
				<Group position="apart" mt="sm">
					<Anchor href={searchUrl} target="_blank">{dict.searchGoogle}</Anchor>
					<Button type="submit" leftIcon={<DeviceFloppy size={20} />} loading={saving}>{dict.save}</Button>
				</Group>
			</form>
			<Confirm opened={emptyConfOpened} setOpened={setEmptyConfOpened} yesLabel={dict.remove} yesAction={saveWebsite}>{dict.removeWebsiteConf}</Confirm>
			<Modal opened={mergeConfOpened} withCloseButton={false} centered onClose={setMergeConfOpened}>
				<Stack>
					<Text>
						{dict.mergeConf?.replace("[name]", mergeCompany?.name).replace("[town]", mergeCompany?.town).replace("[reg]", mergeCompany?.regNumber ?? "N/A")
							.replace("[size]", mergeCompany?.size ?? (mergeCompany?.sizeMin ? `${mergeCompany?.sizeMin}-${mergeCompany?.sizeMax}` : "N/A"))}
					</Text>
					<Group spacing={8} position="center" mb="sm">
						<ExternalLink size={18} color={theme.colors.blue[4]} />
						<Anchor href={`./${mergeCompany?.id}`} target="_blank">{dict.battleCard}</Anchor>
					</Group>
				</Stack>
				<Group position="center" mt="sm">
					<Button leftIcon={<X size={20} />} variant="outline" onClick={() => setMergeConfOpened(false)}>{dict.cancel}</Button>
					<Button leftIcon={<ArrowMerge size={20} />} onClick={merge}>{dict.merge}</Button>
					<Button leftIcon={<TransferIn size={20} />} onClick={transfer}>{dict.transfer}</Button>
				</Group>
			</Modal>


		</>
	);
}