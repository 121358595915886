import React from "react";
import { Modal, Stack, Text, Group, Button } from "@mantine/core";
import { fetchUrl } from "utils/urlHelpers";
import { X, Check } from "tabler-icons-react";

export default function LeadsActionWebAddress({ dict, opened, setOpened, rowCount, filters }) {

	const guess = () => {
		const url = "leadProcessQueue/webAddress";
		fetchUrl(url, "POST", JSON.stringify(filters), null, dict.webAddressSent, () => setOpened(false));
	};

	return (
		<Modal centered title={dict.webAddress} opened={opened} onClose={() => setOpened(false)}>
			<Stack>
				<Text>{dict.webAddressConfirm?.replace("{count}", rowCount)}</Text>
				<Group position="center" mt="sm">
					<Button leftIcon={<X size={20} />} variant="outline" onClick={() => setOpened(false)}>{dict.cancel}</Button>
					<Button leftIcon={<Check size={20} />} onClick={guess}>{dict.webAddress}</Button>
				</Group>
			</Stack>
		</Modal>
	);
}