import { Button, Table } from "@mantine/core";
import TablePlaceHolder from "components/TablePlaceHolder";
import React, { useEffect, useState } from "react";
import { CurrencyPound } from "tabler-icons-react";
import { fetchUrl, getUrl } from "utils/urlHelpers";

export default function CognismContact({ company, visible, loadDiamondCompany }) {
	const [contacts, setContacts] = useState(null);
	const [hoverContactId, setHoverContactId] = useState(null);
	const [buying, setBuying] = useState(null);

	useEffect(() => {
		if (visible && contacts == null) {
			getUrl(`providerSearch/cognism/company/${company.id}/contact`, setContacts);
		}
	}, [company, visible]);

	const buyContact = contact => {
		const url = `providerSearch/cognism/contact/${contact.id}?companyId=${company.id}&firstName=${encodeURIComponent(contact.firstName)}&lastName=${encodeURIComponent(contact.lastName)}`;
		fetchUrl(url, null, null, setBuying, null, updatedContact => {
			setContacts(old => old.map(contact => contact.id === updatedContact.id ? { bought: true, ...updatedContact } : contact));
			loadDiamondCompany();
		});
	};
	const content = contacts?.sort((a, b) => { return b.seniority - a.seniority; }).map(x => (
		<tr key={x.id} onMouseOver={() => setHoverContactId(x.id)}>
			<td>{x.firstName} {x.lastName}</td>
			<td style={{ whiteSpace: "nowrap" }}>{x.department}</td>
			<td>{x.position}</td>
			<td style={{ whiteSpace: "nowrap" }}>{x.emails.length ? x.emails[0].email : ""}</td>
			<td>
				{x.phones.length
					? (x.bought
						? x.phones.map(x => <div style={{ whiteSpace: "nowrap" }} key={x.number}>{x.number}</div>)
						: <>
							<div style={{ whiteSpace: "nowrap" }}>{x.phones.filter(x => x.isMobile).length || 0}&nbsp;mobile</div>
							<div style={{ whiteSpace: "nowrap" }}>{x.phones.filter(x => !x.isMobile).length || 0}&nbsp;landline</div>
						</>)
					: <></>
				}
			</td>
			<td>
				<Button onClick={() => buyContact(x)} compact variant="outline" color="blue"
					leftIcon={<CurrencyPound size={12} />} loading={buying}
					style={{ visibility: hoverContactId == x.id && (x.emails.length || x.phones.length) ? "visible" : "hidden" }}>
					Buy
				</Button>
			</td>
		</tr>
	));
	return (
		<Table highlightOnHover>
			<thead>
				<tr>
					<th>Name</th>
					<th style={{ whiteSpace: "nowrap" }}>Department</th>
					<th>Position</th>
					<th>Email</th>
					<th style={{ whiteSpace: "nowrap" }}>Phone</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{contacts?.length == 0
					? <tr><td colSpan={6}>No contact available</td></tr>
					: (content || <TablePlaceHolder row={10} col={5} />)}
			</tbody>
		</Table>
	);
}