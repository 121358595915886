import React from "react";
import { createStyles, Center, Tooltip } from "@mantine/core";
import { ArrowUpRight, ArrowDownRight, ArrowRight } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
	sizeUp: { strokeWidth: 4, color: theme.colors.green[6], width: 18, verticalAlign: "middle", marginLeft: 2 },
	sizeDown: { strokeWidth: 4, color: theme.colors.red[7], width: 18, verticalAlign: "middle", marginLeft: 2 },
	sizeEq: { strokeWidth: 4, color: theme.colors.blue[7], width: 18, verticalAlign: "middle", marginLeft: 2 },
}));

export default function LeadsTableSize({ lead }) {
	const { classes } = useStyles();

	const getSizeIcon = lead => {
		return lead.previousSize == null ? <></>
			: (lead.previousSize < lead.size ? <ArrowUpRight className={classes.sizeUp} />
				: (lead.previousSize > lead.size ? <ArrowDownRight className={classes.sizeDown} />
					: <ArrowRight className={classes.sizeEq} />));
	};

	return (
		<Center>
			{lead.size == null
				? (lead.sizeMin != null ? lead.sizeMin + " - " + lead.sizeMax : "")
				: <>
					{lead.size}
					<Tooltip label={lead.previousSize}>
						<span>{getSizeIcon(lead)}</span>
					</Tooltip>
				</>}
		</Center>
	);
}