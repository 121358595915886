import React, { useState, useEffect } from "react";
import { Table, Skeleton, ActionIcon } from "@mantine/core";
import { useDictionary } from "i18n/dictionary";
import { getUrl } from "utils/urlHelpers";
import ContentBox from "components/ContentBox.js";
import dayjs from "dayjs";
import { Outlet, useNavigate } from "react-router-dom";
import { Pencil } from "tabler-icons-react";
import IssueComment from "./issueComment";
import IssueFilter from "./issueFilter";

export default function Issues() {
	const navigate = useNavigate();
	const dict = useDictionary("issue");
	const [issues, setIssues] = useState(null);
	const [issueOpened, setIssueOpened] = useState(null);
	const [filters, setFilters] = useState({ userId: null, dateRange: [null, null], status: null });

	const openLead = (e, companyId) => {
		if (!e.target.closest(".action-icon-td"))
			navigate(`./${companyId}`);
	};

	useEffect(() => {
		const url = "issueReport?1=1" + (filters.userId ? `&userId=${filters.userId}` : "") 
		+ (filters.dateRange[0] ? `&from=${dayjs(filters.dateRange[0]).format("YYYY-MM-DD")}` : "") 
		+ (filters.dateRange[1] ? `&to=${dayjs(filters.dateRange[1]).format("YYYY-MM-DD")}` : "")
		+ (filters.status ? `&status=${filters.status}` : "");
		getUrl(url, setIssues);
	}, [filters]);

	const placeHolderRow = Array.from({ length: 6 }, (v, i) => <td key={i}><Skeleton height={42} /></td>);
	const placeHolder = Array.from({ length: 20 }, (v, i) => <tr key={i}>{placeHolderRow}</tr>);

	const rows = issues?.map(issue => (
		<tr key={issue.id} style={{ cursor: "pointer" }} onClick={e => openLead(e, issue.companyId)}>
			<td>{dayjs(issue.dateInserted).format("DD/MM/YYYY HH:mm")}</td>
			<td>{issue.firstName} {issue.lastName}</td>
			<td>{issue.company}</td>
			<td>{issue.type}</td>
			<td>{issue.description}</td>
			<td>{issue.comment}</td>
			<td>{dict[`status${issue.status}`]}</td>
			<td className="action-icon-td">
				<ActionIcon variant="outline" color="blue" onClick={() => setIssueOpened(issue)}>
					<Pencil />
				</ActionIcon>
			</td>
		</tr>
	));
	return (
		<ContentBox>
			<IssueFilter dict={dict} filters={filters} setFilters={setFilters} />
			<Table mt="md" highlightOnHover sx={{ minWidth: 450 }}>
				<thead>
					<tr>
						<th>{dict.date}</th>
						<th>{dict.user}</th>
						<th>{dict.lead}</th>
						<th>{dict.type}</th>
						<th>{dict.description}</th>
						<th>{dict.comment}</th>
						<th>{dict.status}</th>
						<th></th>
					</tr>
				</thead>
				<tbody>{issues === null ? placeHolder : rows}</tbody>
			</Table>
			<Outlet />
			{issueOpened && <IssueComment dict={dict} issue={issueOpened} setIssueOpened={setIssueOpened} issues={issues} setIssues={setIssues} />}
		</ContentBox>
	);
}