import { ActionIcon, Grid, TextInput } from "@mantine/core";
import React, { useState } from "react";
import { Search } from "tabler-icons-react";

export default function TableFilter({ setFilter, loading }) {
	const [regNumber, setRegNumber] = useState("");
	const [name, setName] = useState("");
	const [domain, setDomain] = useState("");

	const doSearch = () => {
		const filter = {
			name: name || null, regNumber: regNumber || null, domain: domain || null,
			rowCount: 10, sortColumn: "Name", sortDirection: "ASC"
		};
		setFilter(filter);
	};

	return (
		<Grid sm={4}>
			<Grid.Col span={4}>
				<TextInput label="Name" value={name} onChange={e => setName(e.currentTarget.value)} />
			</Grid.Col>
			<Grid.Col span={4}>
				<TextInput label="Domain" value={domain} onChange={e => setDomain(e.currentTarget.value)} />
			</Grid.Col>
			<Grid.Col span={3}>
				<TextInput label="Reg.number" value={regNumber} onChange={e => setRegNumber(e.currentTarget.value)} />
			</Grid.Col>
			<Grid.Col span={1}>
				<ActionIcon mt="xl" onClick={doSearch} color="blue" variant="filled" loading={loading} >
					<Search />
				</ActionIcon>
			</Grid.Col>
		</Grid>
	);
}