import React, { useState, useEffect } from "react";
import { Stack, Group, Text, SegmentedControl, Checkbox } from "@mantine/core";

export function ImportTypeSelect({ dict, importType, processType, setProcessType, importTypes, setImportType }) {
	const [websiteChecked, setWebsiteChecked] = useState(processType & 32 ? 32 : 0);
	const [scrapeChecked, setScrapeChecked] = useState(processType & 1 ? 1 : 0);
	const [regChecked, setRegChecked] = useState(processType & 2 ? 2 : 0);
	const [docChecked, setDocChecked] = useState(processType & 4 ? 4 : 0);
	const [coordChecked, setCoordChecked] = useState(processType & 16 ? 16 : 0);
	const [dupChecked, setDupChecked] = useState(processType & 128 ? 128 : 0);
	const [addChecked, setAddChecked] = useState(processType & 1024 ? 1024 : 0);

	useEffect(() => {
		setProcessType(dupChecked + websiteChecked + coordChecked + docChecked + regChecked + scrapeChecked + addChecked);
	}, [dupChecked, websiteChecked, coordChecked, docChecked, regChecked, scrapeChecked, addChecked]);

	useEffect(() => {
		if (importType != "1") {
			setWebsiteChecked(0); setScrapeChecked(0);
			setRegChecked(0); setDocChecked(0);
			setCoordChecked(0); setDupChecked(0);
			setAddChecked(0);
		}
	}, [importType]);

	return (
		<Stack>
			<Group position="center" mt="xl">
				<Text my="sm">{dict.step1Desc}</Text>
				{dict.leads && <SegmentedControl value={importType} onChange={setImportType} data={importTypes} />}
			</Group>
			{importType == 1 &&
				<Group position="center" mt="xl">
					<Stack>
						{/*
						<Checkbox label={dict.leadOptionWebsite} checked={websiteCheck != 0} onChange={(e) => setWebsiteChecked(e.currentTarget.checked ? 32 : 0)} />
						*/}
						<Checkbox label={dict.leadOptionScrape} checked={scrapeChecked != 0} onChange={(e) => setScrapeChecked(e.currentTarget.checked ? 1 : 0)} />
						<Checkbox label={dict.leadOptionReg} checked={regChecked != 0} onChange={(e) => setRegChecked(e.currentTarget.checked ? 2 : 0)} />
						<Checkbox label={dict.leadOptionDoc} checked={docChecked != 0} onChange={(e) => setDocChecked(e.currentTarget.checked ? 4 : 0)} />
						<Checkbox label={dict.leadOptionCoord} checked={coordChecked != 0} onChange={(e) => setCoordChecked(e.currentTarget.checked ? 16 : 0)} />
						<Checkbox label={dict.leadOptionDup} checked={dupChecked != 0} onChange={(e) => setDupChecked(e.currentTarget.checked ? 128 : 0)} />
						<Checkbox label={dict.leadOptionAdd} checked={addChecked != 0} onChange={(e) => setAddChecked(e.currentTarget.checked ? 1024 : 0)} />
					</Stack>
				</Group>
			}
		</Stack>
	);
}