import React, { useState } from "react";
import { TextInput, Group, Button } from "@mantine/core";
import { RichTextEditor } from "@mantine/rte";
import { DeviceFloppy, Trash } from "tabler-icons-react";
import { useForm } from "@mantine/form";
import { useDictionary } from "i18n/dictionary";
import { objectToFormData } from "utils/helpers";
import { fetchUrl } from "utils/urlHelpers";
import Confirm from "components/Confirm";

export default function BattleCards(p) {
	const dict = useDictionary("battleCard");
	const [saving, setSaving] = useState(null);
	const [deleting, setDeleting] = useState(null);
	const [deleteConfOpened, setDeleteConfOpened] = useState(false);

	const form = useForm({ initialValues: p.card });

	const saveCard = (values) => {
		fetchUrl(`battleCard/${values.id || ""}`, values.id ? "PUT" : "POST", objectToFormData({ ...values }),
			setSaving, dict.cardSaved, card => p.setSavedCard(card), dict.cardNotSaved);
	};
	const deleteCard = () => {
		fetchUrl(`battleCard/${p.card.id}`, "DELETE", null, setDeleting, dict.cardDeleted, () => p.setDeletedCard({ id: p.card.id }), dict.cardNotDeleted);
	};

	return (
		<>
			<form onSubmit={form.onSubmit(values => saveCard(values))}>
				<TextInput label={dict.title} required {...form.getInputProps("title")} maxLength={100}></TextInput>
				<RichTextEditor {...form.getInputProps("content")} style={{ minHeight: 400 }} />
				<Group position="center" mt="sm">
					{p.card?.id &&
						<Button leftIcon={<Trash size={20} />} color="red" variant="outline" loading={deleting} onClick={() => setDeleteConfOpened(true)}>
							{dict.delete}
						</Button>
					}
					<Button type="submit" leftIcon={<DeviceFloppy size={20} />} loading={saving}>{dict.save}</Button>
				</Group>
			</form>
			<Confirm opened={deleteConfOpened} setOpened={setDeleteConfOpened} yesLabel={dict.delete} yesAction={deleteCard}>{dict.cardDelConf}</Confirm>
		</>
	);
}