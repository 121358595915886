import React, { useState, useEffect } from "react";
import { createStyles, Table, Loader, Skeleton, Tooltip, Group, Button, Modal, TextInput } from "@mantine/core";
import { Bulb, Check, BoxMultiple, AlertTriangle, Hourglass, Typography, Plus } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import IndustriesDupWords from "./IndustriesDupWords";
import Industry from "./Industry";
import { getUrl } from "utils/urlHelpers";
import ContentBox from "components/ContentBox";
import IndustryNew from "./IndustryNew";

const useStyles = createStyles((theme) => ({
	row: { cursor: "pointer" },
	rowInactive: {
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2],
		cursor: "pointer", fontStyle: "italic"
	},
	warning: { stroke: theme.colors.red[8], marginLeft: theme.spacing.sm, strokeWidth: 2, verticalAlign: "middle" },
}));

export default function Industries() {
	const { classes } = useStyles();
	const dict = useDictionary("industries");
	const [industries, setIndustries] = useState(null);
	const [savedIndustry, setSavedIndustry] = useState(null);
	const [deletedIndustry, setDeletedIndustry] = useState(null);
	const [industryOpened, setIndustryOpened] = useState(null);
	const [dupWordsOpened, setDupWordsOpened] = useState(null);
	const [newIndustryOpened, setNewIndustryOpened] = useState(null);
	const [filter, setFilter] = useState(null);

	useEffect(() => {
		if (industries && savedIndustry != null) {
			let newIndustries = industries.slice();
			let found = false;
			newIndustries.forEach((industry, i) => {
				if (industry.id == savedIndustry.id) {
					newIndustries[i] = { ...savedIndustry };
					found = true;
				}
			});
			if (!found)
			{
				newIndustries.push(savedIndustry);
				newIndustries = newIndustries.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
			}
			setSavedIndustry(null);
			setIndustryOpened(null);
			setNewIndustryOpened(false);
			setIndustries(newIndustries);
		}
	}, [savedIndustry]);

	useEffect(() => {
		if (industries && deletedIndustry != null) {
			const newIndustries = industries.filter(industry => {
				return industry.id != deletedIndustry.id;
			});
			setIndustries(newIndustries);
			setIndustryOpened(null);
		}
	}, [deletedIndustry]);

	useEffect(() => {
		getUrl("industry", setIndustries);
	}, []);

	const openIndustry = (e, industry) => {
		if (e.target.tagName == "svg") return;
		setIndustryOpened(industry);
	};

	const placeHolderRow = Array.from({ length: 6 }, (v, i) => <td key={i}><Skeleton height={42} /></td>);
	const placeHolder = Array.from({ length: 20 }, (v, i) => <tr key={i}>{placeHolderRow}</tr>);

	const rows = industries?.filter(industry => !filter || industry.scrapedWords.includes(filter) || industry.name.toLowerCase().indexOf(filter) != -1)
		.map(industry => (
			<tr key={industry.id} className={industry.status == 10 ? classes.row : classes.rowInactive} onClick={e => openIndustry(e, industry)}>
				<td>{industry.name}</td>
				<td align="center">{industry.baseSites?.length || 0}</td>
				<td align="center">
					{(industry.scrapedWords?.length || 0) - (industry.positiveWords?.length || 0)}
					{industry.scrapedWords?.length > 0 && industry.scrapedWords?.length < 40 && !industry.isGeneric
						? <Tooltip label={dict.notEnoughWords}>
							<span><AlertTriangle className={classes.warning} size="20" /></span>
						</Tooltip>
						: ""}
				</td>
				<td align="center">{industry.positiveWords?.length || 0}</td>
				<td align="center">{industry.negativeWords?.length || 0}</td>
				<td align="center">{industry.stopWords?.length || 0}</td>
				<td align="center">
					<Tooltip label={industry.status == 1 ? dict.new : (industry.status == 2 ? dict.processing : (industry.status == 5 ? dict.processed : dict.live))}>
						<div>
							{industry.status == 1 ? <Bulb /> : (industry.status == 2 ? <Loader size="sm" /> : (industry.status == 5 ? <Hourglass /> : <Check />))}
						</div>
					</Tooltip>
				</td>
			</tr>
		));

	return (
		<ContentBox>
			<Group grow>
				<Group position="left">
					<TextInput onChange={e => setFilter(e.currentTarget.value)} label={dict.keyword} icon={<Typography size={18} />} />
				</Group>
				<Group position="right">
					<Button leftIcon={<BoxMultiple size={20} />} variant="outline" onClick={() => setDupWordsOpened(true)}>{dict.dupWords}</Button>
					<Button leftIcon={<Plus size={20} />} onClick={() => setNewIndustryOpened(true)}>{dict.create}</Button>
				</Group>
			</Group>
			<Table highlightOnHover sx={{ minWidth: 450 }}>
				<thead>
					<tr>
						<th>{dict.industry}</th>
						<th>{dict.refSites}</th>
						<th>{dict.wordsFound}</th>
						<th>{dict.positive}</th>
						<th>{dict.negative}</th>
						<th>{dict.ignored}</th>
						<th>{dict.status}</th>
					</tr>
				</thead>
				<tbody>{industries === null ? placeHolder : rows}</tbody>
			</Table>
			<Modal size="50%" opened={dupWordsOpened} onClose={() => setDupWordsOpened(false)} title={dict.dupWords}>
				<IndustriesDupWords industries={industries} opened={dupWordsOpened} setIndustries={setIndustries} />
			</Modal>
			<Industry industryOpened={industryOpened} setIndustryOpened={setIndustryOpened} setSavedIndustry={setSavedIndustry} setDeletedIndustry={setDeletedIndustry} />
			<IndustryNew opened={newIndustryOpened} setOpened={setNewIndustryOpened} setSavedIndustry={setSavedIndustry} />
		</ContentBox>
	);
}