import React, { useEffect, useState } from "react";
import { Skeleton, Table, Text } from "@mantine/core";
import { getUrl } from "utils/urlHelpers";

export default function RunningTaskTable({ dict }) {
	const [tasks, setTasks] = useState(null);

	useEffect(() => {
		const timer = setTimeout(() => { getUrl("leadProcessQueue/running", tasksLoaded); }, tasks == null ? 1 : 10000);
		return () => clearTimeout(timer);
	}, [tasks]);

	const tasksLoaded = data => {
		setTasks(
			[1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32768, 65536].map(i => {
				const t = data.filter(x => x.type & i);
				return { type: i, count: t.reduce((s, x) => { return s + x.count; }, 0) };
			}).filter(x => x.count > 0).sort((a, b) => { return b.count - a.count; })
		);
	};
	const rows = tasks?.map(x => (
		<tr key={x.type}>
			<td>{dict[`task${x.type}`]}</td>
			<td>{x.count}</td>
		</tr>
	));
	const placeHolderRow = Array.from({ length: 2 }, (v, i) => <td key={i}><Skeleton height={42} /></td>);
	const placeHolder = Array.from({ length: 3 }, (v, i) => <tr key={i}>{placeHolderRow}</tr>);

	return (
		tasks?.length == 0
			? <Text ml={100} my={50}>{dict.nothing}</Text>
			: <Table sx={{ width: 500 }}>
				<thead>
					<tr>
						<th>{dict.type}</th>
						<th>{dict.leadCount}</th>
					</tr>
				</thead>
				<tbody>{rows || placeHolder}</tbody>
			</Table>
	);
}